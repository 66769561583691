import { Component, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-import-report-dialog',
  templateUrl: './import-report-dialog.component.html',
  styleUrls: ['./import-report-dialog.component.less']
})

export class ImportReportDialogComponent implements OnInit {
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  qrCode = '';

  constructor(private http: HttpClient, private router: Router, private dialogRef: MatDialogRef<ImportReportDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * フィールドに入力された文字列をtbl_reportに登録
   */
  onKeyUpQrCode(event: any) {
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-LoginAccessKey': localStorage.getItem('accessKey')
      })
    };

    let code = this.qrCode;
    this.qrCode = '';

    if (code != '' && localStorage.getItem('accessKey') != '') {
      this.http.post(
        API_BASE_URL + '/api/v1/report?', code, httpOptions
      ).subscribe(res => {  
        if (res["message"] != null) {
          alert(res["message"]);
        }
      },error => {
        alert("認証に失敗しました");
        return;
      });
    }
  }

  /**
   * キャンセルボタン
   */
  cancel() {
    this.dialogRef.close();
  }
}


