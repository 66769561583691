import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepickerInputEvent } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

export interface DialogData {
  any
}

@Component({
  selector: 'app-exchange-dialog',
  templateUrl: './exchange-dialog.component.html',
  styleUrls: ['./exchange-dialog.component.less']
})
export class ExchangeDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 画面上半分に表示するデータ
  exchangeDialogData: any;

  // 振替元の日付（休む日）
  day: string = moment(this.data['day']).format('YYYY-MM-DD');

  // 画面表示用日付
  dayForDisp: string = moment(this.data['day']).format('YYYY/MM/DD');

  // 物件名
  siteName: '';

  // 既存の休みデータ
  holiday = {};

  // 時間ドロップダウン
  hours: number[] = [];
  
  // 分ドロップダウン
  minutes: string[] = [];

  // 「変更を保存」ボタン　活性:true、非活性:false
  saveDisabled:boolean = false;

  /** ローディング */
  loadingCompleteData:boolean = false;

  // 振替先データ
  exchange = [{
    id: null,
    sequenceNumber: this.data['data'].sequenceNumber,
    staffDailyCleaning: this.data['data'].staffDailyCleaning,
    cdSite: this.data['data'].cdSite,
    cdContract: this.data['data'].cdContract,
    day: moment(this.data['day']).format('YYYY-MM-DD'),
    exchangeDay: '',
    hour: '',
    minute: '00',
    nameSite: this.data['data'].nameSite
  }];

  // 振替元のメモ
  holidayNote: '';

  // 雇用指示の実働時間
  workingHours = 0;

  // 契約詳細情報の実働時間
  constractHours = 0;

  constructor(private router: Router, private http: HttpClient, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<ExchangeDialogComponent>,
    private datePipe: DatePipe, private ngZone: NgZone) { }

  ngOnInit() {
    if (this.data['status']) {
      this.holiday = this.data['status']
    }

    if (this.data['data']['operationNote'].length) {
      if (this.data['data']['operationNote'][this.data['target'] - 1]) {
        this.holidayNote = this.data['data']['operationNote'][this.data['target'] - 1]
      }
    }

    //開始時間、終了時間の選択
    this.hours = this.generateNums(23, true);
    this.minutes = [ '00', '15', '30', '45' ];

    this.getData();

    // 振替元日のメモ
    if (Object.keys(this.holiday).length && this.holidayNote != '') {
      // 休み登録より
      this.holidayNote = this.holiday['note'];
    }
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 連番生成
   * @param n 最大値
   * @param isStartZero 0からスタートする
   * @returns　連番配列 
   */
  generateNums(n: number, isStartZero): number[] {
    if (isStartZero) {
      n++;
      return Array.from({length: n}, (x, i) => i);
    } else {
      return Array.from({length: n}, (x, i) => i + 1);
    }
  }

  
  /**
   * 現場に関係する清掃員情報を取得
   */
  getData() {
    this.http.get(API_BASE_URL + '/api/v1/sites/operationDialog?cdSite=' + this.data['data']['cdSite']
      + '&cdContract=' + this.data['data']['cdContract']
      + '&cdStaff=' + this.data['data']['staffDailyCleaning']
      + '&day=' + this.day
      + '&sequenceNumber=' + this.data['data']['sequenceNumber'],
      {headers: this.headers})
      .subscribe((res) => {
        this.siteName = this.data['data']['nameSite'];
        if (res == null || (res as []).length == 0) {
          this.exchangeDialogData = null
        } else {
          this.exchangeDialogData = res;
        }
        // 既存の振替データ取得
        this.http.get(API_BASE_URL + '/api/v1/exchange?sequenceNumber=' + this.data['data']['sequenceNumber']
        + '&staffDailyCleaning=' + this.data['data']['staffDailyCleaning']
        + '&cdSite=' + this.data['data']['cdSite']
        + '&day=' + this.day,
        {headers: this.headers})
        .subscribe((res) => {
          if (res != null && (res as []).length > 0) {
            this.exchange = res as [];
          }
          this.loadingCompleteData = true;
          this.getTime();
        },
        error => {
          alert("認証に失敗しました");
          this.router.navigate(['/']);
          this.loadingCompleteData = true;
        }
      );

      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.loadingCompleteData = true;
      }
    );
  }

  /**
   * 振替先の日を選択
   * @param event 選択日
   * @param i インデックス
   */
  changeDate(event: MatDatepickerInputEvent<Date>, i: number) {
    let d = new FormControl(event.value);
    this.exchange[i].exchangeDay = moment(d.value).format('YYYY-MM-DD');
  }

  /**
   * 保存ボタンイベント
   */
  save() {
    if (!this.validate()) {
      return
    }
    this.saveDisabled = true;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    // 休みデータを生成
    let holiday = [];
    holiday.push({
      sequenceNumber: this.data['data'].sequenceNumber,
      cdSite: this.data['data'].cdSite,
      staffDailyCleaning: this.data['data'].staffDailyCleaning,
      day: this.day,
      status: '7',
      note: this.holidayNote,
      insertedDate: moment().format('YYYY-MM-DD')
    })

    // tbl_operation_note用のデータを生成
    let operationNote = {
      // id: null,
      
      operationNoteId: {
        sequenceNumber: this.data['data'].sequenceNumber,
        staffDailyCleaning: this.data['data']['staffDailyCleaning'],
        cdSite: this.data['data'].cdSite,
        day: this.day,
      },
      cdContract: this.data['data'].cdContract,
      note: this.holidayNote
    }

    this.http.post(
      API_BASE_URL + '/api/v1/status?sendmail=yes&saveHoliday=' + true
      + '&name=' + this.data['data']['name']
      + '&nameSite=' + this.data['data']['nameSite']
      + '&show=' + this.data['data']['show']
      + '&staffDailyCleaning=' + this.data['data']['staffDailyCleaning'],
      holiday, httpOptions
    ).subscribe(res => {
      this.http.post(
        API_BASE_URL + '/api/v1/exchange/save?staffName=' + this.data['data']['name']
        + '&siteName=' + this.siteName
        + '&show=' + this.data['data']['show']
        , this.exchange
        , httpOptions
      ).subscribe(res => {
        this.chkOperationNote([operationNote])
        // tbl_operation_noteに保存
        this.http.post(
          API_BASE_URL + '/api/v1/operationNote/save?days=' + [this.day], [operationNote], httpOptions
        ).subscribe(res => {
          this.dialogRef.close();
        },error => {
          alert("認証に失敗しました");
          this.dialogRef.close();
          return;
        });
      },error => {
        alert('認証に失敗しました');
        this.router.navigate(['/']);
        this.dialogRef.close();
      });
    },error => {
      if (error.error.status == 800 && error.error.message != ""){
        alert(error.error.message);
        this.dialogRef.close();
      } else if (error.error.status == 801){
        alert(error.error.message);
        this.saveDisabled = false;
      }
      else{
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.dialogRef.close();
      }
    });
  }

  /**
   * 閉じるボタンイベント
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * 削除ボタン
   * @param i インデックス
   */
  delete(i) {
    this.exchange.splice(i, 1);
  }

  /**
   * 追加ボタン
   */
  add() {
    let data = {
      id: null,
      sequenceNumber: this.data['data'].sequenceNumber,
      staffDailyCleaning: this.data['data'].staffDailyCleaning,
      cdSite: this.data['data'].cdSite,
      cdContract: this.data['data'].cdContract,
      day: this.day,
      exchangeDay: '',
      hour: '',
      minute: '00',
      nameSite: this.data['data'].nameSite
    }
    this.exchange.push(data)
  }


  /**
   * 実働時間取得
   */
  getTime() {
    // let total = 0.0
    // for (let i = 0; i < this.exchange.length; i++) {
    //   let h = this.exchange[i].hour
    //   let m = this.exchange[i].minute
    //   if (!h || h == '') {
    //     h = '0'
    //   }
    //   if (!m || m == '' || m == '00') {
    //     m = '0'
    //   } else {
    //     m = String(Number(m) / 60);
    //   }
      
    //   let hour = Number(h) + Number(m);
    //   total += hour
    // }
    // 雇用指示の実働時間
    let workingHours = Number.parseFloat(this.data['data']['workingHours'][this.data['target'] - 1]);
    if (!workingHours || isNaN(workingHours) || this.data['data']['sequenceNumber'].indexOf('-') != -1) {
      workingHours = 0
    }
    this.workingHours = workingHours;

    // 契約詳細情報の実働時間
    let cdHours = Number.parseFloat(this.data['data']['cdHours'][this.data['target'] - 1]);
    if (!cdHours || isNaN(cdHours)) {
      cdHours = 0
    }
    this.constractHours = cdHours;
  }

  /**
   * バリデート
   * @returns 
   */
  validate() {
    let d = [];
    for (let i = 0; i < this.exchange.length; i++) {
      const e = this.exchange[i];
      if (!e.exchangeDay || e.exchangeDay == 'Invalid date' || e.exchangeDay == '') {
        alert((i+1) + '番目の振替先の日付が選択されていません。')
        return false
      }
      if ((e.hour == null || e.hour == '') && e.hour != '0') {
        alert((i+1) + '番目の実働時間が選択されていません。')
        return false
      }
      if (e.minute == null || e.minute == '') {
        alert((i+1) + '番目の実働時間が選択されていません。')
        return false
      }
    }
    return true
  }

  chkOperationNote(onote) {
    if (onote == null || onote == undefined) {

      let raw_stacktrace = ''
      try{
        throw new Error('調査用のエラーになります。');
      }catch(e){
        raw_stacktrace = e.stack;
      }
      
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "X-LoginAccessKey": localStorage.getItem("accessKey")
        })
      };
  
      // ログ内容
      let log = {
        level: 2,
        userId: localStorage.getItem("uid"),
        serviceName: '振替ダイアログ',
        operation: '登録',
        logText: 'body="' + JSON.stringify(onote) + '" \r\n' + raw_stacktrace
      }
    
      // ログ登録
      this.http.post(
        API_BASE_URL + "/api/v1/log/save", log, httpOptions
      ).subscribe(res => {
      },error => {
      });
    }
  }
}
