import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_BASE_URL } from '../../environments/environment';
import { MatTableDataSource, MatPaginator, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepicker, MatDatepickerInputEvent, MatDialog, Sort } from '@angular/material';
import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ProfitDialogComponent } from './profit-dialog/profit-dialog.component';
import { MediaMatcher } from '@angular/cdk/layout';
import { BaseComponent } from '../base.component';
import { BuildingsExportDialogComponent } from './buildings-export-dialog/buildings-export-dialog.component';

export interface Building {
  cdSite: string;
  name_billing_address_1: string;
  name_billing_address_2: string;
  nameSiteFirst: string;
  nameWorkContent: string;
  baInfo: string;
  blInfo: string;
  cdBillingAddress: string;
}

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY年',
  },
  display: {
    dateInput: 'YYYY年',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS},
  ],
})

export class BuildingsComponent extends BaseComponent implements OnInit, AfterViewInit {
 // 物件一覧テーブルのデータソース
  dataSource = new MatTableDataSource();

 // 物件一覧の全レコードの件数
  dataSourceLength = 0;

  nowLoading = true;
  nowLoading2 = false;

  nowLoadingProfit = false;
  nowLoadingProfit2 = false;
  
  loadingExport = false;

  keyword = "";

  filterNameBillingAddress = "";

  filterNameSite = "";

  filterAddress = "";
  
  // 粗利チェックボックス
  profit = false;

  // 赤字のみチェックボックス
  deficit = false;

  // 粗利率 絞り込み
  rate: number;

  // 外注、外注以外、物件毎、得意先毎　トグル
  disp1: any;

  // 合計のみ、月単位　トグル
  disp2 = 'onlyTotal';

  // トグル 選択前の値 1:外注、2:外注以外、3:物件毎、4:得意先毎
  disp1Value = null;

  // 「表示年」年月日
  selectedYear = new FormControl(moment());

  // 「表示年」年のみ
  year: number = moment(this.selectedYear.value).year();

  // 粗利データソース
  profitDataSource = new MatTableDataSource();

  // 粗利データ（赤字のみ、物件毎、両方にチェックがついていない状態）得意先ごとではない
  profitOriginalDataBySite = [];

  // 粗利データ（赤字のみ、にチェックがついていない状態）得意先ごとではない
  profitOriginalDataByCustomer = [];

  // 粗利データ（フィルターあり）
  filterData = [];

  // 物件一覧エクスポート用データ
  profitExportData = [];

  // ソート情報（前回の情報）
  sortInfo: any;

  // 画面の大きさ
  narrowDisp: MediaQueryList;

  // グラフ表示
  chart = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  @ViewChild('TABLE') table: ElementRef;

  // カラム名 物件一覧
  columnDefs = [
    'nameSiteFirst',
    'name_billing_address_1',
    'baInfo',
    'blInfo',
    'mngNote',
    'dstNote',
    'blNote',
    'keyNote'
  ];

  // カラム名 粗利
  columnDefsProfit1 = [
    'nameSite1',
    'total'
  ];

  // カラム名 粗利
  columnDefsProfit2 = [
    'nameSite2',
    'totalContract',
    'totalCost',
    'totalProfit'
  ];

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private cdr: ChangeDetectorRef, private dialog1: MatDialog, media: MediaMatcher, private ngZone: NgZone) {
    super();
    this.narrowDisp = media.matchMedia('(max-width: 1850px)');
  }

  ngOnInit() {
    let month = moment().month();
    // 今日が1月もしくは2月の場合 1年前を初期表示
    if (month == 0 || month == 1) {
      this.selectedYear.setValue(moment().add(-1,'year'));
      this.year = moment(this.selectedYear.value).year();
    }
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ページ読み込み後の初期化メソッド
   */
  ngAfterViewInit() {
    this.filterNameBillingAddress = 
      localStorage.getItem('search_buildings_nameBillingAddress') != null ? localStorage.getItem('search_buildings_nameBillingAddress') : "";
    this.filterNameSite = 
      localStorage.getItem('search_buildings_nameSite') != null ? localStorage.getItem('search_buildings_nameSite') : "";
    this.dataSource.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      // 物件一覧表示
      if (!this.profit) {
        this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
        this.getTotalSize(this.keyword);
        
      } else {
        // 粗利表示
        // 表示件数絞り込む
        let start = this.paginator.pageIndex * this.paginator.pageSize;
        let end = (this.paginator.pageIndex+1) * this.paginator.pageSize;

        // 「赤字のみ」もしくはトグルにチェックがあれば
        if (this.disp1 || this.deficit) {
          this.profitDataSource = new MatTableDataSource(this.filterData.slice(start, end) as []);
          // エクスポート用データ
          this.profitExportData = this.filterData;
        } else {
          this.profitDataSource = new MatTableDataSource(this.profitOriginalDataBySite.slice(start, end) as []);
          // エクスポート用データ
          this.profitExportData = this.profitOriginalDataBySite;
        }
      }
    });
    this.getTotalSize(this.keyword);
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
    this.cdr.detectChanges();
  }
  /**
   * セルクリック時のコールバック
   * @param event 選択された行オブジェクト
   */
  cellClicked(event) {
    console.info(event);
    this.router.navigate(['/buildings/' + event.cdSite]);
  }
  cellClicked2(event) {
    console.info(event);
    this.router.navigate(['/customers/' + event.cdBillingAddress]);
  }

  applyFilter(keyword: string) {
    if (this.profit) {
      return;
    }
    this.keyword = keyword;
    this.paginator.pageIndex = 0;
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
    this.getTotalSize(this.keyword);
  }

  /**
   *物件一覧を取得します
   * @param index 開始インデックス
   * @param size  取得サイズ
   */
  getAgreements(index: number, size: number) {
    this.nowLoading = true;
    localStorage.setItem('search_buildings_nameBillingAddress', this.filterNameBillingAddress);
    localStorage.setItem('search_buildings_nameSite', this.filterNameSite);
    localStorage.setItem('search_address', this.filterAddress);

    this.http.get(
      API_BASE_URL + '/api/v1/sites?s=0&e=8000&index=' + index
        + "&size=" + size
        + "&filterNameBillingAddress=" + encodeURIComponent(this.filterNameBillingAddress)
        + "&filterNameSite=" + encodeURIComponent(this.filterNameSite)
        + "&filterAddress=" + encodeURIComponent(this.filterAddress)
      , {headers: this.headers}
      )
    .subscribe(res => {
      this.dataSource = new MatTableDataSource(res as Building[]);
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
  
  /**
   * 全レコードのサイズを取得します
   */
  getTotalSize(keyword: string) {
    this.loadingExport = true;
    this.http.get(
      API_BASE_URL + '/api/v1/sites/allBuilding?filterNameBillingAddress=' + encodeURIComponent(this.filterNameBillingAddress)
        + "&filterNameSite=" + encodeURIComponent(this.filterNameSite)
        + "&filterAddress=" + encodeURIComponent(this.filterAddress)
      , {headers: this.headers}
    ).subscribe(res => {
      let allData = res as [];
      if (allData && allData.length) {
        this.dataSourceLength = allData.length;
        // エクセル用にカラムを生成
        for (let i = 0; i < allData.length; i++) {
          const a = allData[i]
          // 物件名
          let site = ''
          site = a['nameSiteFirst']
          if (a['nameSiteSecond'] && a['nameSiteSecond'] != '') {
            site += ' ' + a['nameSiteSecond']
          }
          // 得意先名
          let client = ''
          client = a['nameBillingAddressFirst']
          if (a['nameBillingAddressSecond'] && a['nameBillingAddressSecond'] != '') {
            client += ' ' + a['nameBillingAddressSecond']
          }
        }
      } else {
        this.dataSourceLength = 0;
      }
      this.loadingExport = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * エクスポートボタン
   */
  downloadCsv() {
    if (this.profit) {
      // 粗利
      const target = this.setDataForExport();
      const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(target);
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

      /* save to file */
      // ファイル名設定
      let fileName = '物件一覧.xlsx';
      if (this.profit) {
        fileName = '粗利一覧.xlsx';
      }
      XLSX.writeFile(wb, fileName);
    } else {
      // 物件一覧
      this.dialog1.open(BuildingsExportDialogComponent, {
        data: {
          data: {
            filterNameSite: this.filterNameSite,
            filterNameBillingAddress: this.filterNameBillingAddress,
            filterAddress: this.filterAddress
          }
        }
      });
    }
  }
  
  /**
   * エクスポート用にデータを生成_粗利
   * @returns 
   */
  setDataForExport() {
    let target = [];
    let topColumn = '物件名';
    if (this.disp1 == 'perCustomer') {
      topColumn = '得意先名';
    }
    // カラム生成_合計のみ
    const columnOnlyTotal = [
      topColumn,
      '(合計)受注金額',
      '(合計)原価確定',
      '(合計)粗利'
    ]
    for (let j = 0; j < this.profitExportData.length; j++) {
      let p = this.profitExportData[j];
      let add = {}
      add[columnOnlyTotal[0]] = p['nameSite']
      add[columnOnlyTotal[1]] = p['totalContract']
      add[columnOnlyTotal[2]] = p['totalCost']
      add[columnOnlyTotal[3]] = p['totalProfit']

      // 月単位表示のみ
      if (this.disp2 == 'monthly') {
        for (let i = 0; i < 12; i++) {
          if (i <= 9) {
            add[`(${i+3}月)受注金額`] =  p[`contract${i+3}`]
            add[`(${i+3}月)原価確定`] = p[`cost${i+3}`]
            add[`(${i+3}月)粗利`] = p[`profit${i+3}`]
          } else {
            add[`(${i-9}月)受注金額`] =  p[`contract${i-9}`]
            add[`(${i-9}月)原価確定`] = p[`cost${i-9}`]
            add[`(${i-9}月)粗利`] = p[`profit${i-9}`]
          }
        }
      }

      target.push(add);
    }
    return target;
  }

  /**
   * 粗利データ取得
   * @param index 開始インデックス
   * @param size  取得サイズ
   */
  getProfit(getCustmer: boolean) {

    // 粗利にチェックがついていない場合は、何もしない
    if (!this.profit) {
      return;
    }
    // エクスポート用データ
    this.profitExportData = [];
    this.profitDataSource = new MatTableDataSource([]);
    this.dataSourceLength = 0;

    this.nowLoadingProfit = true;
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 50;
    let startDate = moment(this.selectedYear.value).format('YYYY-03-01');    
    let endDate = moment(this.selectedYear.value).add(1, 'year').format('YYYY-02-01');
    endDate = moment(endDate).endOf('month').format('YYYY-MM-DD');

    // 得意先ごとではない
    this.http.get(
      API_BASE_URL + '/api/v1/contractLedgers/profitGroupBySite?s=0&e=8000&siteName=' + encodeURIComponent(this.filterNameSite)
        + "&startDate=" + startDate
        + "&endDate=" + endDate,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.profitOriginalDataBySite = res as [];

      // フィルターがかけられていたら、絞り込む　(物件毎、赤字、外注、外注以外、粗利率)
      if (this.disp1 || this.deficit || this.rate || this.rate == 0) {
        this.profitFilter();
      } else if (this.profitOriginalDataBySite.length > 0) {
        this.dataSourceLength = this.profitOriginalDataBySite.length;

        // 表示件数絞り込む
        let data = res as [];
        let start = this.paginator.pageIndex * this.paginator.pageSize;
        let end = (this.paginator.pageIndex+1) * this.paginator.pageSize;
        
        this.profitDataSource = new MatTableDataSource(data.slice(start, end) as []);
        // エクスポート用データ
        this.profitExportData = data;
      } else {
        this.dataSourceLength = 0;
      }

      this.nowLoadingProfit = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.nowLoadingProfit = false;
    });

    // 初期表示、指定年が変わった変わった場合のみ得意先ごとのデータを取得
    if (getCustmer) {
      this.nowLoadingProfit2 = true;

      // 得意先ごと
      this.http.get(
        API_BASE_URL + '/api/v1/contractLedgers/profitGroupByCustomer?s=0&e=8000&startDate=' + startDate
          + "&endDate=" + endDate,
        {headers: this.headers}
      )
      .subscribe(res => {
        this.profitOriginalDataByCustomer = res as [];

        this.nowLoadingProfit2 = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.nowLoadingProfit2 = false;
      });
    }
  }

  /**
   * 粗利データ表示するか確認
   * @param e イベント
   */
  onProfitClick(e) {
    
    // データ取得に時間がかかるので警告を出す
    if(window.confirm('データの取得に時間がかかりますがよろしいでしょうか？')) {
    } else {
      // キャンセル押下時　何もしない    
      e.preventDefault();
    }
  }

  /**
   * 粗利データ取得するか確認
   * @param index ページ数
   * @param size 表示数
   * @returns 
   */
  confirm(getCustmer: boolean) {
    // 粗利にチェックがついていない場合は、何もしない
    if (!this.profit) {
      return;
    }

    // データ取得に時間がかかるので警告を出す
    if(window.confirm('データの取得に時間がかかりますがよろしいでしょうか？')) {
      this.getProfit(getCustmer);
    } else {
      // キャンセル押下時　何もしない
    }
  }

  /**
   * 「表示年」切替
   * @param normalizedYear 
   */
  // 手入力した場合
  handlerManual(normalizedYear: MatDatepickerInputEvent<moment.Moment>) {
    const ctrlValue = this.selectedYear.value;
    ctrlValue.year(normalizedYear);
    this.selectedYear.setValue(ctrlValue);
    this.confirm(true);
  }

  // datepickerで選択した場合
  handler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    this.paginator.pageIndex = 0;
    const ctrlValue = this.selectedYear.value;
    ctrlValue.year(normalizedYear.year());
    this.selectedYear.setValue(ctrlValue);
    this.confirm(true);
    datepicker.close();
  }

  /**
   * フォントの色を決める
   * @param row 
   * @param idx 
   */
  fontColor(amount: number) {
    if (amount > 0) {
      return 'blue-color';
    } else if (amount < 0) {
      return 'red-color';
    }
  }

  /**
   * 粗利　物件名クリックイベント
   * @param row 対象の物件データ
   */
  profitDetail(row: any) {

    if (this.disp1 == 'perCustomer') {
      return;
    }

    let perSite = false;
    if (this.disp1 == 'perSite') {
      perSite = true;
    }

    this.dialog1.open(ProfitDialogComponent, {
      data: {
        data: row,
        day: moment(this.selectedYear.value).format('YYYY-03-01'),
        perSite: perSite
      }
    });
  }

  /**
   * グラフ ボタンイベント
   */
  profitChart() {
    this.chart = true;
  }

  /**
   * 粗利一覧へ戻る ボタンイベント
   */
  returnToProfit() {
    this.chart = false;
  }
  
  /**
   * 粗利　閲覧権限
   * @returns 
   */
  getAuthority() {
    let auth: string = localStorage.getItem("authority");
    let outsouce: string = localStorage.getItem("outsource");

    // 全権限かつ外注用でない場合に粗利チェックボタン出現
    if (auth == '1' && outsouce == 'false') {
      return true;
    } else {
      return false;
    }
  }

  /**
   * 外注　トグルイベント
   */
  changeOutsouce() {
    // 2度クリックされたらトグル外す
    if (this.disp1Value == 1) {
      this.disp1 = null;
      this.disp1Value = null;
    } else {
      this.disp1Value = 1;
    }
    this.profitFilter();
  }

  /**
   * 外注以外　トグルイベント
   */
  changeExceptOutsouce() {
    // 2度クリックされたらトグル外す
    if (this.disp1Value == 2) {
      this.disp1 = null;
      this.disp1Value = null;
    } else {
      this.disp1Value = 2;
    }
    this.profitFilter();
  }

  /**
   * 物件毎　トグルイベント
   */
  changePerSite() {
    // 2度クリックされたらトグル外す
    if (this.disp1Value == 3) {
      this.disp1 = null;
      this.disp1Value = null;
    } else {
      this.disp1Value = 3;
    }
    this.profitFilter();
  }

  /**
   * 得意先毎　トグルイベント
   */
  changePerCustomer() {
    // 2度クリックされたらトグル外す
    if (this.disp1Value == 4) {
      this.disp1 = null;
      this.disp1Value = null;
    } else {
      this.disp1Value = 4;
    }
    this.profitFilter();
  }
    
  /**
   * 赤字のみ、物件毎、得意先ごと　チェックボックス イベント
   */
  profitFilter() {
    this.paginator.pageIndex = 0;
    this.filterData = [];

    this.nowLoading2 = true;

    // 「得意先ごと」なし
    if (this.disp1 != 'perCustomer') {

      // 「物件毎」なし
      if (this.disp1 != 'perSite') {

        // 「赤字のみ」なし
        if (!this.deficit) {

          // 「外注」あり　(外注 1つ)
          if (this.disp1 == 'onlyOutsouce') {
            this.filterData = this.divideOutsouce(this.profitOriginalDataBySite);

          // 「外注以外」あり　(外注以外 1つ)
          } else if (this.disp1 == 'exceptOutsouce') {
            this.filterData = this.divideOutsouce(this.profitOriginalDataBySite);

          // 全てなし (0)
          } else {
            this.filterData = this.profitOriginalDataBySite;
          }

        // 「赤字のみ」あり
        } else {

          // 「外注」あり
          if (this.disp1 == 'onlyOutsouce') {

            // 「合計のみ」 (赤字、外注、合計 3つ)
            if (this.disp2 == 'onlyTotal') {
              this.filterData = this.getDeficitTotal(this.divideOutsouce(this.profitOriginalDataBySite));

            // 「月単位」 (赤字、外注、月単位 3つ)
            } else {
              this.filterData = this.getDeficit(this.divideOutsouce(this.profitOriginalDataBySite));
            }

          // 「外注以外」あり
          } else if (this.disp1 == 'exceptOutsouce') {

            // 「合計のみ」 (赤字、外注、合計 3つ)
            if (this.disp2 == 'onlyTotal') {
              this.filterData = this.getDeficitTotal(this.divideOutsouce(this.profitOriginalDataBySite));

            // 「月単位」 (赤字、外注以外、月単位 3つ)
            } else {
              this.filterData = this.getDeficit(this.divideOutsouce(this.profitOriginalDataBySite));
            }
          
          // 「外注、外注以外」どちらもなし
          } else {

            // 「合計のみ」 (赤字、合計 2つ)
            if (this.disp2 == 'onlyTotal') {
              this.filterData = this.getDeficitTotal(this.profitOriginalDataBySite);

            // 「月単位」 (赤字、月単位 2つ)
            } else {
              this.filterData = this.getDeficit(this.profitOriginalDataBySite);
            }  
          }
        }
      // 「物件毎」あり
      } else if (this.disp1 == 'perSite') {

        // 「赤字のみ」なし　(物件 1つ)
        if (!this.deficit) {
          this.filterData = this.getPerSite(this.profitOriginalDataBySite);

        // 「赤字のみ」あり
        } else {

          // 「合計のみ」 (物件、赤字、合計 3つ)
          if (this.disp2 == 'onlyTotal') {
            this.filterData = this.getDeficitTotal(this.getPerSite(this.profitOriginalDataBySite));

          // 「月単位」 (物件、赤字、月単位 3つ)
          } else {
            this.filterData = this.getDeficit(this.getPerSite(this.profitOriginalDataBySite));
          }  
        }
      }
      
    // 「得意先ごと」あり
    } else if (this.disp1 == 'perCustomer') {

      // 「赤字のみ」あり　(得意先、赤字 2つ)
      if (this.deficit) {

        // 「合計のみ」 (得意先、赤字、合計 3つ)
        if (this.disp2 == 'onlyTotal') {
          this.filterData = this.getDeficitTotal(this.profitOriginalDataByCustomer);

        // 「月単位」 (得意先、赤字、月単位 3つ)
        } else {
          this.filterData = this.getDeficit(this.profitOriginalDataByCustomer);
        }
      // 「赤字のみ」なし　(得意先 1つ)
      } else {
        this.filterData = this.profitOriginalDataByCustomer;
      }
    }

    // 「粗利率」の絞り込み あり
    if (this.rate || this.rate == 0) {
      this.filterData = this.rateFilter();
    }

    // ページネーターに全件数表示
    this.dataSourceLength = this.filterData.length;
    
    // 表示件数絞り込む
    let start = this.paginator.pageIndex * this.paginator.pageSize;
    let end = (this.paginator.pageIndex+1) * this.paginator.pageSize;
    
    this.profitDataSource = new MatTableDataSource(this.filterData.slice(start, end) as []);
    // エクスポート用データ
    this.profitExportData = this.filterData;
    
    // 切替前にソートしていたらソートする
    this.sortChange(this.sortInfo);
    this.nowLoading2 = false;
  }

  /**
   * 赤字の物件を取得
   * @param data フィルター対象データ
   */
  getDeficit(data: any) {
    let filter = [];
    for (let i = 0; i < data.length; i++) {
      for (let j = 1; j < 13; j++) {
        // 赤字の月があればその列を表示
        if (data[i]['profit' + j] < 0) {
          filter.push(data[i]);
          break;
        }
      }
    }
    return filter;
  }

  /**
   * 赤字の物件を取得(合計のみ)
   * @param data フィルター対象データ
   */
    getDeficitTotal(data: any) {
      let filter = [];
      for (let i = 0; i < data.length; i++) {
          // 赤字の月があればその列を表示
          if (data[i]['totalProfit'] < 0) {
            filter.push(data[i]);
        }
      }
      return filter;
    }

  /**
   * 物件毎にまとめる
   * @param data フィルター対象データ
   */
  getPerSite(data: any) {
    let index = null;
    let filter = [];

    // 物件毎にまとめる
    for (let i = 0; i < data.length; i++) {
      let perSiteData = [];

      // 確認した行はスキップ
      if (i == index) {
        continue;

      // 同じ物件コードなら、1行にまとめる
      } else if (i+1 != data.length && data[i]['cdSite'] == data[i+1]['cdSite']) {
        // 物件コード、物件名、合計
        perSiteData['cdSite'] = data[i]['cdSite'];
        perSiteData['nameSite'] = data[i]['nameSite'].replace('(外注)', '');
        perSiteData['totalContract'] = data[i]['totalContract'] + data[i+1]['totalContract'];
        perSiteData['totalCost'] = data[i]['totalCost'] + data[i+1]['totalCost'];
        perSiteData['totalProfit'] = data[i]['totalProfit'] + data[i+1]['totalProfit'];

        // 月毎の値
        for (let j = 1; j < 13; j++) {
          perSiteData['contract' + j] = data[i]['contract' + j] + data[i+1]['contract' + j];
          perSiteData['cost' + j] = data[i]['cost' + j] + data[i+1]['cost' + j];
          perSiteData['profit' + j] = data[i]['profit' + j] + data[i+1]['profit' + j];
        }
        filter.push(perSiteData);
        // 確認した行
        index = i+1;

      // 元々、1行の物件
      } else {
        let value = Object.assign({}, data[i]);
        value['nameSite'] = value['nameSite'].replace('(外注)', '');
        filter.push(value);
      }
    }
    return filter;
  }

  /**
   * 外注と外注以外に分ける
   * @param data フィルター対象データ
   */
  divideOutsouce(data: any) {
    let filter = [];

    for (let i = 0; i < data.length; i++) {

      // 外注の場合
      if (data[i].outsourcing == 1 && this.disp1 == 'onlyOutsouce') {
        filter.push(data[i]);

      // 外注以外の場合
      } else if (data[i].outsourcing == 0 && this.disp1 == 'exceptOutsouce') {
        filter.push(data[i]);
      }
      
    }
    return filter;
  }

  /**
   * カラム名セット
   */
  setName() {
    // 得意先ごと
    if (this.disp1 == 'perCustomer') {
      return '得意先名';
    } else {
      return '物件名';
    }
  }

  /**
   * ソート
   * @param sortState ソート対象列、昇順、降順
   */
  sortChange(sortState: Sort) {
    // ソート情報を保持（ソート中に画面を切り替えた際のため）    
    if (!sortState) {
      return;
    } else {
      this.sortInfo = sortState;
    }
    this.paginator.pageIndex = 0;
    let isAsc = sortState.direction == 'asc';
    let sortData = [];

    // 「赤字のみ」もしくはトグルにチェックがあれば
    if (this.disp1 || this.deficit) {
      sortData = this.filterData.slice();

      // ソート解除
      if (sortState.direction == '') {
        sortData =  this.filterData.slice();
      
      // 昇順、降順
      } else {
        sortData.sort((a,b) =>{
          return this.compare(a[sortState.active], b[sortState.active], isAsc);
        });
      }
    //「赤字のみ」も、トグルにもチェックなし
    } else {
      sortData = this.profitOriginalDataBySite.slice();

      // ソート解除
      if (sortState.direction == '') {
        sortData =  this.profitOriginalDataBySite.slice();
      
      // 昇順、降順
      } else {
        sortData.sort((a,b) =>{
          return this.compare(a[sortState.active], b[sortState.active], isAsc);
        });
      }
    }

    // ページネーターに全件数表示
    this.dataSourceLength = sortData.length;
    
    // 表示件数絞り込む
    let start = this.paginator.pageIndex * this.paginator.pageSize;
    let end = (this.paginator.pageIndex+1) * this.paginator.pageSize;
    
    this.profitDataSource = new MatTableDataSource(sortData.slice(start, end) as []);
    // エクスポート用データ
    this.profitExportData = sortData;
  }

  /**
   * ソート
   * @param a 
   * @param b 
   * @param isAsc 
   * @returns 
   */
  compare(a, b, isAsc) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * 合計のみの表示 カラムセット
   */
  changeTotal() {
    // 「赤字」にチェックが入っていたら
    if (this.deficit) {
      this.profitFilter();
    }
    
    // カラム名 粗利
    this.columnDefsProfit1 = [
      'nameSite1',
      'total'
    ];

    // カラム名 粗利
    this.columnDefsProfit2 = [
      'nameSite2',
      'totalContract',
      'totalCost',
      'totalProfit'
    ];
  }

  /**
   * 月単位の表示
   */
  changeMonthly() {
    // 「赤字」にチェックが入っていたら
    if (this.deficit) {
      this.profitFilter();
    }

    // カラム名 粗利
    this.columnDefsProfit1 = [
      'nameSite1',
      'total',
      'mar',
      'apl',
      'may',
      'jun',
      'jul',
      'aug',
      'sep',
      'oct',
      'nov',
      'dec',
      'jan',
      'feb'
    ];

    // カラム名 粗利
    this.columnDefsProfit2 = [
      'nameSite2',
      'totalContract',
      'totalCost',
      'totalProfit',
      'contract3',
      'cost3',
      'profit3',
      'contract4',
      'cost4',
      'profit4',
      'contract5',
      'cost5',
      'profit5',
      'contract6',
      'cost6',
      'profit6',
      'contract7',
      'cost7',
      'profit7',
      'contract8',
      'cost8',
      'profit8',
      'contract9',
      'cost9',
      'profit9',
      'contract10',
      'cost10',
      'profit10',
      'contract11',
      'cost11',
      'profit11',
      'contract12',
      'cost12',
      'profit12',
      'contract1',
      'cost1',
      'profit1',
      'contract2',
      'cost2',
      'profit2'
    ];
  }

  /**
   * 「得意先ごと」以外は物件名のカーソルをポインターにする
   */
  setCursor() {
    if (this.disp1 != 'perCustomer') {
      return 'set-cursor';
    }
  }

  /**
   * 粗利表の高さを設定
   */
  setHeight() {
    if (this.narrowDisp.matches) {
      return 'profit-height2';
    } else {
      return 'profit-height1';
    }
  }

  /**
   * 粗利率フィルター
   */
  rateFilter() {
    let data = [];
    for (let i = 0; i < this.filterData.length; i++) {
      if (this.filterData[i].totalProfit == 0 && 0 <= this.rate) {
        data.push(this.filterData[i]);
      } else if (this.filterData[i].totalContract == 0 && -100 <= this.rate) {
        data.push(this.filterData[i]);
      } else {
        let num = this.filterData[i].totalProfit / this.filterData[i].totalContract * 100;
        if (num <= this.rate) {
          data.push(this.filterData[i]);
        }
      }
    }
    return data;
  }
}
