import { Component, OnInit, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import * as moment from 'moment';

declare function require(string): any;

@Component({
  selector: 'app-importer',
  templateUrl: './importer.component.html',
  styleUrls: ['./importer.component.less']
})

export class ImporterComponent implements OnInit {

  // csvの内容
  private csv: string = "";

  // 「位置参照情報」用csvの内容
  private csvArray = [];

  // ファイルタイプ
  public fileType: string = "";

  // 更新日時
  instructEmploymentUpdate: string = "";
  contractLedgerWorkUpdate: string = "";
  purchaseUpdate: string = "";
  sitesUpdate: string = "";
  billingAddressUpdate: string = "";
  contractDetailUpdate: string = "";
  smsSiteInfoUpdate: string = "";
  positionReferenceUpdate: string = "";
  prefectureUpdate = [];

  // 「位置参照情報」用メッセージ
  positionReferenceMsg: string = "";

  // （複数選択）「位置参照情報」ファイル名
  selectedFileNames = [];

  // （複数選択）「位置参照情報」ファイル名(画面表示用)
  fileNames = '';

  // 県ごとの更新日を表示・非表示
  more = false;

  // ローディング
  public nowSending: boolean = false;

  // ヘッダー
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private ngZone: NgZone) { }

  ngOnInit() {
    this.updateDate();
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * インポートするファイル選択 イベント
   * @param files 対象ファイル
   */
  public changeListener(files: FileList){
    const Encoding = require('encoding-japanese');

    if(files && files.length > 0) {
      let file : File = files.item(0);

      // 「位置参照情報」の場合、複数ファイル読込＆shift-jisのため文字化け防止の処理
      if (this.fileType == 'positionReference') {
        let reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        this.selectedFileNames.push(file.name);
        reader.onload = (e) => {
          var result = reader.result;
          var sjisArray = this.str2Array(result);
          var uniArray = Encoding.convert(sjisArray, 'UNICODE', 'SJIS');
          this.csvArray.push(Encoding.codeToString(uniArray));
        };
      
      // 「代勤共有情報（無加工）」の場合、shift-jisのため文字化け防止の処理
      } else if (this.fileType == 'smsSiteInfo1') {
        let reader: FileReader = new FileReader();
        reader.readAsBinaryString(file);
        this.selectedFileNames.push(file.name);
        reader.onload = (e) => {
          var result = reader.result;
          var sjisArray = this.str2Array(result);
          var uniArray = Encoding.convert(sjisArray, 'UNICODE', 'SJIS');
          this.csv = Encoding.codeToString(uniArray);
        };
        
      // 「位置参照情報」以外はそのまま読込
      } else {
        let reader: FileReader = new FileReader();
        reader.readAsText(file);
        reader.onload = (e) => {
          this.csv = reader.result as string;
        }
      }
    }
    this.fileNames = this.selectedFileNames.join(' , ');
  }

  str2Array(str) {
    var array = [],i,il=str.length;
    for(i=0;i<il;i++) array.push(str.charCodeAt(i));
    return array;
  }

  /**
   * インポート ボタンイベント
   */
  public upload() {
    console.info("upload()");
    console.info(this.fileType);
    this.nowSending = true;

    try {
      // 「位置参照情報」の場合
      if (this.fileType == 'positionReference') {
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            "X-LoginAccessKey": localStorage.getItem("accessKey")
          })
        };
        this.http.post(
          API_BASE_URL + '/api/v1/files/' + this.fileType + '?fileName=' + this.selectedFileNames, 
          this.csvArray, 
          httpOptions
        )
        .subscribe(res => {
          console.info(res);
        },
        error => {
          console.info(error);
        });
        
      // 「位置参照情報」以外の場合
      } else {
        let httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'plain/csv',
            "X-LoginAccessKey": localStorage.getItem("accessKey")
          })
        };
        this.http.post(
          API_BASE_URL + '/api/v1/files/' + this.fileType, 
          this.csv, 
          httpOptions
        )
        .subscribe(res => {
          console.info(res);
        },
        error => {
          console.info(error);
        });
      }
    } catch (e) {
    }

    this.nowSending = false;
    alert("インポートを開始しました。");
    this.updateDate();
}

  /**
   * 更新日時取得
   */
  updateDate() {
    this.http.get(
      API_BASE_URL + '/api/v1/files/importState',
      {headers: this.headers}
    )
    .subscribe(res => {
      let data = res as any;
      if (data != null && data.importState.length > 0) {

        data.importState.forEach(state => {
          if (state.fileType == 'instructEmployment') {
            this.instructEmploymentUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });
        data.importState.forEach(state => {
          if (state.fileType == 'contractLedgerWork') {
            this.contractLedgerWorkUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });

        data.importState.forEach(state => {
          if (state.fileType == 'purchase') {
            this.purchaseUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });
        data.importState.forEach(state => {
          if (state.fileType == 'sites') {
            this.sitesUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });
        data.importState.forEach(state => {
          if (state.fileType == 'billingAddress') {
            this.billingAddressUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });
        data.importState.forEach(state => {
          if (state.fileType == 'contractDetail') {
            this.contractDetailUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });
        data.importState.forEach(state => {
          if (state.fileType == 'smsSiteInfo') {
            this.smsSiteInfoUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });
        data.importState.forEach(state => {
          if (state.fileType == 'positionReference') {
            this.positionReferenceUpdate = this.getUpdateDisp(state.state, state.processingDatetime);
          }
        });
        this.prefectureUpdate = data.prefecture;

        let update = moment(this.positionReferenceUpdate).format('YYYY-MM-DD');
        let today = moment().format('YYYY/MM/DD');
        let days = moment(today).diff(update, 'years');

        if (this.positionReferenceUpdate == null) {
          this.positionReferenceMsg = '位置参照情報をインポートしてください。';
        } else if (days >= 1) {
          this.positionReferenceMsg = '最終更新日から1年以上経過しています。最新の位置参照情報をインポートしてください。'
        }
      }
    },
    error => {
      alert("認証に失敗しました");
    });
  }

  /**
   * 更新表示内容取得
   * @param state 
   * @param processingDatetime 
   */
  getUpdateDisp(state, processingDatetime) {
    let dt = moment(processingDatetime).format('YYYY-MM-DD HH:mm:ss')
    switch (state) {
      case 1:
        return '実行中';
        break;
      case 5:
        return dt + ' (エラー発生)';
        break;
      default:
        return dt;
        break;
    }
  }

  /**
   * 「位置参照情報」用の選択したファイルをクリアにする
   */
  clear() {
    this.csvArray = [];
    this.selectedFileNames = [];
    this.fileNames = '';
  }

  /**
   * 県ごとの更新日を表示
   * @param more 1：表示、0表示しない
   */
  perPrefecture(more: number) {
    if (more == 1) {
      this.more = true;
    } else {
      this.more = false;
    }
  }
}