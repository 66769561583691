import { Component, OnInit, NgZone, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import _ from 'lodash'

export interface DialogData {
  any
}
@Component({
  selector: 'app-client-selection-dialog',
  templateUrl: './client-selection.component.html',
  styleUrls: ['./client-selection.component.less']
})
export class ClientSelectionDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // データ一覧
  list = [];

  // 変更前の得意先名
  oldClient = '';

  // 得意先名検索
  filterName = '';

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialogRef: MatDialogRef<ClientSelectionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: DialogData,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    // 得意先名を取得
    this.getData();
    // 変更前の得意先名を取得
    this.oldClient = this.data['name']
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }

  /**
   * 得意先名を取得
   */
  getData() {
    // データ取得
    this.http.get(API_BASE_URL + '/api/v1/contractLedgers/getClientName?filterName='
      + encodeURIComponent(this.filterName),
      {headers: this.headers})
      .subscribe((res) => {
        let r = res as any[];
        this.list = r;
      },
      error => {
        alert(error);
        this.router.navigate(['/']);
      }
    );
  }

  /**
   * 得意先名クリックイベント
   * @param name 
   */
  clickClientName(name) {
    this.dialogRef.close(name);
  }

}
