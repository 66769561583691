import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { API_BASE_URL } from '../../environments/environment';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import { BaseComponent } from '../base.component';
import * as moment from 'moment';
import { ManagementPlanDialogComponent } from '../agreements/management-plan-dialog/management-plan.component';

@Component({
  selector: 'app-contract-ledger',
  templateUrl: './contract-ledger.component.html',
  styleUrls: ['./contract-ledger.component.less']
})
export class ContractLedgerComponent extends BaseComponent implements OnInit {
  public headers = new HttpHeaders({ "X-LoginAccessKey": localStorage.getItem("accessKey") });
  
  // テーブルのカラム名
  columnDefs = [
    'dateCancellation',
    'cancelNextYear',
    'cancelReason',
    'cdBillingAddress',
    'nameBillingAddress1',
    'nameBillingAddress2',
    'nameSite1',
    'nameSite2',
    'nameDepartment',
    'cdKubunWorkContent',
    'cdWorkContent',
    'nameWorkContent',
    'feePlan3',
    'feePlan4',
    'feePlan5',
    'feePlan6',
    'feePlan7',
    'feePlan8',
    'feePlan9',
    'feePlan10',
    'feePlan11',
    'feePlan12',
    'feePlan1',
    'feePlan2',
    'totalFee'
  ];

  // ページネーター
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // エクスポート時の内容
  @ViewChild('TABLE') table: ElementRef;

  // テーブルのデータソース
  contractList = new MatTableDataSource();

  // 絞込み_得意先名１
  filterNameCustomer1 = '';
  // 絞込み_物件名１
  filterNameSite1 = '';
  // 絞込み_部門名
  filterNameDepartment = '';
  // 絞込み_作業内容コード
  filterCdWorkContent = '';
  // 絞込み_作業内容名
  filterNameWorkContent = '';

  // ローディング中の表示
  nowLoading = true;

  // データ件数
  paginatorLength = 0;
  
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private dialog1: MatDialog,
    ) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit() {
    this.filterNameCustomer1 = 
      localStorage.getItem('filterNameCustomer1ByContractLedger') != null ? localStorage.getItem('filterNameCustomer1ByContractLedger') : "";
    this.filterNameSite1 = 
      localStorage.getItem('filterNameSite1ByContractLedger') != null ? localStorage.getItem('filterNameSite1ByContractLedger') : "";
    this.filterNameDepartment = 
      localStorage.getItem('filterNameDepartmentByContractLedger') != null ? localStorage.getItem('filterNameDepartmentByContractLedger') : "";
    this.filterCdWorkContent = 
      localStorage.getItem('filterCdWorkContentByContractLedger') != null ? localStorage.getItem('filterCdWorkContentByContractLedger') : "";
    this.filterNameWorkContent = 
      localStorage.getItem('filterNameWorkContentByContractLedger') != null ? localStorage.getItem('filterNameWorkContentByContractLedger') : "";

    this.contractList.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      this.getlList(this.paginator.pageIndex, this.paginator.pageSize);
    });
    this.getlList(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * リストのデータ取得
   */
  getlList(index: number, size: number) {
    this.nowLoading = true;
    this.paginatorLength = 0;

    localStorage.setItem('filterNameCustomer1ByContractLedger', this.filterNameCustomer1);
    localStorage.setItem('filterNameSite1ByContractLedger', this.filterNameSite1);
    localStorage.setItem('filterNameDepartmentByContractLedger', this.filterNameDepartment);
    localStorage.setItem('filterCdWorkContentByContractLedger', this.filterCdWorkContent);
    localStorage.setItem('filterNameWorkContentByContractLedger', this.filterNameWorkContent);

    this.http.get(
      API_BASE_URL + '/api/v1/contractLedgers/2?s=0&e=8000&index='
        + index
        + '&size=' + size
        + '&filterNameCustomer1=' + encodeURIComponent(this.filterNameCustomer1)
        + '&filterNameSite1=' + encodeURIComponent(this.filterNameSite1)
        + '&filterNameDepartment=' + encodeURIComponent(this.filterNameDepartment)
        + '&filterCdWorkContent=' + encodeURIComponent(this.filterCdWorkContent)
        + '&filterNameWorkContent=' + encodeURIComponent(this.filterNameWorkContent),
      {headers: this.headers}
    )
    .subscribe(res => {
      let data = res as any[];
      if (data.length && data.length > 0) {
        this.contractList = new MatTableDataSource(data);
        this.paginatorLength = data[0].totalSize;
      } else {
        this.contractList = new MatTableDataSource([]);
        this.paginatorLength = 0;
      }
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 検索実行
   */
  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getlList(this.paginator.pageIndex, this.paginator.pageSize)
  }

  /**
   * 背景色設定
   * @param index 番号
   */
  setBgColor(index: number) {
    if (index % 2 == 0) {
      return 'blue-contract-bg';
    }
  }

  /**
   * 金額を3桁区切りにする
   * @param val 値
   * @returns 
   */
  amountFormat(val) {
    if (!val) {
      return null
    } else {
      return Number(val).toLocaleString()
    }
  }

  /**
   * 経営計画書 ダウンロード
   */
  downloadManagementPlan() {
    this.http.get(
      API_BASE_URL + '/api/v1/contractLedgers/downloadManagementPlan',
      {headers: this.headers, responseType : 'blob' as 'json'}
    )
    .subscribe(res => {
      let report = res as any;

      var blob = new Blob([report], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      var downloadURL = window.URL.createObjectURL(report);
      var link = document.createElement('a');
      link.href = downloadURL;

      link.download = '経営計画書_' + moment().format('YYYY年MM月DD日');

      link.click();
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 経営計画書の設定ダイアログを表示
   */
  setManagementPlan() {
    this.dialog1.open(ManagementPlanDialogComponent, {});
  }
}
