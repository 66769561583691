import { Component, OnInit, AfterViewInit, Inject, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_BASE_URL } from '../../../environments/environment';
import { MatTableDataSource, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';

export interface DialogData {
  any
}

@Component({
  selector: 'app-profit-dialog',
  templateUrl: './profit-dialog.component.html',
  styleUrls: ['./profit-dialog.component.less']
})

export class ProfitDialogComponent implements OnInit, AfterViewInit {

  // ローディング中
  nowLoading1 = false;
  nowLoading2 = false;

  // 外注ではないデータソース
  insourcingList = new MatTableDataSource();

  // 外注データソース
  outsourcingList = new MatTableDataSource();

  // 外注 1:外注委託、0:外注でない
  outsourcing = 0;

  // 物件名
  siteName = '';

  // 物件コード
  cdSite = '(物件ｺｰﾄﾞ:'

  // 粗利一覧画面　物件毎の表示
  perSite = false;

  // 外注データ件数
  outLength = 0;

  // 外注以外のデータ件数
  inLength = 0;

  // カラム名 粗利
  columnDefsProfit = [
    'type',
    'total',
    'mar',
    'apl',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'jan',
    'feb'
  ];

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<ProfitDialogComponent>, private changeDetectionRef: ChangeDetectorRef, private ngZone: NgZone) { }

  ngOnInit() {
    this.outsourcing = this.data['data']['outsourcing'];
    this.siteName =  this.data['data']['nameSite'];
    this.cdSite = this.cdSite + this.data['data']['cdSite'] + ')';
    if (this.data['perSite']) {
      this.perSite = true;
    }
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ページ読み込み後の初期化メソッド
   */
  ngAfterViewInit() {
    this.getProfit();
    this.changeDetectionRef.detectChanges();
  }

  /**
   * 粗利データ取得
   * @param index 開始インデックス
   * @param size  取得サイズ
   */
  getProfit() {

    let startDate = moment(this.data['day']).format('YYYY-MM-DD');
    let endDate = moment(startDate).add(1, 'year').format('YYYY-02-01');
    endDate = moment(endDate).endOf('month').format('YYYY-MM-DD');

    // 外注でない、もしくは物件毎の表示
    if (this.outsourcing == 0 || this.perSite) {
      this.nowLoading1 = true;
      
      this.http.get(
        API_BASE_URL + '/api/v1/contractLedgers/profitDetail/insourcing?s=0&e=8000&cdSite=' + this.data['data']['cdSite']
          + "&startDate=" + startDate
          + "&endDate=" + endDate,
        {headers: this.headers}
      )
      .subscribe(res => {
        this.insourcingList = new MatTableDataSource(res as []);
        
        this.nowLoading1 = false;
        let data = res as [];
        this.inLength = data.length;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.nowLoading1 = false;
      });
    }

    // 外注、もしくは物件毎の表示
    if (this.outsourcing == 1 || this.perSite) {
      this.nowLoading2 = true;
  
      this.http.get(
        API_BASE_URL + '/api/v1/contractLedgers/profitDetail/outsourcing?s=0&e=8000&cdSite=' + this.data['data']['cdSite']
          + "&startDate=" + startDate
          + "&endDate=" + endDate,
        {headers: this.headers}
      )
      .subscribe(res => {
        this.outsourcingList = new MatTableDataSource(res as []);
        let data = res as [];
        this.outLength = data.length;

        this.nowLoading2 = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.nowLoading2 = false;
      });
    }
  }

  close() {
    this.dialogRef.close();
  }

  /**
   * フォントの色を決める
   * @param amount 金額
   */
  fontColor(amount: number) {
    if (amount > 0) {
      return 'blue-color';
    } else if (amount < 0) {
      return 'red-color';
    }
  }

  /**
   * フォントの色を決める
   * @param cdType タイプ　0：粗利
   * @param amount 金額
   * @returns 
   */
  fontColor2(cdType: number, amount: number) {
    if (cdType == 0 && amount > 0) {
      return 'blue-color';
    } else if (cdType == 0 && amount < 0) {
      return 'red-color';
    }
  }

  /**
   * 背景色を決める
   * @param cdType タイプ　1：原価確定
   * @returns 
   */
  backColor(cdType: number) {
    if (cdType == 1) {
      return 'cost-color';
    }
  }

  /**
   * 画面の高さを決める
   */
  getHight() {
    if (this.perSite && this.outLength > 0 && this.inLength > 1) {
      return 'table-perSite-container'
    } else if(this.perSite) {
      return 'table-perSite-container1';
    } else {
      return 'table-profit-container';
    }
  }
}
