import { Component, OnInit, AfterViewInit, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders }        from '@angular/common/http'; 
import { ActivatedRoute, Router }    from '@angular/router';
import { API_BASE_URL }                   from '../../environments/environment';

@Component({
  selector: 'app-customer-detail',
  templateUrl: './customer-detail.component.html',
  styleUrls: ['./customer-detail.component.less']
})
export class CustomerDetailComponent implements OnInit, AfterViewInit {
  public customerDetail: any;
  public sites: any;
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    this.http.get(API_BASE_URL + "/api/v1/billingAddresses/" + this.route.snapshot.paramMap.get("id"),
    {headers: this.headers})
    .subscribe(res => {
      if(res == null)
        return;
      // console.info(JSON.stringify(res[0]));
      this.customerDetail = res[0];
      this.http.get(API_BASE_URL + "/api/v1/billingAddresses/" + this.route.snapshot.paramMap.get("id") + "/sites",
        {headers: this.headers}
      )
      .subscribe(res => {
        if(res == null)
          return;
        // console.info(JSON.stringify(res));
        this.sites = res;
      });
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  ngAfterViewInit() {
    
  }

}
