import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';

// 祝祭日から引き継いだデータ
export interface DialogData {
  data
}

export const DATE_FORMATS = {
  parse: {
    dateInput: "YYYY/MM/DD",
  },
  display: {
    dateInput: "YYYY/MM/DD",
    monthYearLabel: "YYYY MMM",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY MMMM",
  },
}

@Component({
  selector: 'app-national-holiday-dialog',
  templateUrl: './national-holiday-dialog.component.html',
  styleUrls: ['./national-holiday-dialog.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})

export class NationalHolidayDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 画面タイトル
  public title: string = '新規登録';

  // 日付
  public selectedDate = new FormControl();

  // 祝日データ
  public target;

  // httpヘッダー
  public httpOptions;

  constructor(private http: HttpClient, private router: Router, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<NationalHolidayDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    if (this.data.data) {
      this.target = {
        id: this.data.data['id'],
        holiday: this.data.data['holiday'],
        classification: String(this.data.data['classification']),
        note: this.data.data['note']
      }
      this.title = '編集';
    } else {
      this.target = {
        id: null,
        holiday: null,
        classification: '0',
        note: null
      }
    }

    this.selectedDate = new FormControl(moment(this.target.holiday));
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 日付設定
   * @param event 入力日付
   */
  changeDate(event: MatDatepickerInputEvent<Moment>) {
    this.selectedDate = new FormControl(event.value);
    this.target.holiday = moment(this.selectedDate.value).format('YYYY-MM-DD');
  }

  /**
   * 登録
   */
  register() {
    this.http.post(
      API_BASE_URL + "/api/v1/nationalHoliday/save", this.target, this.httpOptions
    ).subscribe(res => {
      this.dialogRef.close();
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * キャンセル
   */
  cancel() {
    this.dialogRef.close();
  }
}


