import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MatDialogRef, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';

export interface DialogData {
  any
}

export const DATE_FORMATS = {
  parse: {
    dateInput: "YYYY年MM月DD日",
  },
  display: {
    dateInput: "YYYY年MM月DD日",
    monthYearLabel: "YYYY MMM DD",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY MMMM DD",
  },
}

@Component({
  selector: 'app-export-csv-dialog',
  templateUrl: './export-csv-dialog.component.html',
  styleUrls: ['./export-csv-dialog.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})

export class ExportCsvDialogComponent implements OnInit {
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  blob: Blob;

  //「表示年月」初期値_現在の日付から1ヵ月前の日付を取得
  monthYear = moment().subtract(1, 'months');

  // 月初日
  monthFirstDay = moment(this.monthYear).format("YYYY-MM-01");

  // エリア候補
  areas = [];

  // 選択したエリアID
  areaIds = [];

  // 業務報告書取込日 指定
  importDate: Boolean = false;

  // 業務報告書取込 開始日
  sDay = new FormControl();
  sDayString: String;

  // 業務報告書取込 終了日
  eDay = new FormControl();
  eDayString: String;

  /** ローディング */
  loadingCompleteData:boolean = true;

  constructor(private http: HttpClient, private router: Router, private dialogRef: MatDialogRef<ExportCsvDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {
    this.getAreas();
    this.sDay = new FormControl(new Date());
    this.sDayString = moment(this.sDay.value).format('YYYY-MM-DD 00:00:00');
    this.eDay = new FormControl(new Date());
    this.eDayString = moment(this.eDay.value).format('YYYY-MM-DD 23:59:00');
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * カレンダーの年選択時
   * @param normalizedYear 選択した年
   */
  chosenYearHandler(normalizedYear: moment.Moment) {
    this.monthYear = normalizedYear;
  }

  /**
   * カレンダーの月選択時
   * @param normalizedMonth 選択した月
   * @param datepicker
   * @param screenDisplay 画面表示切替
   */
  chosenMonthHandler(normalizedMonth: Moment) {
    this.monthYear = normalizedMonth;
  }

  /**
   * エリアのドロップダウン生成
   */
  getAreas() {
    this.http.get(
      API_BASE_URL + "/api/v1/area/AreaCsv", {headers: this.headers}
    ).subscribe(res => {
      this.areas = res as any[];
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 業務報告書 取込 開始日
   * @param event 
   */
  changeSDay(event: MatDatepickerInputEvent<Moment>) {
    this.sDay = new FormControl(event.value);
    this.sDayString = moment(this.sDay.value).format('YYYY-MM-DD 00:00:00');
  }

  /**
   * 業務報告書 取込 開始日
   * @param event 
   */
  changeEDay(event: MatDatepickerInputEvent<Moment>) {
    this.eDay = new FormControl(event.value);
    this.eDayString = moment(this.eDay.value).format('YYYY-MM-DD 23:59:00');
  }

  /**
   * CSV出力
   */
  downloadCSV() {
    this.loadingCompleteData = false;
    let titleDate = moment(this.monthYear).format("YYYY年MM月");
    this.monthFirstDay = moment(this.monthYear).format("YYYY-MM-01");
    let areaS = '';
    if (this.areaIds.length) {
      let arr = [];
      for (let i = 0; i < this.areas.length; i++) {
        if (this.areaIds.includes(this.areas[i].areaId)) {
          arr.push(this.areas[i].areaName)
        }
      }
      if (arr.length) {
        areaS = arr.join(',');
        areaS = '【' + areaS + '】';
      }
    }

    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/csv?date=' + this.monthFirstDay
      + '&importDate=' + this.importDate
      + '&sDay=' + this.sDayString
      + '&eDay=' + this.eDayString
      + '&area=' + this.areaIds,
      {headers: this.headers, responseType : 'blob' as 'json'}
    )
    .subscribe(res => {
      let report = res as any;

      this.blob = new Blob([report], {type: 'text/csv'});
      var downloadURL = window.URL.createObjectURL(report);
      var link = document.createElement('a');
      link.href = downloadURL;

      // ダウンロードしたファイル名
      link.download = '給与奉行用CSV' + titleDate + areaS + '.csv';

      link.click();
      this.loadingCompleteData = true;
    },
    error => {
      this.loadingCompleteData = true;
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  cancel() {
    this.dialogRef.close();
  }
}


