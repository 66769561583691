/**
 * 契約一覧画面のコントローラ
 * @author toguchi
 */
import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { BaseComponent } from '../base.component';
import { ManagementPlanDialogComponent } from '../agreements/management-plan-dialog/management-plan.component';

export interface Agreement {
  name_billing_address_1: string;
  nameSite1: string;
  nameWorkContent: string;
}

@Component({
  selector: 'app-agreements',
  templateUrl: './agreements.component.html',
  styleUrls: ['./agreements.component.less']
})
export class AgreementsComponent extends BaseComponent implements OnInit, AfterViewInit {

  // 契約一覧テーブルのデータソース
  public dataSource = new MatTableDataSource();

  // エクスポート用のデータ
  public exportData = [];

  // 契約一覧の全レコードの件数
  public dataSourceLength = 0;

  public nowLoading = true;
  
  // エクスポート用ローディング
  public loadingExport = true;

  public keyword = "";

  public filterNameBillingAddress = "";

  public filterNameSite = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;

  @ViewChild('TABLE') table: ElementRef;

  public columnDefs = [
    'name_billing_address_1',
    'nameSite1',
    'nameWorkContent'
  ];
  
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(
    private http: HttpClient,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone,
    private dialog1: MatDialog,
  ) {
    super();
  }
  
  ngOnInit() {}

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ページ読み込み後の初期化メソッド
   */
  ngAfterViewInit() {
    this.filterNameBillingAddress = 
      localStorage.getItem('search_agreements_nameBillingAddress') != null ? localStorage.getItem('search_agreements_nameBillingAddress') : "";
    this.filterNameSite = 
      localStorage.getItem('search_agreements_nameSite') != null ? localStorage.getItem('search_agreements_nameSite') : "";
    
    this.dataSource.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
      this.getTotalSize(this.keyword);
    });
    this.getTotalSize(this.keyword);
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
    
    this.cdr.detectChanges();
  }

  /**
   * セルクリック時のコールバック
   * @param event 選択された行オブジェクト
   */
  cellClicked(event) {
    this.router.navigate(['/agreements/detail'], { queryParams: {
      cdSite: event.contractLedgerId.cdSite,
      cdBillingAddress: event.contractLedgerId.cdBillingAddress,
      noRec: event.contractLedgerId.noRec
    }
    });
  }

  applyFilter(keyword: string) {
    this.keyword = keyword;
    this.paginator.pageIndex = 0;
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
    this.getTotalSize(this.keyword);
  }

  /**
   * 契約一覧を取得します
   * @param index 開始インデックス
   * @param size  取得サイズ
   */
  getAgreements(index: number, size: number) {
    localStorage.setItem('search_agreements_nameBillingAddress', this.filterNameBillingAddress);
    localStorage.setItem('search_agreements_nameSite', this.filterNameSite);
    this.nowLoading = true;
    // console.info(this.filterNameBillingAddress + ", " + this.filterNameSite);

    // 表示する「年度」を生成
    let month = moment().month();
    let year = moment().year();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/contractLedgers?s=0&e=8000&index=' + index
        + "&size=" + size
        + "&filterNameBillingAddress=" + encodeURIComponent(this.filterNameBillingAddress)
        + "&filterNameSite=" + encodeURIComponent(this.filterNameSite)
        + "&year=" + year
      , {headers: this.headers}
      )
    .subscribe(res => {
      // console.info(res);
      this.dataSource = new MatTableDataSource(res as Agreement[]);
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 全レコードのサイズを取得します
   */
  getTotalSize(keyword: string) {
    this.loadingExport = true;

    // 表示する「年度」を生成
    let month = moment().month();
    let year = moment().year();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/contractLedgers/allAgreements?filterNameBillingAddress=' + encodeURIComponent(this.filterNameBillingAddress)
        + "&filterNameSite=" + encodeURIComponent(this.filterNameSite)
        + "&year=" + year
      , {headers: this.headers}
      ).subscribe(res => {
      this.dataSourceLength = res as number;
      
      let allData = res as [];
      if (allData && allData.length) {
        this.dataSourceLength = allData.length;
        this.exportData = [];
        // エクセル用にカラムを生成
        for (let i = 0; i < allData.length; i++) {
          const a = allData[i]
          // 得意先名
          let client = a['name_billing_address_1']
          // 物件名
          let site = a['nameSite1']
          // 作業名
          let work = a['nameWorkContent']
          this.exportData.push({ '得意先名': client, '物件名': site, '作業名': work })
        }
      } else {
        this.exportData = [];
        this.dataSourceLength = 0;
      }
      this.loadingExport = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 経営計画書 ダウンロード
   */
  downloadManagementPlan() {
    this.http.get(
      API_BASE_URL + '/api/v1/contractLedgers/downloadManagementPlan',
      {headers: this.headers, responseType : 'blob' as 'json'}
    )
    .subscribe(res => {
      let report = res as any;

      var blob = new Blob([report], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      var downloadURL = window.URL.createObjectURL(report);
      var link = document.createElement('a');
      link.href = downloadURL;

      link.download = '経営計画書_' + moment().format('YYYY年MM月DD日');

      link.click();
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 経営計画書の設定ダイアログを表示
   */
  setManagementPlan() {
    this.dialog1.open(ManagementPlanDialogComponent, {});
  }

  /**
   * エクスポート
   */
  downloadCsv() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    // ファイル名設定
    let fileName = '契約一覧.xlsx';
    XLSX.writeFile(wb, fileName);
  }
}
