import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatDialog, DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDatepicker, MatDatepickerInputEvent, MatSort, Sort } from '@angular/material';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ExportCsvDialogComponent } from './export-csv-dialog/export-csv-dialog.component';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { ImportReportDialogComponent } from './import-report-dialog/import-report-dialog.component';

export interface Staff {
  nameStaff: string;
  nameSite: string;
  january: string;
  february: string;
  march: string;
  april: string;
  may: string;
  june: string;
  july: string;
  august: string;
  september: string;
  october: string;
  november: string;
  december: string;
  nameSalesOfficeCharge: string;
}

export const YEAR_FORMATS = {
  parse: {
    dateInput: 'YYYY年',
  },
  display: {
    dateInput: 'YYYY年',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: YEAR_FORMATS},
  ],
})

export class ConfirmationComponent implements OnInit {
  // 清掃員一覧テーブルのデータソース
  data = new MatTableDataSource();

  // 時間差分
  timeDiff = new MatTableDataSource();

  // 時間差分（取得した状態　全データ）
  diffData = [];

  // 時間差分（ソート後）
  sortData = [];

  // 清掃員一覧の全レコードの件数
  dataSourceLength = 0;

  nowLoading = true;

  filterName = "";

  filterNameKana = "";

  siteName = "";

  areas = [];

  areaIds = "";

  // 「表示年」年月日
  selectedYear = new FormControl(moment());

  // 「表示年」年のみ
  year: number = moment(this.selectedYear.value).year();

  // 時間差分　日
  differenceDay = false;

  // 時間差分　週
  differenceWeek = false;

  // 時間差分　月
  differenceMonth = false;

  // 時間差分　年
  differenceYear = false;

  // 時間差分　表示開始日 日・週
  timeDiffStartInput = moment();

  // 時間差分　表示終了日
  timeDiffEnd = new FormControl(moment());

  // 時間超過物件数
  plusCount = 0;

  // 時間不足物件
  minusCount = 0;

  // 時間差分　年　ヘッダー
  header = [];

  // 物件毎・契約毎のトグルボタン
  dif = 'byContract';

  // 物件毎か否か
  bySite = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // カラム名 月次一覧
  columnDefs = [
    'name',
    'nameSite',
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
    'nameSalesOffice'
  ];

  // カラム名 時間差分 共通部分
  timeDiffColumn = [
    'nameSite'
  ];

  // カラム名 時間差分 日
  columnDefsDay = [];

  // カラム名 時間差分 週
  columnDefsWeek = [
    'nameSite',
    'one',
    'two',
    'three',
    'four',
    'five',
    'totalHours'
  ];

  // カラム名 時間差分 月
  columnDefsMonth = [
    'nameSite',
    'jan',
    'feb',
    'mar',
    'apl',
    'may',
    'jun',
    'jul',
    'aug',
    'sep',
    'oct',
    'nov',
    'dec',
    'totalHours'
  ];

  // カラム名 時間差分 年
  columnDefsYear = [];

  constructor(
    private http: HttpClient,
    private router: Router,
    private dialog1: MatDialog,
    private dialog2: MatDialog,
    private dialog3: MatDialog,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone) { }

  ngOnInit() {
    this.data.sort = this.sort;
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
  * ページ読み込み後の初期化メソッド
  */
  ngAfterViewInit() {
    this.filterName = 
      localStorage.getItem('search_staffs_name') != null ? localStorage.getItem('search_staffs_name') : "";
    this.filterNameKana = 
      localStorage.getItem('search_staffs_nameKana') != null ? localStorage.getItem('search_staffs_nameKana') : "";
      this.siteName = 
        localStorage.getItem('confirmation_site_name') != null ? localStorage.getItem('confirmation_site_name') : "";
    this.areaIds = 
      localStorage.getItem('search_staffs_areaIds') != null ? JSON.parse(localStorage.getItem('search_staffs_areaIds')) : [];

    this.data.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      if (!this.differenceDay && !this.differenceWeek && !this.differenceMonth && !this.differenceYear) {
        this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
      } else {
        this.getPage();
      }
    });
    if (!this.differenceDay && !this.differenceWeek && !this.differenceMonth && !this.differenceYear) {
      this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
    }
    
    this.getAreas();
    this.cdr.detectChanges();
    // QR読込 ダイアログを閉じたら、データを再取得
    this.dialog2.afterAllClosed.subscribe(res => {
      this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
    })
  }

  /**
  * セルクリック時のコールバック
  * @param event 選択された行オブジェクト
  */
  clickSite(event) {
    console.info(event);
    this.router.navigate(['/buildings/'+event.cdSite]);
  }

  /**
   * 清掃員名クリック時イベント　勤務実績情報ダイアログへ
   * @param event 清掃員情報
   * @param row 
   */
  openDialog(event, row: any) {
    console.info(event);
    this.dialog1.open(ConfirmationDialogComponent, {
      data: {
        data: event
      }
    });
  }

  /**
   * データ再取得
   */
  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
  * 契約一覧を取得します
  * @param index 開始インデックス
  * @param size  取得サイズ
  */
  getAgreements(index: number, size: number) {
    localStorage.setItem('search_staffs_name', this.filterName);
    localStorage.setItem('search_staffs_nameKana', this.filterNameKana);
    localStorage.setItem('confirmation_site_name', this.siteName);
    localStorage.setItem('search_staffs_areaIds', JSON.stringify(this.areaIds));
    
    this.initTimeDiff();
    
    this.nowLoading = true;
    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/confirmation?s=0&e=8000&index='+ index
        + "&size=" + size
        + "&filterName="+ encodeURIComponent(this.filterName)
        + "&filterNameKana=" + encodeURIComponent(this.filterNameKana)
        + "&siteName=" + encodeURIComponent(this.siteName)
        + "&areasStr=" + this.areaIds
        + "&year=" + this.year,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.data = new MatTableDataSource(res as Staff[]);

      let data = res as any[];
      if (data.length > 0) {
        this.dataSourceLength = data[0].totalSize;        
      } else {
        this.dataSourceLength = 0
      }
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * エリアのドロップダウン生成
   */
  getAreas() {
    this.http.get(
      API_BASE_URL + "/api/v1/area", {headers: this.headers}
    ).subscribe(res => {
      this.areas = res as any[];
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 「表示年」切替
   * @param normalizedYear 
   */
  // 手入力した場合
  handlerManual(normalizedYear: MatDatepickerInputEvent<moment.Moment>) {
    this.paginator.pageIndex = 0;
    const ctrlValue = this.selectedYear.value;
    ctrlValue.year(normalizedYear);
    this.selectedYear.setValue(ctrlValue);
    this.year = moment(this.selectedYear.value).year();
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize)

    this.timeDiffEnd.setValue(ctrlValue);
    // 時間差分　月　データ取得
    this.changeTimeDifferenceMonth(this.paginator.pageIndex, this.paginator.pageSize);
    // 時間差分　年　データ取得
    // データ取得に時間がかかるので警告を出す
    if (this.differenceYear) {
      if(window.confirm('データの取得に時間がかかりますがよろしいでしょうか？')) {
        this.changeTimeDifferenceYear(this.paginator.pageIndex, this.paginator.pageSize);
      }
    }
  }
  /**
   * datepickerで選択した場合
   * @param normalizedYear 
   * @param datepicker 
   */
  handler(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    this.paginator.pageIndex = 0;
    const ctrlValue = this.selectedYear.value;
    ctrlValue.year(normalizedYear.year());
    this.selectedYear.setValue(ctrlValue);
    this.year = moment(this.selectedYear.value).year();

    if (!this.differenceDay && !this.differenceWeek && !this.differenceMonth && !this.differenceYear) {
      this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize)
    } else if (this.differenceMonth) {
      // 時間差分　月　データ取得
      this.changeTimeDifferenceMonth(this.paginator.pageIndex, this.paginator.pageSize);
    } else if (this.differenceYear) {
      // 時間差分　年　データ取得
      let start: number = moment(this.selectedYear.value).year();
      let end: number =  moment(this.timeDiffEnd.value).year();
      let year: number = moment(end).year() - moment(start).year()
      if (year >= 0) {
        // データ取得に時間がかかるので警告を出す
        if(window.confirm('データの取得に時間がかかりますがよろしいでしょうか？')) {
          this.changeTimeDifferenceYear(this.paginator.pageIndex, this.paginator.pageSize);
        }
      }
    }
    datepicker.close();
  }

  /**
   * datepickerで選択した場合　時間差分　年
   * @param normalizedYear 
   * @param datepicker 
   */
  handlerDiffYear(normalizedYear: moment.Moment, datepicker: MatDatepicker<moment.Moment>) {
    const ctrlValue = this.timeDiffEnd.value;
    ctrlValue.year(normalizedYear.year());
    this.timeDiffEnd.setValue(ctrlValue);
    this.year = moment(this.timeDiffEnd.value).year();
    
    // 時間差分　年　データ取得
    this.paginator.pageIndex = 0;
    // データ取得に時間がかかるので警告を出す
    if(window.confirm('データの取得に時間がかかりますがよろしいでしょうか？')) {
      this.changeTimeDifferenceYear(this.paginator.pageIndex, this.paginator.pageSize);
    }
    datepicker.close();
  }

  /**
   * カレンダーの年選択時　時間差分 日・週
   * @param normalizedYear 選択した年
   */
  chosenYearHandler(normalizedYear: moment.Moment) {
    this.timeDiffStartInput = normalizedYear;
  }
  
  /**
   * カレンダーの月選択時　時間差分 日・週
   * @param normalizedMonth 選択した月
   * @param datepicker
   * @param screenDisplay 画面表示切替
   */
  chosenMonthHandler(normalizedMonth: moment.Moment) {
    this.paginator.pageIndex = 0;
    this.timeDiffStartInput = normalizedMonth;
    if (this.differenceDay) {
      // 時間差分　日　データ取得
      this.changeTimeDifferenceDay(this.paginator.pageIndex, this.paginator.pageSize);
    } else if (this.differenceWeek) {
      // 時間差分　週　データ取得
      this.changeTimeDifferenceWeek(this.paginator.pageIndex, this.paginator.pageSize);
    }
  }

  /**
   * データを取得
   */
  changeTimeDiff() {
    this.paginator.pageIndex = 0;
    if (this.dif === 'bySite') {
      this.bySite = true
    } else {
      this.bySite = false
    }
    this.changeTimeDifferenceDay(this.paginator.pageIndex, this.paginator.pageSize);
    this.changeTimeDifferenceWeek(this.paginator.pageIndex, this.paginator.pageSize);
    this.changeTimeDifferenceMonth(this.paginator.pageIndex, this.paginator.pageSize);
    
    // データ取得に時間がかかるので警告を出す
    if (this.differenceYear) {
      if(window.confirm('データの取得に時間がかかりますがよろしいでしょうか？')) {
        this.changeTimeDifferenceYear(this.paginator.pageIndex, this.paginator.pageSize);
      }
    }
  }

  /**
   * 時間差分チェックボックスイベント　日ごとのデータ取得
   */
  changeTimeDifferenceDay(index: number, size: number) {
    this.paginator.pageIndex = index;
    this.paginator.pageSize = size;
    
    // チェックがつくのは1つのみにする
    if (this.differenceDay) {
      this.initTimeDiff();
      this.changeCheck(true, false, false, false);
      // データ取得
      this.timeDiffColumn = ['nameSite'];
      this.columnDefsDay = [];
      let startDate = this.timeDiffStartInput.format('YYYY-MM-01');
      let endDate = this.timeDiffStartInput.endOf('month').format('YYYY-MM-DD');

      //表示月の日数を取得
      let date = moment(endDate).date();
      // カラム作成
      for (let i = 1; i <= date; i++) {
        this.columnDefsDay.push('hours' + i);      
      }
      this.timeDiffColumn = this.timeDiffColumn.concat(this.columnDefsDay).concat('totalHours');

      this.nowLoading = true;

      this.http.get(
        API_BASE_URL + '/api/v1/contractDetail/timeDifferenceDay?s=0&e=8000&siteName=' + encodeURIComponent(this.siteName)
          + "&startDate=" + startDate
          + "&endDate=" + endDate
          + "&bySite=" + this.bySite,
        {headers: this.headers}
      )
      .subscribe(res => {
        let data = res as any[];
        this.setTimeDiffData(data);
        this.nowLoading = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });

    } else {
      return;
    }
  }

  /**
   * フォントの色を決める
   * @param row 
   * @param idx 
   */
  fontColor1(row: any, idx: number) {
    if (row.differenceArr && row.differenceArr.length > idx) {
      let time = row.differenceArr[idx];
      if (time > 0) {
        return 'blue-color';
      } else if (time < 0) {
        return 'red-color';
      }      
    }
  }

  /**
   * フォントの色を決める
   * @param row 
   * @param idx 
   */
  fontColor2(time: any) {
    if (time > 0) {
      return 'blue-color';
    } else if (time < 0) {
      return 'red-color';
    }
  }

  /**
   * 時間差分チェックボックスイベント　週ごとのデータ取得
   */
  changeTimeDifferenceWeek(index: number, size: number) {
    this.paginator.pageIndex = index;
    this.paginator.pageSize = size;
    
    // チェックがつくのは1つのみにする
    if (this.differenceWeek) {
      this.initTimeDiff();
      
      this.changeCheck(false, true, false, false);
      // データ取得
      let startDate = this.timeDiffStartInput.format('YYYY-MM-01');
      let endDate = this.timeDiffStartInput.endOf('month').format('YYYY-MM-DD');
  
      this.nowLoading = true;
  
      this.http.get(
        API_BASE_URL + '/api/v1/contractDetail/timeDifferenceWeek?s=0&e=8000&index='+ 0
          + "&size=" + 0
          + "&siteName=" + encodeURIComponent(this.siteName)
          + "&startDate=" + startDate
          + "&endDate=" + endDate
          + "&cdSite=" + ''
          + "&bySite=" + this.bySite,
        {headers: this.headers}
      )
      .subscribe(res => {
        let data = res as any[];
        this.setTimeDiffData(data);
        this.nowLoading = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });
    } else {
      return;
    }
  }

  /**
   * 時間差分チェックボックスイベント　月ごとのデータ取得
   */
  changeTimeDifferenceMonth(index: number, size: number) {
    // チェックがつくのは1つのみにする
    if (this.differenceMonth) {
      this.initTimeDiff();
      this.changeCheck(false, false, true, false);
      
      let startDate = moment(this.selectedYear.value).format('YYYY-01-01');
      let endDate =  moment(this.selectedYear.value).format('YYYY-12-31');

      this.nowLoading = true;

      this.http.get(
        API_BASE_URL + '/api/v1/contractDetail/timeDifferenceMonth?s=0&e=8000&siteName=' + encodeURIComponent(this.siteName)
          + "&startDate=" + startDate
          + "&endDate=" + endDate
          + "&bySite=" + this.bySite,
        {headers: this.headers}
      )
      .subscribe(res => {
        this.timeDiff = new MatTableDataSource(res as []);

        let data = res as any[];
        this.setTimeDiffData(data);
        this.nowLoading = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });
    } else {
      return;
    }
  }

  /**
   * 時間差分チェックボックスイベント　年ごとのデータ取得
   */
  changeTimeDifferenceYear(index: number, size: number) {

    // チェックがつくのは1つのみにする
    if (this.differenceYear) {
      this.initTimeDiff();
      this.changeCheck(false, false, false, true);
      
      this.timeDiffColumn = ['nameSite'];
      this.columnDefsYear = [];

      let startDate = moment(this.selectedYear.value).format('YYYY-01-01');
      let endDate =  moment(this.timeDiffEnd.value).format('YYYY-12-31');

      let year = moment(endDate).year() - moment(startDate).year()
      
      // カラム作成
      for (let i = 0; i < year+1; i++) {
        this.columnDefsYear.push('year' + (i+1));
      }
      this.timeDiffColumn = this.timeDiffColumn.concat(this.columnDefsYear).concat('totalHours');

      this.nowLoading = true;

      this.http.get(
        API_BASE_URL + '/api/v1/contractDetail/timeDifferenceYear?s=0&e=8000&siteName=' + encodeURIComponent(this.siteName)
          + "&startDate=" + startDate
          + "&endDate=" + endDate
          + "&bySite=" + this.bySite,
        {headers: this.headers}
      )
      .subscribe(res => {
        this.timeDiff = new MatTableDataSource(res as []);

        let data = res as any[];
        this.setTimeDiffData(data);
        this.nowLoading = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.nowLoading = false;
      });

    } else {
      return;
    }
  }

  /**
   * 時間差分　日　ヘッダー曜日生成
   * @param i 
   * @returns 
   */
  createDayHeader(i: number) {
    let date = this.timeDiffStartInput.format('YYYY-MM-' + (++i));
    let week = moment(date).day();
    let weekday = '';

    switch(week) {
      case 0:
        weekday = '(日)';
        break;
        case 1:
        weekday = '(月)';
        break;
        case 2:
        weekday = '(火)';
        break;
        case 3:
        weekday = '(水)';
        break;
        case 4:
        weekday = '(木)';
        break;
        case 5:
        weekday = '(金)';
        break;
        case 6:
        weekday = '(土)';
        break;
    }

    weekday = (i++) + weekday;
    return weekday;
  }

  /**
   * 時間差分のチェックが付くのは1つのみにする
   * @param d 日
   * @param w 週
   * @param m 月
   * @param y 年
   */
  changeCheck(d: boolean, w: boolean, m: boolean, y: boolean) {
    this.differenceDay = d;
    this.differenceWeek = w;
    this.differenceMonth = m;
    this.differenceYear = y;
  }

  /**
   * 物件数表示時のCSSクラス名セット
   * @returns 
   */
  addSiteCount() {
    if (this.differenceDay || this.differenceWeek || this.differenceMonth || this.differenceYear) {
      return 'pagi';
    }
  }

    /**
   * 物件数表示時のCSSクラス名セット
   * @returns 
   */
  changeWidth() {
    if (this.differenceDay || this.differenceWeek || this.differenceMonth || this.differenceYear) {
      return 'time-diff-pagi';
    }
  }

  /**
   * 名前クリックイベント　稼働管理へ遷移
   * @param name 物件名
   */
  toOperationPage(name: string) {
    localStorage.setItem('search_site_name', name);
  }

  /**
   * 時間差分データ表示するか確認
   * @param e イベント
   */
  onTimeClick(e) {
  
    // データ取得に時間がかかるので警告を出す
    if(window.confirm('データの取得に時間がかかりますがよろしいでしょうか？')) {
    } else {
      // キャンセル押下時　何もしない    
      e.preventDefault();
    }
  }
    
  /**
   * 業務報告インポート
   */
  importReport() {
    this.dialog2.open(ImportReportDialogComponent);
  }

  /**
   * 給与CSVボタン
   */
  exportCsv() {
    this.dialog3.open(ExportCsvDialogComponent, {
    });
  }

  /**
   * 差分のソート
   * @param sortState ソート対象列、昇順、降順
   * @returns 
   */
  sortChange(sortState: Sort) {
    if (!sortState || !this.diffData.length) {
      return;
    }
    this.paginator.pageIndex = 0;
    let isAsc = sortState.direction == 'asc';
    this.sortData = [];
    this.sortData = this.diffData.slice();
    
    // 昇順もしくは降順の場合
    if (sortState.direction) {
      // 合計列の場合
      if (sortState.active == 'totalHours') {
        this.sortData.sort((a,b) => {
          return this.compare(a['totalDifference'], b['totalDifference'], isAsc);
        });

      // 合計列以外
      } else {
        // 日 もしくは 年
        if (this.differenceDay || this.differenceYear) {
          let idx = Number(sortState.active.replace(/[^0-9]/g, ''));
          idx--;
  
          this.sortData.sort((a,b) => {
            return this.compare(a['differenceArr'][idx], b['differenceArr'][idx], isAsc);
          });

        // 週 もしくは 月
        } else {
          let idx = sortState.active;
          this.sortData.sort((a,b) => {
            return this.compare(a['difference' + idx], b['difference' + idx], isAsc);
          });
        }
      }
    }

    // 表示件数絞り込む
    let start = this.paginator.pageIndex * this.paginator.pageSize;
    let end = (this.paginator.pageIndex+1) * this.paginator.pageSize;

    // 画面に反映
    this.timeDiff = new MatTableDataSource(this.sortData.slice(start, end) as []);
  }
  
  /**
   * ソート
   * @param a 
   * @param b 
   * @param isAsc 
   * @returns 
   */
  compare(a, b, isAsc) {
    return (Number(a) < Number(b) ? -1 : 1) * (isAsc ? 1 : -1);
  }

  /**
   * 取得したデータをセット
   */
  setTimeDiffData(data) {
    
    console.log(data)
    if (data.length > 0) {
      this.dataSourceLength = data[0].totalSize;
      this.minusCount = data[0].minusCount;
      this.plusCount = data[0].plusCount;
      this.diffData = data;
    } else {
      this.dataSourceLength = 0
      this.minusCount = 0;
      this.plusCount = 0;
    }
    this.getPage();
  }

  /**
   * 時間差分の
   * ページネーター表示件数分取得
   */
  getPage() {
    let start = this.paginator.pageIndex * this.paginator.pageSize;
    let end = (this.paginator.pageIndex+1) * this.paginator.pageSize;

    let data = [];
    if (this.sortData && this.sortData.length) {
      data = this.sortData.slice();
    } else {
      data = this.diffData.slice();
    }

    if (data && data.length) {
      // 表示件数絞り込む
      this.timeDiff = new MatTableDataSource(data.slice(start, end) as []);
    } else {
      this.timeDiff = new MatTableDataSource(data as []);
    }
  }

  /**
   * 時間差分の初期化
   */
  initTimeDiff() {
    localStorage.setItem('confirmation_site_name', this.siteName);
    this.diffData = [];
    this.sortData = [];
    this.sort.direction = '';
    this.sort.sortChange.emit(this.sort);
  }
}
