import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { ActivatedRoute, Router }            from '@angular/router';
import { API_BASE_URL }       from '../../environments/environment';
import { MatDialog, MatPaginator, MatTableDataSource } from '@angular/material';
import * as XLSX from 'xlsx';
import { BaseComponent } from '../base.component';
import { ContractDetailNoteDialogComponent } from './contract-detail-note-dialog/contract-detail-note-dialog.component';

@Component({
  selector: 'app-contract-detail',
  templateUrl: './contract-detail.component.html',
  styleUrls: ['./contract-detail.component.less']
})

export class ContractDetailComponent extends BaseComponent implements OnInit {
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });
  
  // テーブルのカラム名
  columnDefs = [
    'nameSite',
    'cdContract',
    'nameContentsWork',
    'mon',
    'tue',
    'wed',
    'thu',
    'fri',
    'sat',
    'sun',
    'monthlyFee',
    'monthlyHours',
    'hourlyWage',
    'note',
    'rank',
    'cdSite',
    'nameCustomer',
    'nameBranch',
    'startDateContract',
    'endDateContract',
    'holiday',
    'companyHoliday'
  ];

  // ページネーター
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // エクスポート時の内容
  @ViewChild('TABLE') table: ElementRef;

  // テーブルのデータソース
  contractList = new MatTableDataSource();

  // エクスポート用データ
  exportData = [];

  // 絞込み_物件名
  filterNameSite = '';

  // 絞込み_得意先名
  filterNameCustomer = '';

  // ローディング中の表示
  nowLoading = true;

  // エクスポート用ローディング
  loadingExport = false;

  // データ件数
  paginatorLength = 0;
  
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
    ) {
    super();
  }

  ngOnInit() {}

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  ngAfterViewInit() {
    this.filterNameCustomer = 
      localStorage.getItem('search_buildings_nameBillingAddress') != null ? localStorage.getItem('search_buildings_nameBillingAddress') : "";
    this.filterNameSite = 
      localStorage.getItem('search_buildings_nameSite') != null ? localStorage.getItem('search_buildings_nameSite') : "";
      this.contractList.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      this.getContractDetailList(this.paginator.pageIndex, this.paginator.pageSize);
    })
    
    this.dialog.afterAllClosed.subscribe(res => {
      this.getContractDetailList(this.paginator.pageIndex, this.paginator.pageSize);
    });
    this.cdr.detectChanges();
  }

  /**
   * リストのデータ取得
   */
  getContractDetailList(index: number, size: number) {
    this.nowLoading = true;
    this.paginatorLength = 0;

    localStorage.setItem('search_buildings_nameBillingAddress', this.filterNameCustomer);
    localStorage.setItem('search_buildings_nameSite', this.filterNameSite);

    this.http.get(
      API_BASE_URL + '/api/v1/contractDetail?s=0&e=8000&index='
        + index
        + '&size=' + size
        + '&filterNameSite=' + encodeURIComponent(this.filterNameSite)
        + '&filterNameCustomer=' + encodeURIComponent(this.filterNameCustomer),
      {headers: this.headers}
    )
    .subscribe(res => {
      let data = res as any[];
      if (data.length && data.length > 0) {
        this.contractList = new MatTableDataSource(data);
        this.paginatorLength = data[0].totalSize;
      } else {
        this.contractList = new MatTableDataSource([]);
        this.paginatorLength = 0;
      }
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * エクスポート用に全件取得
   */
  getAll() {
    this.loadingExport = true;

    this.http.get(
      API_BASE_URL + '/api/v1/contractDetail/allContractDetail?s=0&e=8000'
        + '&filterNameSite=' + encodeURIComponent(this.filterNameSite)
        + '&filterNameCustomer=' + encodeURIComponent(this.filterNameCustomer),
      {headers: this.headers}
    )
    .subscribe(res => {
      let allData = res as [];
      if (allData && allData.length) {
        this.exportData = [];
        // エクセル用にカラムを生成
        for (let i = 0; i < allData.length; i++) {
          const a = allData[i];

          // 契約開始日
          let startDateContract = '';
          if (a['startDateContract'] != 0) {
            startDateContract = a['startDateContract'];
          }

          // 契約終了日
          let endDateContract = '';
          if (a['endDateContract'] != 0) {
            endDateContract = a['endDateContract'];
          }

          this.exportData.push({
              '物件名': a['nameSite']
            , '契約コード': a['cdContract']
            , '作業内容': a['nameContentsWork']
            , '月曜': a['mon']
            , '月曜出勤形態': this.attendance(a['attendanceMonday'])
            , '火曜': a['tue']
            , '火曜出勤形態': this.attendance(a['attendanceTuesday'])
            , '水曜': a['wed']
            , '水曜出勤形態': this.attendance(a['attendanceWednesday'])
            , '木曜': a['thu']
            , '木曜出勤形態': this.attendance(a['attendanceThursday'])
            , '金曜': a['fri']
            , '金曜出勤形態': this.attendance(a['attendanceFriday'])
            , '土曜': a['sat']
            , '土曜出勤形態': this.attendance(a['attendanceSaturday'])
            , '日曜': a['sun']
            , '日曜出勤形態': this.attendance(a['attendanceSunday'])
            , '月額': this.amountFormat(a['monthlyFee'])
            , '月時間': a['monthlyHours']
            , '時給単価': this.amountFormat(a['hourlyWage'])
            , '祝日出勤': a['nameKubunHolidayContract']
            , '全社休日出勤': a['hasExportingCompanysHolidayContractName']
            , '備考': a['note']
            , '物件ランク': a['rank']
            , '物件コード': a['cdSite']
            , '得意先名': a['nameCustomer']
            , '支店名': a['nameBranch']
            , '契約開始日': startDateContract
            , '契約終了日': endDateContract
          })
        }
      } else {
        this.exportData = [];
      }
      this.exportXlsx();
      this.loadingExport = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 検索実行
   */
  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getContractDetailList(this.paginator.pageIndex, this.paginator.pageSize)
  }

  /**
   * 出勤形態区分から名称を判定
   * @param val 出勤形態区分コード
   * @returns 
   */
  attendance(val) {
    let res = '';
    switch (val) {
      case '01':
        res = '（第 1，3，5 週）'
        break;
      case '02':
        res = '（第 1，3 週）'
        break;
      case '03':
        res = '（第 2，4 週）'
        break;
      case '04':
        res = '（第 1 週）'
        break;
      case '05':
        res = '（第 2 週）'
        break;
      case '06':
        res = '（第 3 週）'
        break;
      case '07':
        res = '（第 4 週）'
        break;
      case '08':
        res = '（第 5 週）'
        break;
      case '09':
        res = '（隔週）'
        break;
    
      default:
        break;
    }
    return res;
  }

  /**
   * エクスポート
   */
  exportXlsx() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    XLSX.writeFile(wb, '契約詳細.xlsx');
  }

  /**
   * 背景色設定
   * @param index 番号
   */
  setBgColor(index: number) {
    if (index % 2 == 0) {
      return 'blue-contract-bg';
    }
  }

  /**
   * 備考欄編集イベント
   * @param row 対象データ
   */
  editNote(row) {
    this.dialog.open(ContractDetailNoteDialogComponent, {
      data: { ...row }
    });
  }

  /**
   * 金額を3桁区切りにする
   * @param val 値
   * @returns 
   */
  amountFormat(val) {
    if (!val) {
      return null
    } else {
      return Number(val).toLocaleString()
    }
  }
}
