import { Component, OnInit, ViewChild, AfterViewInit, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { Router } from '@angular/router';
import { API_BASE_URL } from '../../environments/environment';
import { MatTableDataSource, MatPaginator, MatDatepickerInputEvent } from '@angular/material';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';

export interface Log {
  day: string;
  createdTime: string;
  userId: string;
  serviceName: string;
  operation: string;
  logText: string;
}

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.less']
})
export class LogComponent implements OnInit, AfterViewInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // テーブルデータソース
  logList = new MatTableDataSource();

  // 記録日
  startDay = new FormControl(new Date(moment().add(-6, 'days').format('YYYY/MM/DD')));
  endDay = new FormControl();
  startRecordDay: String;
  endRecordDay: String;

  // チェックボックス　エラーのみ
  error: boolean = false;

  // データ件数
  dataSourceLength = 0;
  
  // ローディング
  nowLoading = true;

  // ページネーター
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // カラム名
  columnDefs = [
    'day',
    'time',
    'userId',
    'serviceName',
    'operation',
    'logText'
  ];

  constructor(private http: HttpClient, private router: Router, private ngZone: NgZone) { }

  ngOnInit() {
    this.startRecordDay = moment(this.startDay.value).format('YYYY-MM-DD');
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ページ読み込み後の初期化メソッド
   */
  ngAfterViewInit() {
    this.paginator.page.subscribe(() => {
      this.getLogData(this.paginator.pageIndex, this.paginator.pageSize);
    });
    this.getLogData(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * 記録日（開始日）　変更イベント
   * @param event 開始日
   */
  changeStartDay(event: MatDatepickerInputEvent<Date>) {
    this.paginator.pageIndex = 0;
    this.startDay = new FormControl(event.value);
    this.startRecordDay = moment(this.startDay.value).format('YYYY-MM-DD');
    this.getLogData(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * 記録日（終了日）　変更イベント
   * @param event 終了日
   */
  changeEndDay(event: MatDatepickerInputEvent<Date>) {
    this.paginator.pageIndex = 0;
    this.endDay = new FormControl(event.value);
    this.endRecordDay = moment(this.endDay.value).format('YYYY-MM-DD');
    this.getLogData(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * ログデータ取得
   */
  getLogData(index: number, size: number) {
    this.nowLoading = true;
    this.http.get(
      API_BASE_URL + '/api/v1/log?s=0&e=8000&index=' + index + '&size=' + size + '&startDay=' + this.startRecordDay + '&endDay=' + this.endRecordDay + '&error=' + this.error,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.logList = new MatTableDataSource(res as Log[]);

      let data = res as any[];
      if (data.length > 0) {
        this.dataSourceLength = data[0].totalSize;        
      } else {
        this.dataSourceLength = 0
      }

      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
}
