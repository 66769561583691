import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';

export interface DialogData {
  any
}

@Component({
  selector: 'app-contract-detail-note-dialog',
  templateUrl: './contract-detail-note-dialog.component.html',
  styleUrls: ['./contract-detail-note-dialog.component.less']
})
export class ContractDetailNoteDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 物件名
  siteName = '';

  // 備考
  note = '';

  // 保存内容
  contractDetailNote = {
    note: '',
    updateUser: '',
    updateDate: null,
    contractDetailNoteId: {},
    address: ''
  }

  constructor(private router: Router, private http: HttpClient, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<ContractDetailNoteDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {
    if (this.data['nameSite']) {
      this.siteName = this.data['nameSite'];
    };
    if (this.data['note']) {
      this.note = this.data['note'];
    };
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 保存ボタンイベント
   */
  save() {
    let contractDetailNoteId = {
      cdSite: this.data['cdSite'],
      cdContract: this.data['cdContract']
    }
    this.contractDetailNote = {
      contractDetailNoteId,
      note: this.note,
      updateUser: localStorage.getItem("uid"),
      updateDate: new Date(),
      address: this.data['address']
    };

    // 新規登録の場合
    if (!this.data['noteId']) {
      this.contractDetailNote['createdUser'] = localStorage.getItem("uid");
      this.contractDetailNote['createdDate'] = new Date();
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    this.http.post(
      API_BASE_URL + '/api/v1/contractDetail/note?siteName=' + this.siteName,
      this.contractDetailNote, httpOptions
    ).subscribe(res => {
      this.dialogRef.close();
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.dialogRef.close();
    });
  }

  /**
   * 閉じるボタンイベント
   */
  close() {
    this.dialogRef.close();
  }

}
