import { Component, OnInit, Inject, ViewEncapsulation, NgZone }  from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';

export interface DialogData {
  any
}
@Component({
  selector: 'app-holiday-dialog',
  templateUrl: './holiday-dialog.component.html',
  styleUrls: ['./holiday-dialog.component.less'],
  encapsulation: ViewEncapsulation.None
})
export class HolidayDialogComponent implements OnInit {
  start: Date = new Date();
  end: Date = new Date();
  headers = new HttpHeaders({
    'X-LoginAccessKey': localStorage.getItem('accessKey')
  });
  sequenceNumber: string;
  staffs: any[];
  staff: any;
  statusChoices = [
    {key: 0, value: '休み'},
    {key: 1, value: '有休'},
    {key: 2, value: '欠員'},
    {key: 5, value: '要確認'},
    {key: 6, value: '指定有休'},
  ];
  type = this.statusChoices[0].key;
  note = '';
  targetDay = '';
  targetStart = '';
  targetEnd = '';
  insertedDate = null;

  // 画面上半分に表示するデータ
  holidayDialogData: any;

  // 画面表示　初期値：連日
  displaySpecifyDate = 'consecutive';

  // 複数日選択
  daysSelected: any[] = [];
  
  daysArray: any[] = [];

  // 選択した日（表示用）
  days:string = '';

  // 曜日ドロップダウン
  weekly = [
    {key: 1, value: '月曜日'},
    {key: 2, value: '火曜日'},
    {key: 3, value: '水曜日'},
    {key: 4, value: '木曜日'},
    {key: 5, value: '金曜日'},
    {key: 6, value: '土曜日'},
    {key: 0, value: '日曜日'}
  ];

  // 選択した曜日
  week = [];

  // チェック処理
  check = true;

  // 有休時間
  paidHolidayHours: number = 0;
  paidHolidayMinutes: string = '00';
  paidHolidayHoursInput: number = 0;
  paidHolidayMinutesInput: string = '00';
  hours: number[] = this.generateNums(12);
  minutes: string[] = [ '00', '25', '5', '75' ];
  paidHolidayUsageTime;
  paidHolidayUsageTimeInput;
  paidHolidayUsageTimeArray = [];
  phTimeCount = 0;

  // 有休時間入力 true:非活性 false:活性
  phTime:boolean = false;
  // 有休残数
  paidHolidayLeft: string;
  // 登録済みの有休利用日
  paidHolidayDays = [];
  // 代勤の最終更新者
  public operationCreatedUser = '';
  // 代勤の最終更新日
  public operationCreatedDate = '';
  // 休みの最終更新者
  public statusInsertedUser = '';
  // 休みの最終更新日
  public statusInsertedDate = '';

  // tbl_operation_noteに保存するデータ
  public operationNote: any[] = [];

  nowLoading = false;

  constructor(private router: Router, private http: HttpClient, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<HolidayDialogComponent>,
    private datePipe: DatePipe, private ngZone: NgZone) { }

  ngOnInit() {
    let target = this.data['target'];
    let data = this.data['data'];
    let day = moment(this.data['day']).format('YYYY-MM-DD');

    // 画面上部の表示データ取得
    this.getData(day);

    // 実働時間取得
    let workingHours = this.data['data']['workingHours'][this.data['target'] - 1];

    // 休みの更新情報生成
    let statusInfo = this.getStatusCreatedInfo(data, target);
    this.statusInsertedUser = statusInfo.createdUser;
    this.statusInsertedDate = statusInfo.createdDate;

    // 通常清掃員の勤務予定時間から半角スペースを削除
    let time = this.data['data']['workingTime'][this.data['target'] - 1].replace(/\s/g, '');
    // 通常清掃員の予定勤務時間をセット
    if (time && time != '-') {
      let timeSplit = time.split('-');
      this.targetStart = timeSplit[0];
      this.targetEnd = timeSplit[1];
    } else {
      this.targetStart = '-';
      this.targetEnd = '-';
    }

    this.sequenceNumber = this.data['data'].sequenceNumber;
    this.setWeekDay();
    this.getPaidHolidaysLeft();
    this.start = this.data['day'];
    this.start.setHours(0);
    this.start.setMinutes(0);
    this.start.setSeconds(0);
    this.end = new Date();
    this.end.setTime(this.start.getTime());
    // this.note = this.data['status']?this.data['status']['note'] : '';
    this.insertedDate = this.data['status']?moment(this.data['status']['insertedDate']).format('YYYY/MM/DD') : null;
    if(this.data['status'] && this.data['status']['status']) {
      for(var i = 0; i < this.statusChoices.length; i++) {
        if(this.statusChoices[i].key == this.data['status']['status'] as number) {
          this.type = this.statusChoices[i].key;
        }
      }
    }
    
    // メモ
    if (this.data['data']['operationNote'].length && this.data['data']['operationNote'][this.data['target'] - 1]) {
      this.note = this.data['data']['operationNote'][this.data['target'] - 1];
    }
    if (this.data['status'] && this.note == '') {
      // 休み登録より
      this.note = this.data['status']['note'];
    } else if (this.note == undefined) {
      this.note = '';
    }

    // 有休時間取得（有休登録されている場合）
    if (this.data['status'] && this.data['status']['paidHolidayUsageTime'] && (this.data['status']['status'] == '1' || this.data['status']['status'] == '6')) {
      this.paidHolidayUsageTimeArray.push(this.data['status']['paidHolidayUsageTime']);
      this.paidHolidayUsageTime = this.data['status']['paidHolidayUsageTime'];
    } else {
      // (新規登録する場合)
      this.paidHolidayUsageTimeArray.push(workingHours);
    }

    // 勤務日なら有休時間入力できない
    if (workingHours != '0.00' && workingHours != null && workingHours != '') {
      this.phTime = true;
    }

    // 有休時間を表示
    if(this.paidHolidayUsageTimeArray[0]) {
      this.setPaidHolidayTime(this.paidHolidayUsageTimeArray[0], workingHours);
    }
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 休みの開始日 変更時の処理
   * @param event 開始日
   */
  changeStart(event) {
    this.start = event.value;
    // 有休時間の非活性（初期化）
    this.phTime = true;
    // 有休の場合は、勤務日でない日があれば有休時間活性
    this.checkHolidayCount();
  }

  /**
   * 休みの終了日 変更時の処理
   * @param event 終了日
   */
  changeEnd(event) {
    this.end = event.value;
    // 有休時間の非活性（初期化）
    this.phTime = true;
    // 有休の場合は、勤務日でない日があれば有休時間活性
    this.checkHolidayCount();
  }

  cancel() {
    this.dialogRef.close();
  }

  /**
   * 画面表示用に曜日を生成
   */
  setWeekDay() {

    switch(this.data['day'].getDay()) {
      case 0:
        this.targetDay = '日曜';
        break;
        case 1:
        this.targetDay = '月曜';
        break;
        case 2:
        this.targetDay = '火曜';
        break;
        case 3:
        this.targetDay = '水曜';
        break;
        case 4:
        this.targetDay = '木曜';
        break;
        case 5:
        this.targetDay = '金曜';
        break;
        case 6:
        this.targetDay = '土曜';
        break;
    }
  }

  /**
   * 登録ボタンイベント
   * @returns 
   */
  async sendHoliday() {
    // 初期化
    this.phTimeCount = 0

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'X-LoginAccessKey': localStorage.getItem('accessKey')
      })
    };
    var statusList = new Array();
    var workingdays = true;

    // 画面表示が「連日」の場合
    if (this.displaySpecifyDate == 'consecutive') {
      let startDay = moment(this.start);
      let endDay = moment(this.end);
      let days = endDay.diff(startDay, 'days');

      for (let i = 0; i <= days; i++) {
        let dateS = moment(this.start).add(i, 'days').format('YYYY-MM-DD');

        let workingHours = await this.getWorkingHours2(dateS);

        // 勤務日に休み登録をした場合
        if(workingHours != '0.00') {
          workingdays = true;
          // 登録する休みの情報を生成
          if (!this.setStatusList(statusList, dateS, workingdays, i)) {
            return;
          }
        } else {
          // 勤務日ではない日に休み登録をした場合
          if(window.confirm(moment(dateS).format('YYYY/MM/DD') + 'は勤務日ではありませんがよろしいでしょうか？')) {
            workingdays = false;
            // 登録する休みの情報を生成
            if (!this.setStatusList(statusList, dateS, workingdays, i)) {
              return
            }
          } else {
            // キャンセルボタン押下時は何もしない
            return;
          }
        }
      }
      
    // 画面表示が「複数日指定」の場合
    } else if (this.displaySpecifyDate == 'multiple') {
      
      for (let i = 0; i < this.daysSelected.length; i++) {
        let date = moment(this.daysSelected[i]).format('YYYY-MM-DD');

        let workingHours = await this.getWorkingHours2(date);

        // 勤務日に休み登録をした場合
        if(workingHours != '0.00') {
          workingdays = true;
          // 登録する休みの情報を生成
          if (!this.setStatusList(statusList, this.daysSelected[i], workingdays, i)) {
            return
          }
        } else {
          // 勤務日ではない日に休み登録をした場合
          if(window.confirm(moment(this.daysSelected[i]).format('YYYY/MM/DD') + 'は勤務日ではありませんがよろしいでしょうか？')) {
            workingdays = false;
            // 登録する休みの情報を生成
            if (!this.setStatusList(statusList, this.daysSelected[i], workingdays, i)) {
              return
            }
          } else {
            // キャンセルボタン押下時は何もしない
            return;
          }
        }
      }

    // 画面表示が「曜日指定」の場合
    } else if (this.displaySpecifyDate == 'weekday') {
      
      let startDay = moment(this.start);
      let endDay = moment(this.end);
      let days = endDay.diff(startDay, 'days');
      let selected = [];

      for (let i = 0; i <= days; i++) {
        let date = moment(this.start).add(i, 'days').format('YYYY-MM-DD');
        let weekNumber = new Date(date).getDay();

        // 範囲指定の中に選択した曜日がない場合は処理をスキップ
        for (let j = 0; j < this.week.length; j++) {
          if (this.week[j] == weekNumber) {
            selected.push(date);
          }
        }
      }

      if (selected.length == 0) {
        alert('該当する日がありません。');
        return;
      }

      // if(left < selected.length) {
      //   alert('有休残数が足りません');
      //   return;
      // }

      for (let i = 0; i < selected.length; i++) {
        let dateS = moment(selected[i]).format('YYYY-MM-DD');
        let workingHours = await this.getWorkingHours2(dateS);

        // 勤務日に休み登録をした場合
        if(workingHours != '0.00') {
          workingdays = true;
          if (!this.setStatusList(statusList, dateS, workingdays, i)) {
            return
          }
        } else {
          this.phTime = false;
          // 勤務日ではない日に休み登録をした場合
          if(window.confirm(moment(selected[i]).format('YYYY/MM/DD') + 'は勤務日ではありませんがよろしいでしょうか？')) {
            workingdays = false;
            if (!this.setStatusList(statusList, dateS, workingdays, i)) {
              return
            }
          } else {
            // キャンセルボタン押下時は何もしない
            return;
          }
        }
      }
    }

    // tbl_operation_noteに保存するデータ
    let daysArr = [];
    let isPaidHoliday = false
    for (let i = 0; i < statusList.length; i++) {
      daysArr.push(statusList[i].day);
      if (['1', '6'].includes(statusList[i].status)) {
        isPaidHoliday = true
      }
    }

    // 有休・指定有休の場合、登録可能か確認
    if (isPaidHoliday) {
      // 有休残数
      let left: number;
      // 「日」を消す
      left = Number(this.paidHolidayLeft.slice( 0, -1 ));
      // 有休残数が少ない場合
      if (left < daysArr.length) {
        for (let idx = 0; idx < daysArr.length; idx++) {
          const d = daysArr[idx];
          // 同じ日が登録されていない
          if (!this.paidHolidayDays.includes(d)) {
            alert('有休残数が足りません\r\n' + d + 'は有休登録できません。');
            return;
          }
        }
      }
    }

    this.setOperationNote(daysArr)

    this.http.post(
      API_BASE_URL + '/api/v1/status?sendmail=yes&saveHoliday=' + true
      + '&name=' + this.data['data']['name']
      + '&nameSite=' + this.data['data']['nameSite']
      + '&show=' + this.data['data']['show']
      + '&staffDailyCleaning=' + this.data['data']['staffDailyCleaning'],
      statusList,
      httpOptions
    ).subscribe(res => {
      this.chkOperationNote(this.operationNote)
      // tbl_operation_noteに保存
      this.http.post(
        API_BASE_URL + '/api/v1/operationNote/save?days=' + daysArr, this.operationNote, httpOptions
      ).subscribe(res => {
        this.dialogRef.close();
      },error => {
        if (error.error.status == 801) {
        alert(error.error.message);
      } else {
        alert("認証に失敗しました");
        this.dialogRef.close();
      }
        return;
      });
    },error => {
      if (error.error.status == 801) {
      alert(error.error.message);
    } else {
      alert("認証に失敗しました");
      this.dialogRef.close();
    }
      return;
    });
  }

  /**
   * 保存するデータを生成
   * @param statusList 保存データ
   * @param date 日付
   * @param workingdays 勤務日の判定
   * @param i 番号
   */
  setStatusList(statusList: any, date: string, workingdays: boolean, i:number) {
    statusList.push({
      sequenceNumber: this.data['data'].sequenceNumber,
      cdSite: this.data['data'].cdSite,
      staffDailyCleaning: this.data['data'].staffDailyCleaning,
      day: date,
      status: String(this.type),
      note: this.note,
      insertedDate: moment().format('YYYY-MM-DD')
    });
    // 有休登録の場合は、有休時間を保存
    if (this.type == 1 || this.type == 6) {
      // 勤務日の場合
      if (workingdays) {
        if (!this.paidHolidayUsageTimeArray[this.phTimeCount]) {
          alert('エラーが発生しました。有休時間が取得できません。')
          return false
        }
        statusList[i].paidHolidayUsageTime = this.paidHolidayUsageTimeArray[this.phTimeCount];
        this.phTimeCount ++;
      } else {
        let time = this.paidHolidayUsageTimeInput;
        if (!this.paidHolidayUsageTimeInput || this.paidHolidayUsageTimeInput == undefined) {
          time =  this.paidHolidayHoursInput + '.' + this.paidHolidayMinutesInput
        }
        if (!time || time == '0.00') {
          alert('有休時間を入力してください。')
          return false
        }
        statusList[i].paidHolidayUsageTime = time;
      }
    }
    return true
  }

  /**
   * 現場に関係する清掃員情報を取得「シフト表示」「代勤登録者・更新日」
   */
  getData(day: string) {
    this.nowLoading = true;

    this.http.get(API_BASE_URL + '/api/v1/sites/operationDialog?cdSite=' + this.data['data']['cdSite']
      + '&cdContract=' + this.data['data']['cdContract']
      + '&cdStaff=' + this.data['data']['staffDailyCleaning']
      + '&day=' + day
      + '&sequenceNumber=' + this.data['data']['sequenceNumber'],
      {headers: this.headers})
      .subscribe((res) => {
        this.holidayDialogData = res;
        if (res[0] && res[0].createdDate) {
          this.operationCreatedDate = res[0].createdDate;
          this.operationCreatedUser = res[0].createdUser;
        }
        this.nowLoading = false;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.nowLoading = false;
      }
    );
  }

  /**
   * 休みの最終更新情報を取得する
   * @param data 稼働データ
   * @param target 取得するのは稼働データの何番目の情報か
   */
  private getStatusCreatedInfo(data, target) {
    let targetDay = target - 1;
    let targetStatus = data['statusArray'][targetDay] || {};
    let createdUser = targetStatus['insertedUser'];
    let createdDate = targetStatus['insertedDate'];
    return {'createdUser': createdUser, 'createdDate': createdDate};
  }

  /**
   * 有休時間ドロップダウン作成
   * @param n 最大値
   * @returns　連番配列
   */
  generateNums(n: number): number[] {
    n++;
    return Array.from({length: n}, (x, i) => i);
  }

  /**
   * 有休時間に変換
   */
  inputTime() {
    this.paidHolidayUsageTimeInput = this.paidHolidayHoursInput + '.' + this.paidHolidayMinutesInput;
  }

  /**
   * 実働時間 設定
   * 通常勤務日であれば実労働時間を有休時間にセット
   * @param day 日付
   */
  getWorkingHours(day: string, cb) {

    // 該当日の実働時間を取得
    this.http.get(API_BASE_URL + '/api/v1/instructEmployment/workingHours?&staffDailyCleaning=' + this.data['data']['staffDailyCleaning']
      + '&cdSite=' + this.data['data']['cdSite']
      + '&cdContract=' + this.data['data']['cdContract']
      + '&sequenceNumber=' + this.data['data'].sequenceNumber
      + '&day=' + day,
      {headers: this.headers})
      .subscribe((res) => {
        if(cb){
          cb(res);
        }
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      }
    );
  }

  /**
   * 実働時間 設定
   * 通常勤務日であれば実労働時間を有休時間にセット
   * 同期処理
   * @param day 日付
   */
  async getWorkingHours2(day: string) {

    var ret;
    // 該当日の実働時間を取得
    await this.http.get(API_BASE_URL + '/api/v1/instructEmployment/workingHours?&staffDailyCleaning=' + this.data['data']['staffDailyCleaning']
      + '&cdSite=' + this.data['data']['cdSite']
      + '&cdContract=' + this.data['data']['cdContract']
      + '&sequenceNumber=' + this.data['data'].sequenceNumber
      + '&day=' + day,
      {headers: this.headers})
      .toPromise().then((res: any) => {
        ret = res
      })
      .catch((error: any) => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });
    
    return ret;
  }

  /**
   * 有休時間を時と分に分けてバインド
   * @param time 時間
   */
  setPaidHolidayTime(time: String, workingHours: any){
    var t = time.split('.');
    var hours;
    var minutes;

    if(t.length > 0) {
      hours = Number(t[0]);
      if(t.length >= 2) {
        minutes = t[1];
      } else {
        minutes = '00';
      }
    } else {
      hours = 0;
      minutes = '00';
    }

    // 勤務日の場合
    if (workingHours != '0.00' && workingHours != null && workingHours != '') {

      this.paidHolidayHours = hours;
      this.paidHolidayMinutes = minutes;

    // もともと休みの場合
    } else {
        this.paidHolidayHoursInput = hours;
        this.paidHolidayMinutesInput = minutes;
    }
  }

  /**
   * 勤務日ではない日が含まれている場合は有休時間を活性
   * @returns 
   */
  checkHolidayCount() {
    this.check = true;
    this.paidHolidayUsageTimeArray = [];
    // 有休の場合
    if (this.type == 1 || this.type == 6) {
      
      let startDay = moment(this.start);
      let endDay = moment(this.end);
      let daysDiff = endDay.diff(startDay, 'days');

      // 「連日」の場合
      if (this.displaySpecifyDate == 'consecutive') {
        // 1日以上範囲指定している場合
        if (daysDiff >= 0) {
          for (let i = 0; i <= daysDiff; i++) {
            this.checkWorkingDay(this.start, i);
          }
        }

      // 「複数日指定」の場合
      } else if (this.displaySpecifyDate == 'multiple') {
        let generatedDays = Array.from(this.daysSelected);
        let days = this.daysSelected.length;
        for (let i = 0; i < days; i++) {
          this.checkWorkingDay(this.daysSelected[i], i);
        }
        this.daysSelected = generatedDays;

      // 「曜日指定」の場合
      } else if (this.displaySpecifyDate == 'weekday') {
          for (let i = 0; i <= daysDiff; i++) {
            let date = moment(this.start).add(i, 'days').format('YYYY-MM-DD');
            let weekNumber = new Date(date).getDay();

            // 範囲指定の中に選択した曜日がない場合は処理をスキップ
            for (let j = 0; j < this.week.length; j++) {
              if (this.week[j] == weekNumber) {
                this.checkWorkingDay(this.start, i)
              }
            }
          }
      }
    }
  }

  /**
   * 勤務日の場合は、実働時間をセット、勤務日でない場合はアラートを出す
   * @param day 日付
   * @param i 番号
   * @returns 
   */
  checkWorkingDay(day: any, i: number) {
      // 「連日」「曜日指定」の場合
      if (this.displaySpecifyDate == 'consecutive' || this.displaySpecifyDate == 'weekday') {
        var date = moment(day).add(i, 'days').format('YYYY/MM/DD');

      // 「複数日指定」の場合
      } else if (this.displaySpecifyDate == 'multiple') {
        var date = moment(day).format('YYYY/MM/DD');
      }

      // 予定の実働時間を取得
      let dateFormat = moment(date).format('YYYY-MM-DD');
      this.getWorkingHours(dateFormat,(workingTime) => {
        // 勤務日でない日が含まれていれば時間入力を活性にする
        if (workingTime == '0.00') {
          this.phTime = false;
        } else {
          this.paidHolidayUsageTimeArray.push(workingTime);
          if (i == 0) {
            this.setPaidHolidayTime(String(workingTime), workingTime)
          }
        }
      });
  }

  /**
   * 画面表示を変更した際にする処理
   */
  changeDisplay() {
    this.checkHolidayCount();
  }

  /**
   * 曜日を変更した際にする処理
   */
  changeWeek() {
    this.checkHolidayCount();
  }

  /**
   * 種別を変更した際にする処理
   */
  changeType() {
    this.checkHolidayCount();
  }

  /**
   * 「複数日指定」のカレンダーマークをクリックした際にする処理
   * @param calendar 
   */
  openCalendar(calendar: any) {
    // カレンダーを更新
    calendar.updateTodaysDate();
  }

  /**
   * 有休残数を取得
   */
  getPaidHolidaysLeft() {
    this.paidHolidayLeft = '0日';
    this.http.get(API_BASE_URL + '/api/v1/paidHolidays/left?staffDailyCleaning=' + this.data['data']['staffDailyCleaning'],
      {headers: this.headers})
      .subscribe((PaidHolidays) => {
        // 有休残日数
        if (PaidHolidays['intRes'] && !isNaN(PaidHolidays['intRes'])) {
          this.paidHolidayLeft = PaidHolidays['intRes'] + '日';
        }
        // 有休登録日
        if (PaidHolidays['strRes']) {
          console.log(PaidHolidays['strRes'])
          this.paidHolidayDays = PaidHolidays['strRes'].replace('{', '').replace('}', '').split(',')
        }

      },
      error => {
        alert('認証に失敗しました');
        this.router.navigate(['/']);
      });
  }

  /**
   * 「複数日指定」選択した日に色を付ける
   * @param event 選択した日
   * @returns
   */
  isSelected = (event: any) => {
    const date = moment(event).format('YYYY-MM-DD');
    return this.daysSelected.find(x => x == date) ? 'selected' : null;
  }
  
  /**
   * 「複数日指定」選択した日を表示する
   * @param event 選択した日
   * @param calendar カレンダー
   */
  select(event: any, calendar: any) {
    let date = moment(event).format('YYYY-MM-DD');
    let dateFormat = moment(date).format('YYYY/MM/DD');
    const index = this.daysSelected.findIndex(x => x == date);
    if (index < 0) {
      this.daysSelected.push(date);
      this.daysArray.push(dateFormat);
      this.days = this.daysArray.join(", ");
      // 有休チェック
      this.checkHolidayCount();
    } else {
      // すでに選択済みの日をクリックした場合、配列から削除
      this.daysSelected.splice(index, 1);
      this.daysArray.splice(index, 1);
      this.days = this.daysArray.join(", ");
    }
    // 選択した日に色を付けるため、クリックするたびにカレンダーを更新
    calendar.updateTodaysDate();
  }

  /**
   * 開始日、終了日のクラス名設定
   */
  classForTime() {
    // 連日
    if (this.displaySpecifyDate == 'consecutive') {
      return 'time-consecutive';
    // 曜日指定
    } else if (this.displaySpecifyDate == 'weekday') {
      return 'time-weekday'
    }
  }

  /**
   * 画面表示ラジオボタンイベント
   */
  initialized() {
    // 日付けを初期化
    this.start = new Date();
    this.end = new Date();
    // 複数日選択
    this.daysSelected = [];
    // 複数日選択（表示用）
    this.days = '';
    // 有休時間入力　非活性
    this.phTime = true;
  }

  /**
   * tbl_operation_note用のデータを生成
   * @param days 
   */
  setOperationNote (days) {
    this.operationNote = [];
    for (let i = 0; i < days.length; i++) {
      let row = {
        // id: null,
        operationNoteId: {
          sequenceNumber: this.data['data'].sequenceNumber,
          staffDailyCleaning: this.data['data']['staffDailyCleaning'],
          cdSite: this.data['data'].cdSite,
          day: days[i],
        },
        cdContract: this.data['data'].cdContract,
        note: this.note
      }
      this.operationNote.push(row);
    }
  }

  chkOperationNote(onote) {
    if (onote == null || onote == undefined) {

      let raw_stacktrace = ''
      try{
        throw new Error('調査用のエラーになります。');
      }catch(e){
        raw_stacktrace = e.stack;
      }
      
      let httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          "X-LoginAccessKey": localStorage.getItem("accessKey")
        })
      };
  
      // ログ内容
      let log = {
        level: 2,
        userId: localStorage.getItem("uid"),
        serviceName: '休み登録ダイアログ',
        operation: '登録',
        logText: 'body="' + JSON.stringify(onote) + '" \r\n' + raw_stacktrace
      }
    
      // ログ登録
      this.http.post(
        API_BASE_URL + "/api/v1/log/save", log, httpOptions
      ).subscribe(res => {
      },error => {
      });
    }
  }
}
