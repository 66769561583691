import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { API_BASE_URL } from '../../environments/environment';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import * as XLSX from 'xlsx';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-building-setting',
  templateUrl: './building-setting.component.html',
  styleUrls: ['./building-setting.component.less']
})

export class BuildingSettingComponent extends BaseComponent implements OnInit, AfterViewInit {
  // 物件一覧テーブルのデータソース
  dataSource = new MatTableDataSource();

  // 物件一覧の全レコードの件数
  dataSourceLength = 0;

  // ローディング中 
  nowLoading = true;

  // 得意先名検索
  filterClient = "";

  // 物件名検索
  filterSite = "";

  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  @ViewChild('TABLE') table: ElementRef;

  // カラム名 物件一覧
  columnDefs = [
    'siteName',
    'clientName'
  ];

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private cdr: ChangeDetectorRef, private ngZone: NgZone) {
    super();
  }

  ngOnInit() {
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ページ読み込み後の初期化メソッド
   */
  ngAfterViewInit() {
    this.filterClient = 
      localStorage.getItem('filterClient') != null ? localStorage.getItem('filterClient') : "";
    this.filterSite = 
      localStorage.getItem('filterSite') != null ? localStorage.getItem('filterSite') : "";
    this.dataSource.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      // 物件一覧表示
        this.getData(this.paginator.pageIndex, this.paginator.pageSize);
    });
    this.getData(this.paginator.pageIndex, this.paginator.pageSize);
    this.cdr.detectChanges();
  }

  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getData(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * 物件一覧取得
   * @param index 開始インデックス
   * @param size  取得サイズ
   */
  getData(index: number, size: number) {
    this.nowLoading = true;
    localStorage.setItem('filterClient', this.filterClient);
    localStorage.setItem('filterSite', this.filterSite);

    this.http.get(
      API_BASE_URL + '/api/v1/sites/getSiteList?s=0&e=8000&index=' + index
        + "&size=" + size
        + "&filterClient=" + encodeURIComponent(this.filterClient)
        + "&filterSite=" + encodeURIComponent(this.filterSite)
      , {headers: this.headers}
      )
    .subscribe(res => {
      this.dataSource = new MatTableDataSource(res as []);
      // ページネーターに表示する全サイズを取得
      let data = res as any[];
      if (data != null && data.length > 0) {
        this.dataSourceLength = data[0].totalSize;
      } else {
        this.dataSourceLength = 0;
      }
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
  
  /**
  * セルクリック時のコールバック
  * @param event 選択された行オブジェクト
  */
  cellClicked(event) {
    this.router.navigate(['/buildingSetting/' + event.cdSite + '/' + event.cdClient]);
    } 
}