import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator, MatDialog } from '@angular/material';
import { RecruitDialogComponent } from './recruit-dialog/recruit-dialog.component';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-recruit',
  templateUrl: './recruit.component.html',
  styleUrls: ['./recruit.component.less']
})

export class RecruitComponent implements OnInit {

  // カラム名
  public columnDefs = [
    'nameSite',
    'client1',
    'type',
    'address',
    'cdContract',
    'contract',
    'helloWork',
    'period',
    'wage',
    'other',
    'status',
    'monthlyFee',
    'monthlyHours',
    'hourlyWage',
    'edit',
    'addition'
  ];

  // テーブルデータソース
  recruitList = new MatTableDataSource();

  // 募集状態トグル
  recruit = null;
  recruitValue = null;

  // 物件名絞込み
  nameSite = '';

  // 住所絞り込み
  address = '';

  // 欠員状態絞り込み
  status = '全て';

  // 欠員状態絞り込みリスト
  public statusChoices = [
    {value: '全て'},
    {value: '清掃員なし'},
    {value: '清掃員いるが時間不足'},
    {value: '清掃員いる・不足なし'},
  ];

  // ハローワーク以外の募集媒体
  medium = '';

  // ローディング中：true　終了：false
  nowLoading = true;

  // ダウンロード中：true　終了：false
  downloading = false;

  // 出力データ
  exportData = [];

  // データ数
  dataSourceLength = 0;

  // ダイアログopen
  isOpenDialog = false;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog, private cdr: ChangeDetectorRef, private ngZone: NgZone) { }

  ngOnInit() {
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  ngAfterViewInit() {
    this.medium = localStorage.getItem('recruit_medium') != null ? localStorage.getItem('recruit_medium') : '';
    this.address = localStorage.getItem('recruit_address') != null ? localStorage.getItem('recruit_address') : '';
    this.nameSite = localStorage.getItem('recruit_name_site') != null ? localStorage.getItem('recruit_name_site') : '';
    this.status = localStorage.getItem('recruit_status') != null ? localStorage.getItem('recruit_status') : '全て';
    this.recruit = localStorage.getItem('recruit_recruit');
    this.recruitValue = localStorage.getItem('recruit_recruit_value');

    this.paginator.pageIndex = 0;
    this.recruitList.paginator = this.paginator;
    this.getRecruitData(0, 50);

    this.dialog.afterAllClosed.subscribe(res => {
      if (this.isOpenDialog) {
        this.getRecruitData(this.paginator.pageIndex, this.paginator.pageSize);
        this.isOpenDialog = false
      }
    })

    this.paginator.page.subscribe(() => {
        this.getRecruitData(this.paginator.pageIndex, this.paginator.pageSize); 
    });
    
    this.cdr.detectChanges();
  }

  /**
   * 欠員物件データ取得
   */
  getRecruitData(index: number, size: number) {
    this.paginator.pageIndex = index;
    this.paginator.pageSize = size;
    localStorage.setItem('recruit_medium', this.medium);
    localStorage.setItem('recruit_address', this.address);
    localStorage.setItem('recruit_name_site', this.nameSite);
    localStorage.setItem('recruit_status', this.status);
    localStorage.setItem('recruit_recruit', this.recruit);
    localStorage.setItem('recruit_recruit_value', this.recruitValue);

    this.nowLoading = true;

    let year = moment().year();
    let month = moment().month();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/recruit?s=0&e=8000&index=' + index
      + '&size=' + size
      + '&year=' + year
      + '&medium=' + encodeURIComponent(this.medium)
      + '&nameSite=' + encodeURIComponent(this.nameSite)
      + '&address=' + encodeURIComponent(this.address)
      + '&recruit=' + this.recruit
      + '&status=' + this.status,
      {headers: this.headers}
    )
    .subscribe(res => {

      let data = res as any[];
      // console.log(data)
      if (data.length > 0) {
        this.dataSourceLength = res[0].totalSize;
        // 週の状態を配列に変更
        data.forEach((d) => {
          if (d.week) {
            d.weekArr = (d.week.replace('{', '').replace('}', '').replace(/"/g, '')).split(',')
          }
        })
        this.recruitList = new MatTableDataSource(res as any[]);
      } else {
        this.dataSourceLength = 0;
        this.recruitList = new MatTableDataSource(data);
      }
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.nowLoading = false;
    });
  }

  /**
   * 清掃員状況　表示
   * @param status 1：決定、2：未決定
   * @returns 
   */
  getStatus(status: number) {
    if (status == 1) {
      return '決定';
    } else {
      return '未決定';
    }
  }

  /**
   * セル色
   * @param endDateRecruit 募集終了日
   * @param status 状態
   * @returns 
   */
  cellColor(endDateRecruit: Date, status: number) {
    if (endDateRecruit && status == 2) {
      
      let end = moment(endDateRecruit);
      let today = moment();

      let diff = end.diff(today, 'days');
      let before = today.isBefore(end);

      if (diff < 8 && diff >= 0) {
        return 'yellow-row';
      } else if (!before) {
        return 'red-row';
      }
    }
  }

  /**
   * 募集状況トグル
   * @param value 選択した値
   */
  changeRecruitToggle(value) {
    // 2度クリックされたらトグル外す
    if (this.recruitValue == value) {
      this.recruit = null;
      this.recruitValue = null;
    } else {
      this.recruitValue = value;
    }
    this.paginator.pageIndex = 0;
    this.paginator.pageSize = 50;
    this.getRecruitData(0, 50);
  }

  /**
   * ダイアログ表示
   * @param event 祝日データ
   */
  openDialog(event: any, registerFlag: boolean) {
    this.dialog.open(RecruitDialogComponent, {
      data: {
        event,
        registerFlag
      }
    });
    this.isOpenDialog = true
  }

  /**
   * ダウンロードイベント
   */
  downloadBtn() {
    this.downloading = true;
    this.exportData = [];

    let year = moment().year();
    let month = moment().month();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/recruit/allData?year=' + year
      + '&medium=' + encodeURIComponent(this.medium)
      + '&nameSite=' + encodeURIComponent(this.nameSite)
      + '&address=' + encodeURIComponent(this.address)
      + '&recruit=' + this.recruit
      + '&status=' + this.status,
      {headers: this.headers}
    )
    .subscribe(res => {

      let data = res as any[];
      if (data.length > 0) {

        data.forEach((d) => {
          // 契約終了日がある場合
          if (d.endDateContract) {
            // 日にちを整形
            d.endDateContract = moment(d.endDateContract).format('YYYY/MM/DD')
            // 作業日を空にする
            d.endDateWork = ''
          // 作業終了日がある場合
          } else if (d.endDateWork) {
            d.endDateWork = moment(d.endDateWork).format('YYYY/MM/DD')
          }

          // 募集期間
          d.start = ''
          if (d.startDateRecruit) {
            d.start = moment(d.startDateRecruit).format('YYYY/MM/DD');
          }
          d.end = ''
          if (d.endDateRecruit) {
            d.end = moment(d.endDateRecruit).format('YYYY/MM/DD');
          }
          d.weekStr = ''
          if (d.week) {
            d.weekStr = d.week.replace('{', '').replace('}', '').replace(/"/g, '')
          }

          // 清掃員の決定有無
          d.staff = this.getStatus(d.status)

          this.exportData.push({
            '物件名': d.nameSite,
            '契約終了': d.endDateContract,
            '清掃員終了': d.endDateWork,
            '得意先': d.client1,
            '支店名': d.client2,
            '欠員状態': d.type,
            '欠員曜日': d.weekStr,
            '郵便番号': d.zip,
            '住所': d.address,
            '物件コード': d.cdSite,
            '契約コード': d.cdContract,
            '月曜': d.mon,
            '火曜': d.tue,
            '水曜': d.wed,
            '木曜': d.thu,
            '金曜': d.fri,
            '土曜': d.sat,
            '日曜': d.sun,
            '募集媒体': d.medium,
            'ハローワーク管理番号': d.employmentSecurityOffice,
            '募集開始': d.start,
            '募集終了': d.end,
            '時給': d.wage,
            '最寄り駅': d.station,
            '徒歩(分)': d.foot,
            '清掃員': d.staff,
            '月額': this.amountFormat(d['monthlyFee']),
            '月時間': d['monthlyHours'],
            '時給単価': this.amountFormat(d['hourlyWage']),
          })
        })
        
        this.downloadCsv();
      } else {
        alert('出力するデータがありません。')
      }
      this.downloading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.downloading = false;
    });
  }

  /**
   * エクスポート実行
   */
  downloadCsv() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    // ファイル名設定
    let fileName = '欠員募集' + moment().format('YYYYMMDD') + '.xlsx';
    XLSX.writeFile(wb, fileName);
  }
  
  /**
   * 金額を3桁区切りにする
   * @param val 値
   * @returns 
   */
  amountFormat(val) {
    if (!val) {
      return null
    } else {
      return Number(val).toLocaleString()
    }
  }
}
