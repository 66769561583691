import { Component, OnInit, NgZone } from '@angular/core';
import { API_BASE_URL } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-area-master',
  templateUrl: './area-master.component.html',
  styleUrls: ['./area-master.component.less']
})
export class AreaMasterComponent implements OnInit {

  areaList = [];
  areaRelatedList = [];

  selectedArea;
  selectedAreaRelated;

  updateArea;
  updateAreaRelated;

  areaEditMode = false;
  areaRelatedEditMode = false;
  
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private dialog: MatDialog, private ngZone: NgZone) { }

  ngOnInit() {
    this.updateArea = {};
    this.updateAreaRelated = {};
    this.refreshAreas();
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }

  refreshAreas() {
    this.http.get(API_BASE_URL + "/api/v1/area", {
      headers: this.headers
    }).subscribe(res=> {
      let currentAreaId = this.updateArea.areaId;
      console.info(res);
      this.areaList = [];
      this.areaList = res as any[];

      // 現在選択されているエリアを選択しなおす
      this.areaList.forEach(area => {
        if (area.areaId == currentAreaId) {
          this.areaSelected(area);
          return;
        }
      })

    }, error => {
      alert("セッションが切れています");
      this.router.navigate(['/']);
    })
  }

  areaSelected(area) {
    console.log(area);
    this.resetSelection(this.areaList);

    area.selected = true;
    this.areaRelatedList = area.areaRelateds;
    this.selectedArea = area;
    this.updateArea = JSON.parse(JSON.stringify(area));

    this.updateAreaRelated = {};
    this.selectedAreaRelated = null;
    this.resetSelection(this.areaRelatedList);
  }

  onNewAreaButton() {
    this.areaEditMode = true;
    this.updateArea = {'areaName': '', 'areaId': -1};
  }

  onUpdateAreaButton() {
    if (!this.selectedArea) {
      window.alert('エリアを選択してください。');
      return;
    }
    this.areaEditMode = true;
  }

  saveArea() {
    this.areaEditMode = false;
    this.http.post(API_BASE_URL + '/api/v1/area',
      this.updateArea, {headers: this.headers})
      .subscribe(res=> {
        console.info(res);
        this.refreshAreas();
      }, error => {
        alert("セッションが切れています");
        this.router.navigate(['/']);
      })
  }

  cancelArea() {
    this.areaEditMode = false;
  }

  deleteArea() {
    if (!this.selectedArea) {
      window.alert('エリアを選択してください。');
      return;
    }

    if (window.confirm('このエリアを削除しますか?')) {
      this.updateArea['deleteDate'] = new Date();
      this.http.post(API_BASE_URL + '/api/v1/area',
        this.updateArea, {headers: this.headers})
        .subscribe(res=> {
          console.info(res);
          this.refreshAreas();
        }, error => {
          alert("セッションが切れています");
          this.router.navigate(['/']);
        })
    }
  }

  areaRelatedSelected(areaRelated) {
    console.log(areaRelated);
    this.resetSelection(this.areaRelatedList);
    areaRelated.selected = true;
    this.selectedAreaRelated = areaRelated;
    this.updateAreaRelated = JSON.parse(JSON.stringify(areaRelated));
  }

  onNewAreaRelatedButton() {
    this.areaRelatedEditMode = true;
    this.updateAreaRelated = {'areaId': this.updateArea.areaId, 'areaRelatedName': '', 'areaRelatedId': -1};
  }

  onUpdateAreaRelatedButton() {
    if (!this.selectedAreaRelated) {
      window.alert('エリアを選択してください。');
      return;
    }
    this.areaRelatedEditMode = true;
  }

  saveAreaRelated() {
    this.areaRelatedEditMode = false;
    this.http.post(API_BASE_URL + '/api/v1/arearelated',
      this.updateAreaRelated, {headers: this.headers})
      .subscribe(res=> {
        console.info(res);
        this.refreshAreas();
      }, error => {
        alert("セッションが切れています");
        this.router.navigate(['/']);
      })
  }

  deleteAreaRelated() {
    if (!this.selectedAreaRelated) {
      window.alert('エリアを選択してください。');
      return;
    }
    if (window.confirm('この関連名を削除しますか?')) {
      this.updateAreaRelated['deleteDate'] = new Date();
      this.http.post(API_BASE_URL + '/api/v1/arearelated',
        this.updateAreaRelated, {headers: this.headers})
        .subscribe(res=> {
          console.info(res);
          this.refreshAreas();
        }, error => {
          alert("セッションが切れています");
          this.router.navigate(['/']);
        })
    }
  }

  cancelAreaRelated() {
    this.areaRelatedEditMode = false;
  }

  private resetSelection(list) {
    list.forEach(item => {
      item.selected = false;
    })
  }
}
