import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';

// 一覧から引き継いだデータ
export interface DialogData {
  data
}

@Component({
  selector: 'app-recruit-dialog',
  templateUrl: './recruit-dialog.component.html',
  styleUrls: ['./recruit-dialog.component.less']
})

export class RecruitDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 画面タイトル
  title = '編集';

  // 日付
  start = new FormControl();
  end = new FormControl();

  // 対象データ
  target: any;

  // httpヘッダー
  public httpOptions;

  constructor(private http: HttpClient, private router: Router, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<RecruitDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    // 「編集」の場合
    if (this.data['registerFlag']) {
      this.target = {
        id: this.data['event']['id'],
        nameSite: this.data['event']['nameSite'],
        client: this.data['event']['client1'] + '　' + this.data['event']['client2'],
        cdSite: this.data['event']['cdSite'],
        cdContract: this.data['event']['cdContract'],
        employmentSecurityOffice: this.data['event']['employmentSecurityOffice'],
        medium: this.data['event']['medium'],
        wage: this.data['event']['wage'],
        foot: this.data['event']['foot'],
        station: this.data['event']['station'],
        hourlyWage: this.data['event']['hourlyWage']
      }

    // 募集開始日セット
    this.start = new FormControl(new Date(moment(this.data['event']['startDateRecruit']).format('YYYY/MM/DD')));
    // 募集終了日セット
    this.end = new FormControl(new Date(moment(this.data['event']['endDateRecruit']).format('YYYY/MM/DD')));

    // 「追加」の場合
    } else {
      this.target = {
        nameSite: this.data['event']['nameSite'],
        client: this.data['event']['client1'] + '　' + this.data['event']['client2'],
        cdSite: this.data['event']['cdSite'],
        cdContract: this.data['event']['cdContract'],
        hourlyWage: this.data['event']['hourlyWage']
      }
      this.title = '追加';
    }

    // 清掃員状況　初期値：未決定
    if (!this.data['event']['status']) {
      this.target.status = '2';
    } else {
      this.target.status = this.data['event']['status'].toString()
    }

    // 募集媒体 初期値
    if (!this.target.id) {
      this.target.medium = 'ハローワーク'
    }
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 募集開始日
   * @param event 
   */
  changeStartDate(event: MatDatepickerInputEvent<Date>) {
    this.start = new FormControl(event.value);
    // 開始日が入ったら、自動で終了日表示
    if (event.value) {
      let day = moment(event.value).add(2, 'M').format('YYYY-MM-DD')
      // 当月1日から3ヶ月マイナス1日（2か月目の月末）
      day =  moment(day).endOf('month').format("YYYY-MM-DD")
      this.end = new FormControl(day);
    }
  }

  /**
   * 募集終了日
   * @param event 
   */
  changeEndDate(event: MatDatepickerInputEvent<Date>) {
    this.end = new FormControl(event.value);
  }

  /**
   * 保存時チェック
   * @returns 
   */
  validate() {
    const t = this.target
    // 開始日
    if (!this.start.value || this.start.value == 'Invalid Date') {
      alert('募集開始日付を選択してください。');
      return false;
    }
    // 終了日
    if (!this.end.value || this.end.value == 'Invalid Date') {
      alert('募集終了日付を選択してください。');
      return false;
    }

    if (!t.employmentSecurityOffice && t.medium == 'ハローワーク') {
      if (!window.confirm('ハローワーク管理番号が未入力ですがよろしいでしょうか？')) {
        return false;
      }
    }
    if (!t.medium) {
      if (!window.confirm('募集媒体が未入力ですがよろしいでしょうか？')) {
        return false;
      }
    }
    return true;
  }

  /**
   * 登録
   */
  register() {
    if (!this.validate()) {
      return
    }

    // 募集開始日
    this.target.startDateRecruit = moment(this.start.value).format('YYYY-MM-DD');
    // 募集終了日
    this.target.endDateRecruit = moment(this.end.value).format('YYYY-MM-DD');
    // 更新者情報を追加
    this.target.createdUser = localStorage.getItem("uid");

    this.http.post(
      API_BASE_URL + "/api/v1/recruit/save?nameSite=" + this.target.nameSite, this.target, this.httpOptions
    ).subscribe(res => {
      this.dialogRef.close();
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.dialogRef.close();
    });
  }

  /**
   * キャンセル
   */
  cancel() {
    this.dialogRef.close();
  }

  /**
   * 削除
   */
  delete() {

    if (window.confirm("削除してよろしいでしょうか？")) {

      // 一度も登録していないデータを削除しようとするときは、ダイアログを閉じるだけ
      if (!this.target.id) {
        this.dialogRef.close();
        return;
      }

      this.http.post(
        API_BASE_URL + "/api/v1/recruit/remove?nameSite=" + this.target.nameSite, this.target.id, this.httpOptions
      )
      .subscribe(res => {
        this.dialogRef.close();
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.dialogRef.close();
      });
    }
  }
  
  /**
   * 金額を3桁区切りにする
   * @param val 値
   * @returns 
   */
  amountFormat(val) {
    if (!val) {
      return null
    } else {
      return Number(val).toLocaleString()
    }
  }
}