import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { FormControl } from '@angular/forms';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import { MatDatepicker, MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MatTableDataSource, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MAT_DIALOG_DATA } from '@angular/material';
import { Moment } from 'moment';
import * as moment from 'moment';

export interface DialogData {
  data: { staffDailyCleaning: string; sequenceNumber: any; cdSite: any; nameStaff: string; }
}

// 「通常勤務」
export interface Regular {
  dayAndWeek: string;
  workingTime: string;
}

// 「通常勤務」表示用
export interface RegularTable {
  dayAndWeek1: string;
  workingTime1: string;
  dayAndWeek2: string;
  workingTime2: string;
}

// 「代理勤務」表示用
export interface WorkInstead {
  dayAndWeek: string;
  nameSite: string;
  workingTimeOp: string;
  workingHours: number;
}

// 「合計」表示用
export interface Summary {
  workingType: string;
  wage: string;
  transExp: string;
  workingDays: number;
  paidHolidays: number;
  commuteTime: number;
  workingHours: number;
}

export const DATE_FORMATS = {
  parse: {
    dateInput: "YYYY年MM月",
  },
  display: {
    dateInput: "YYYY年MM月",
    monthYearLabel: "YYYY MMM",
    dateA11yLabel: "LL",
    monthYearA11yLabel: "YYYY MMMM",
  },
}

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.less'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})

export class ConfirmationDialogComponent implements OnInit {
  //活性:true 非活性:false
  disabled = false;

  // 「通常勤務」カラム名
  regularColumnDefs = [
    'dayAndWeek1',
    'workingTime1',
    'dayAndWeek2',
    'workingTime2'
  ];

  // 「代理勤務」カラム名
  insteadColumnDefs = [
    'dayAndWeek',
    'nameSite',
    'workingTime',
    'workingHours'
  ];

  // 「合計」カラム名
  summaryColumnDefs = [
    'workingType',
    'wageSum',
    'transExpSum',
    'workingDays',
    'paidHolidays',
    'commuteTime',
    'workingHoursSum'
  ];

  // 「通常勤務」テーブルデータソース
  regular = new MatTableDataSource();

  // 「代理勤務」テーブルデータソース
  workInstead = new MatTableDataSource();

  // 「合計」テーブルデータソース
  summary = new MatTableDataSource();

  regularSum: any;

  WorkInsteadSum: any;

  // 画面表示切替ドロップダウンリスト
  screenDisplayType = [
    {key:0, value: "通常勤務"},
    {key:1, value: "代理勤務"},
    {key:3, value: "スポット作業"},
    {key:2, value: "合計"}
  ];
  
  // 選択中の画面表示
  screenDisplay = this.screenDisplayType[0].key;

  // 物件名ドロップダウンリスト
  instructEmployment = [];

  // 選択中の物件に関するデータ
  selectedData = this.instructEmployment[0];

  // 物件コード
  selectedCdSite = this.data.data.cdSite;

  // シーケンス
  selectedSequenceNumber = this.data.data.sequenceNumber;

  //「表示年月」初期値_現在の日付から1ヵ月前の日付を取得
  selectedMonth = new FormControl(moment().subtract(1, 'months'));

  // 月初日
  monthFirstDay = moment(this.selectedMonth.value).format("YYYY-MM-01");

  // 通常勤務 フッター
  days: number;

  paidHolidays: number;

  hours: number;

  // 代理勤務 フッター
  workInsteadHours: number;

  // ローディング
  nowLoading = true;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  blob: Blob;

  constructor(private http: HttpClient, private router: Router, @Inject(MAT_DIALOG_DATA) private data: DialogData, private ngZone: NgZone) { }

  ngOnInit() {
    this.getData(0);
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 画面表示内容
   * @param screenDisplay 0:通常勤務、 1:代理勤務、3:スポット業務、 2:合計
   */
  applyFilter(screenDisplay: number) {
    // 「通常勤務」画面
    if (screenDisplay == 0) {
      this.disabled = false;
      this.getRegular();

      // 「代理勤務」もしくは「スポット作業」画面
    } else if (screenDisplay == 1 || screenDisplay == 3) {
      this.disabled = true;
      this.getWorkInstead(screenDisplay);

      // 「合計」画面
    } else if (screenDisplay == 2) {
      this.disabled = true;
      this.getSum();
    }
  }

  /**
   * カレンダーの年選択時
   * @param normalizedYear 選択した年
   */
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.selectedMonth.value;
    ctrlValue.year(normalizedYear.year());
    this.selectedMonth.setValue(ctrlValue);
  }

  /**
   * カレンダーの月選択時
   * @param normalizedMonth 選択した月
   * @param datepicker
   * @param screenDisplay 画面表示切替
   */
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>, screenDisplay: number) {
    this.selectedMonth.setValue(normalizedMonth);
    this.monthFirstDay = moment(this.selectedMonth.value).format("YYYY-MM-01");
    this.getData(screenDisplay);
    datepicker.close();
  }

  /**
   * 年月表示_手入力した場合
   * @param event 入力した年月
   * @param screenDisplay 画面表示切替
   */
  handlerManual(normalizedMonth: MatDatepickerInputEvent<Moment>, screenDisplay: number) {
    const date = moment(normalizedMonth.value).format("YYYY-MM-01");
    this.selectedMonth.setValue(date);
    this.monthFirstDay = date;
    this.getData(screenDisplay);
  }

  /**
   * 表示する情報を取得
   * @param screenDisplay 表示画面の種類
   */
  getData(screenDisplay) {
    this.clearRegular();
    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/regularSite?staffDailyCleaning='+ this.data.data.staffDailyCleaning + "&date=" + this.monthFirstDay,
      {headers: this.headers}
    )
    .subscribe(res => {
      let result = res as any;
      this.instructEmployment = [];
      this.selectedData = null;

      for(var i = 0; i < result.length; i++){
        if(result[i] != "" && result[i] != null){
          this.instructEmployment[i] = [
            // 0:物件コード
            result[i].cdSite,
            // 1:物件名
            result[i].nameSite,
            // 2:シーケンス
            result[i].sequenceNumber,
            // 3:作業開始日
            result[i].startDateWork,
            // 4:作業終了日
            result[i].endDateWork
          ];
        }

        // 画面表示時は親画面の物件を表示、それ以降（年月を変更した場合）は直前まで選択していた物件を変えずに表示
        if(result[i].cdSite == this.selectedCdSite && result[i].sequenceNumber == this.selectedSequenceNumber){
          this.selectedData = this.instructEmployment[i];
        }
      }  
      // 表示年月を変更し、選択していた物件が稼働期間外ならば、稼働中の物件（ドロップダウン内の1つ目の物件）に切り替える
      if (this.selectedData == null && this.instructEmployment.length > 0) {
        this.selectedData = this.instructEmployment[0];
      }

      // 親画面でクリックした物件がダミー物件（物件コード：000000000）の場合は、稼働中の物件（ドロップダウン内の1つ目の物件）に切り替える
      if (this.instructEmployment.length > 0 && this.selectedCdSite == '000000000' && screenDisplay == 0) {
        this.selectedCdSite = this.instructEmployment[0][0];
        this.selectedSequenceNumber = this.instructEmployment[0][2];
      }

      this.applyFilter(screenDisplay);
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
    
  /**
   * 「通常勤務」表 データ取得
   */
  getRegular() {
    this.nowLoading = true;
    this.clearRegular();
    
    if (!this.selectedData) {
      this.nowLoading = false;
      return;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/regularList?date=' + this.monthFirstDay
        + "&staffDailyCleaning=" + this.data.data.staffDailyCleaning
        + "&cdSite=" + this.selectedCdSite
        + "&sequenceNumber=" + this.selectedSequenceNumber,
      {headers: this.headers}
    )
    .subscribe(res => {
      let regulars = res as Regular[];
      let tabledata:RegularTable[] = [];
      let idx = 0;
      this.days = res[0].daysRegular;
      this.paidHolidays = res[0].daysPaidHoliday;
      this.hours = res[0].hoursRegular;

      // 1～15日の横に16～末日を表示させるようにする
      regulars.forEach( day => {
        if (idx < 15) {
          tabledata.push({
            dayAndWeek1: day.dayAndWeek
          , workingTime1: day.workingTime
          , dayAndWeek2: undefined
          , workingTime2: undefined
          });
        } else if (idx < 30) {
          tabledata[idx - 15].dayAndWeek2 = day.dayAndWeek
          tabledata[idx - 15].workingTime2 = day.workingTime
        } else {
          tabledata.push({
            dayAndWeek1: undefined
          , workingTime1: undefined
          , dayAndWeek2: day.dayAndWeek
          , workingTime2: day.workingTime
          });
        }
        idx ++;
      });

      this.regular = new MatTableDataSource(tabledata);
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 「代理勤務」もしくは「スポット作業」表 データ取得
   */
  getWorkInstead(screenDisplay) {
    this.nowLoading = true;
    let status = '●：代勤OK';
    if (screenDisplay == 3) {
      status = 'スポット作業';
    }
    this.http.get(
      API_BASE_URL + '/api/v1/operations/workInsteadList?staffDailyCleaning='+ this.data.data.staffDailyCleaning + "&date=" + this.monthFirstDay + "&status=" + status,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.workInstead = new MatTableDataSource(res as WorkInstead[]);
      this.workInsteadHours = res[0].workingHoursOp
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
    
  /**
   * 「合計」表 データ取得
   */
  getSum() {
    this.nowLoading = true;
    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/summary?date=' + this.monthFirstDay + "&staffDailyCleaning="+ this.data.data.staffDailyCleaning,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.summary = new MatTableDataSource(res as Summary[]);
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * ダウンロード
   */
  downloadXlsx() {
    
    var today = moment().format("YYYY-MM-DD");
    var day = moment(this.monthFirstDay);

    // 未来の「表示年月」を選択した場合は、アラートを出す
    if(day.isAfter(today)) {
      alert("未来の「表示年月」を選択しているため実績データがありません");
      return;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/download?staffDailyCleaning='+ this.data.data.staffDailyCleaning
        + "&cdSite=" + this.selectedCdSite
        + "&sequenceNumber=" + this.selectedSequenceNumber
        + "&date=" + this.monthFirstDay,
      {headers: this.headers, responseType : 'blob' as 'json'}
    )
    .subscribe(res => {
      let report = res as any;

      this.blob = new Blob([report], {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'});
      var downloadURL = window.URL.createObjectURL(report);
      var link = document.createElement('a');
      link.href = downloadURL;

      // ダウンロードしたファイル名
      var staffName = this.data.data.nameStaff.replace(/\s/g, "");
      link.download = '業務報告書_' + staffName + moment(day).format('YYYY年MM月');

      link.click();
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 物件ドロップダウン選択イベント
   * @param screenDisplay 画面表示 0:通常勤務、1:代理勤務、2:合計
   */
  onChangeSite(screenDisplay: number) {

    if (this.selectedData != null && this.selectedData.length > 0) {
      this.selectedCdSite = this.selectedData[0];
      this.selectedSequenceNumber = this.selectedData[2];
    }
    this.applyFilter(screenDisplay);
  }

  /**
   * 通常勤務　表示データクリア
   */
  clearRegular() {
    this.regular = new MatTableDataSource([0]);
    this.days = 0;
    this.paidHolidays = 0;
    this.hours = 0;
    this.nowLoading = false;
  }
}


