import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import _ from 'lodash'

export interface DialogData {
  any: any
}

@Component({
  selector: 'app-sms-dialog',
  templateUrl: './sms.component.html',
  styleUrls: ['./sms.component.less']
})
export class SmsDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 物件名
  siteName = '';
  // メールアドレス
  mobile = [{ name: '', mobile: '' }];
  // 件名
  title = '';
  // 本文
  text = '';

  constructor(private router: Router, private http: HttpClient, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<SmsDialogComponent>,
    private datePipe: DatePipe, private dialog: MatDialog, private ngZone: NgZone) { }

  ngOnInit() {
    let data = this.data

    this.siteName = data['siteName'];

    // 電話番号
    this.mobile = data['mobile'];

    // 件名
    this.title = this.siteName + 'の情報';

    // 本文取得
    this.getText(data['target']);
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 電話番号追加
   */
  addTel() {
    this.mobile.push({ name: '', mobile: '' });
  }

  /**
   * 電話番号削除
   * @param i index
   */
  deleteSms(i: number) {
    this.mobile.splice(i, 1);

    if (!this.mobile || !this.mobile.length) {
      this.addTel();
    }
  }

  /**
   * 本文取得
   * @param cdSite 物件コード
   */
  getText(val: any) {
    let cdSite = val[0].cdSite

    this.text += `「${this.siteName}」の物件情報をお送りいたします。\n`

    // データ取得
    this.http.get(API_BASE_URL + '/api/v1/SmsSiteInfo?&cdSite=' + cdSite,
      {headers: this.headers})
      .subscribe((res) => {
        let r = res as {}
        this.text += this.setInfo(r);
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      }
    );
  }

  /**
   * 物件情報をメール文にセット
   * @param val データ
   * @returns 
   */
  setInfo(val) {
    if (val) {
      return '\n' + '【注意事項】' + val.attention + '\n'
        + '【オートロック番号】' + val.lockNum + '\n'
        + '【キーボックス番号】' + val.keyBox + '\n'
        + '【キーボックス場所】' + val.keyBoxLocation + '\n'
        + '【メールボックス番号】' + val.mailBox + '\n'
        + '【清掃用具置き場】' + val.cleaningTools + '\n'
        + '【ゴミ置き場】' + val.trashArea + '\n'
        + '【ゴミ搬出場所】' + val.trashCarrying + '\n';
    } else {
      return '\n' + '【注意事項】\n'
        + '【オートロック番号】\n'
        + '【キーボックス番号】\n'
        + '【キーボックス場所】\n'
        + '【メールボックス番号】\n'
        + '【清掃用具置き場】\n'
        + '【ゴミ置き場】\n'
        + '【ゴミ搬出場所】\n';
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  /**
   * メール送信
   */
  sendSms() {
    let mobile = []
    for (let i = 0; i < this.mobile.length; i++) {
      const m = this.mobile[i]['mobile'];
      // 電話番号チェック
      if (m && m != '') {
        let val = m.trim();
        val = val.replace(/ /g, '').replace(/　/g, '').replace(/-/g, '').replace(/ー/g, '').replace(/―/g, '');
        val = this.replaceFullToHalf(val)
        
        if (val) {
          if (val.length == 11) {
            mobile.push(val);
          } else {
            alert(`${i + 1}番目の電話番号が11桁ではありません。`);
            return
          }
        }
      }
    }

    // 電話番号未入力
    if (!mobile || !mobile.length) {
      alert('電話番号を入力してください。');
      return
    }

    // 内容未入力
    if (!this.text) {
      alert('内容を入力してください。');
      return
    }
    
    // 送信確認
    if(!window.confirm('ショートメールを送信してよろしいでしょうか？')) {
      return
    }

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    let data = {
      mobile,
      text: this.text
    }

    this.http.post(
      API_BASE_URL + "/api/v1/SmsSiteInfo/send?siteName=" + this.siteName,
        data, httpOptions
    ).subscribe(res => {
      this.dialogRef.close();
    },error => {
      if (error.error.status == 800 && error.error.message){
        alert(error.error.message);
      } else if (error.error.status == 801){
        alert(error.error.message);
      } else {
        alert('エラーのため送信できませんでした。');
      }
      this.dialogRef.close();
    });
  }

  /**
   * 全角数字を半角に
   * @param str 対象電話番号
   * @returns 
   */
  replaceFullToHalf(str: string){
    return str.replace(/[！-～]/g, function(s: string){
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
    });
  }
}
