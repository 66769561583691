import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { API_BASE_URL } from '../../environments/environment';
import * as moment from 'moment';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-agreement-detail',
  templateUrl: './agreement-detail.component.html',
  styleUrls: ['./agreement-detail.component.less']
})
export class AgreementDetailComponent extends BaseComponent implements OnInit {

  public contractLedger: any = null;
  public purchase: any = null;
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  public workPlanHeader: any = null;

  nowloading1 = true;

  nowloading2 = true;

  nowloading3 = true;

  constructor(private http: HttpClient, private route: ActivatedRoute, private router: Router, private ngZone: NgZone) {
    super();
  }

  ngOnInit() {
    let cdSite = this.route.snapshot.queryParamMap.get("cdSite");
    let cdBillingAddress = this.route.snapshot.queryParamMap.get("cdBillingAddress");
    let noRec = this.route.snapshot.queryParamMap.get("noRec");
    console.info(cdSite);
    this.nowloading1 = true;
    this.nowloading2 = true;
    this.nowloading3 = true;
  
    // [契約情報」で表示する「年度」を生成
    let month = moment().month();
    let year = moment().year();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    // 作業明細を取得します
    this.http.get(API_BASE_URL + "/api/v1/contractLedgers/detail?cdSite=" + cdSite
      + "&cdBillingAddress=" + cdBillingAddress
      + "&noRec=" + noRec
      + "&year=" + year
    , {headers: this.headers})
    .subscribe(res => {
      if(res == null)
        return;
      this.contractLedger = res[0];
      this.contractLedger.totalFee = this.contractLedger.totalFee.replace('"', '');
      this.contractLedger.totalFee = this.contractLedger.totalFee.replace('"', '');
      this.contractLedger.totalFee = this.contractLedger.totalFee.replace(/,/g, '');
      this.contractLedger.totalFee = Number(this.contractLedger.totalFee);
      this.contractLedger.feePlan1 = Number(this.contractLedger.feePlan1.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan2 = Number(this.contractLedger.feePlan2.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan3 = Number(this.contractLedger.feePlan3.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan4 = Number(this.contractLedger.feePlan4.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan5 = Number(this.contractLedger.feePlan5.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan6 = Number(this.contractLedger.feePlan6.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan7 = Number(this.contractLedger.feePlan7.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan8 = Number(this.contractLedger.feePlan8.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan9 = Number(this.contractLedger.feePlan9.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan10 = Number(this.contractLedger.feePlan10.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan11 = Number(this.contractLedger.feePlan11.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.contractLedger.feePlan12 = Number(this.contractLedger.feePlan12.replace('"', '').replace('"', '').replace(/,/g, ''));
      this.nowloading1 = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);

    });
    // 作業予定月のヘッダラベルを取得します。
    this.http.get(API_BASE_URL + "/api/v1/contractLedgers/workPlanHeader"
    , {headers: this.headers})
    .subscribe(res => {
      if(res == null)
        return;
      this.workPlanHeader = res;
      this.nowloading2 = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);

    });
    // 仕入原価明細を取得します
    this.http.get(API_BASE_URL + "/api/v1/purchases/detail?cdSite=" + cdSite
      + "&cdBillingAddress=" + cdBillingAddress
      + "&noRec=" + noRec
      + "&year=" + year
    , {headers: this.headers})
    .subscribe(res => {
      if(res == null || (res as []).length == 0) {
        this.purchase = {
          totalFee: null,
          nameVendor: null
        };
        alert("発注先が登録されていません");
        this.nowloading3 = false;
        return;
      }
      this.purchase = res[0];
      if(this.purchase.totalFee != null) {
        console.info(this.purchase.totalFee);
        this.purchase.totalFee = this.purchase.totalFee.replace('"', '');
        this.purchase.totalFee = this.purchase.totalFee.replace('"', '');
        this.purchase.totalFee = this.purchase.totalFee.replace(/,/g, '');
        this.purchase.totalFee = Number(this.purchase.totalFee);
        this.purchase.feePlan1 = Number(this.purchase.feePlan1.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan2 = Number(this.purchase.feePlan2.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan3 = Number(this.purchase.feePlan3.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan4 = Number(this.purchase.feePlan4.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan5 = Number(this.purchase.feePlan5.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan6 = Number(this.purchase.feePlan6.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan7 = Number(this.purchase.feePlan7.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan8 = Number(this.purchase.feePlan8.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan9 = Number(this.purchase.feePlan9.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan10 = Number(this.purchase.feePlan10.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan11 = Number(this.purchase.feePlan11.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
        this.purchase.feePlan12 = Number(this.purchase.feePlan12.replace('"', '').replace('"', '').replace('"', '').replace('"', '').replace(/,/g, ''));
      } else {
        this.purchase.totalFee = 0;
      }
      this.nowloading3 = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
}
