import { Component, OnInit, NgZone } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import * as moment from 'moment';
import { BaseComponent } from '../base.component';
import { StaffDetailDialogComponent } from '../staffs/staff-detail-dialog/staff-detail-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-building-detail',
  templateUrl: './building-detail.component.html',
  styleUrls: ['./building-detail.component.less']
})
export class BuildingDetailComponent extends BaseComponent implements OnInit {
  public building: any;
  public staff: any;
  public contract: any;
  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });
  public workPlanHeader: any = null;

  nowloading1 = true;

  nowloading2 = true;

  nowloading3 = true;

  nowloading4 = true;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private ngZone: NgZone) {
    super();
  }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    console.info("id = " + id);

    this.nowloading1 = true;
    this.nowloading2 = true;
    this.nowloading3 = true;
    this.nowloading4 = true;

    this.http.get(API_BASE_URL + '/api/v1/sites/'+id,
    {headers: this.headers}).subscribe((building) => {
      this.building = building;
      this.http.get(API_BASE_URL + '/api/v1/instructEmployment/staffForSite?cdSite=' + id,
      {headers: this.headers})
      .subscribe((res) => {
        // console.info(res);
        if(res==null)
          return;
        this.staff = res;
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });

      // 「契約情報」で表示する「年度」を生成
      let month = moment().month();
      let year = moment().year();

      // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
      if (month == 0 || month == 1) {
        year -= 1;
      }

      // 契約情報
      this.http.get(API_BASE_URL + '/api/v1/sites/'+id+'/contract?year=' + year,
      {headers: this.headers})
      .subscribe((contract) => {
        if(contract==null) {
          this.nowloading2 = false;
          this.nowloading3 = false;
          return;
        }
        this.contract = contract;
        for(let i = 0; i < this.contract.length; i++) {
          this.contract[i].totalFee = this.contract[i].totalFee.replace('"', '');
          this.contract[i].totalFee = this.contract[i].totalFee.replace('"', '');
          this.contract[i].totalFee = this.contract[i].totalFee.replace(/,/g, '');
          this.contract[i].totalFee = Number(this.contract[i].totalFee);
          this.contract[i].feePlan1 = Number(this.contract[i].feePlan1.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan2 = Number(this.contract[i].feePlan2.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan3 = Number(this.contract[i].feePlan3.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan4 = Number(this.contract[i].feePlan4.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan5 = Number(this.contract[i].feePlan5.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan6 = Number(this.contract[i].feePlan6.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan7 = Number(this.contract[i].feePlan7.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan8 = Number(this.contract[i].feePlan8.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan9 = Number(this.contract[i].feePlan9.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan10 = Number(this.contract[i].feePlan10.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan11 = Number(this.contract[i].feePlan11.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.contract[i].feePlan12 = Number(this.contract[i].feePlan12.replace('"', '').replace('"', '').replace(/,/g, ''));
          this.http.get(API_BASE_URL + "/api/v1/purchases/detail?cdSite=" + this.building.cdSite
            + "&cdBillingAddress=" + this.contract[i].contractLedgerId.cdBillingAddress
            + "&noRec=" + this.contract[i].contractLedgerId.noRec
            + "&year=" + year
            + "&noAgreement=" + this.contract[i].contractLedgerId.noAgreement
            , {headers: this.headers})
            .subscribe(res => {
              if(res == null || (res as []).length == 0) {
                this.contract[i].purchase =  {
                  totalFee: '',
                  nameVendor: ''
                };
                if (i == this.contract.length - 1) {  
                this.nowloading2 = false;
                }
              } else {
                this.contract[i].purchase = res[0];
                this.contract[i].purchase.totalFee = this.contract[i].purchase.totalFee.replace('"', '');
                this.contract[i].purchase.totalFee = this.contract[i].purchase.totalFee.replace('"', '');
                this.contract[i].purchase.totalFee = this.contract[i].purchase.totalFee.replace(/,/g, '');
                this.contract[i].purchase.totalFee = Number(this.contract[i].purchase.totalFee);
                this.contract[i].purchase.feePlan1 = Number(this.contract[i].purchase.feePlan1.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan2 = Number(this.contract[i].purchase.feePlan2.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan3 = Number(this.contract[i].purchase.feePlan3.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan4 = Number(this.contract[i].purchase.feePlan4.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan5 = Number(this.contract[i].purchase.feePlan5.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan6 = Number(this.contract[i].purchase.feePlan6.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan7 = Number(this.contract[i].purchase.feePlan7.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan8 = Number(this.contract[i].purchase.feePlan8.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan9 = Number(this.contract[i].purchase.feePlan9.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan10 = Number(this.contract[i].purchase.feePlan10.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan11 = Number(this.contract[i].purchase.feePlan11.replace('"', '').replace('"', '').replace(/,/g, ''));
                this.contract[i].purchase.feePlan12 = Number(this.contract[i].purchase.feePlan12.replace('"', '').replace('"', '').replace(/,/g, ''));

                if (i == this.contract.length - 1) {  
                  this.nowloading2 = false;
                }
              }
            },
            error => {
              alert("認証に失敗しました");
              this.router.navigate(['/']);
            });

          this.nowloading3 = false;
        }
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });

      this.nowloading1 = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
    // 作業予定月のヘッダラベルを取得します。
    this.http.get(API_BASE_URL + "/api/v1/contractLedgers/workPlanHeader"
    , {headers: this.headers})
    .subscribe(res => {
      if(res == null) {
        this.nowloading4 = false;
        return;
      }
      this.workPlanHeader = res;
      this.nowloading4 = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }
  
  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 清掃員名クリックイベント
   */
  staffDetailDialogOpen(event: any) {
    const cdSite = this.building.cdSite
    this.dialog.open(StaffDetailDialogComponent, {
      data: {
        data: { ...event, cdSite }
      }
    });
  }
}
