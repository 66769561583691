import { Component, OnInit, Inject, NgZone } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDatepickerInputEvent } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';

export interface DialogData {
  any: any
}

@Component({
  selector: 'app-exchange-edit-dialog',
  templateUrl: './exchange-edit-dialog.component.html',
  styleUrls: ['./exchange-edit-dialog.component.less']
})
export class ExchangeEditDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 画面上半分に表示するデータ
  exchangeDialogData: any;

  // 振替指定日の日付
  exchangeDay: string = moment(this.data['day']).format('YYYY-MM-DD');

  // 画面表示用日付
  dayForDisp: string = moment(this.data['day']).format('YYYY/MM/DD');

  // 物件名
  siteName: '';

  // 時間ドロップダウン
  hours: number[] = [];
  
  // 分ドロップダウン
  minutes: string[] = [];

  // 「変更を保存」ボタン　活性:true、非活性:false
  saveDisabled:boolean = false;

  // ローディング
  loadingCompleteData:boolean = false;

  // 振替先データ
  exchange = [{
    id: null,
    sequenceNumber: this.data['data'].sequenceNumber,
    staffDailyCleaning: this.data['data'].staffDailyCleaning,
    cdSite: this.data['data'].cdSite,
    cdContract: this.data['data'].cdContract,
    nameSite: this.data['data'].nameSite,
    // 振替元
    day: '',
    // 変更前のデータ
    exchangeDayBefore: null,
    hourBefore: null,
    minuteBefore: null,
    note: null,
    // 変更後
    after: [{
      // 振替指定日
      exchangeDay: '',
      hour: '',
      minute: '00'
    }]
  }];

  constructor(private router: Router, private http: HttpClient, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<ExchangeEditDialogComponent>,
    private datePipe: DatePipe, private ngZone: NgZone) { }

  ngOnInit() {

    //開始時間、終了時間の選択
    this.hours = this.generateNums(23, true);
    this.minutes = [ '00', '15', '30', '45' ];

    this.getData();
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * 連番生成
   * @param n 最大値
   * @param isStartZero 0からスタートする
   * @returns　連番配列 
   */
  generateNums(n: number, isStartZero): number[] {
    if (isStartZero) {
      n++;
      return Array.from({length: n}, (x, i) => i);
    } else {
      return Array.from({length: n}, (x, i) => i + 1);
    }
  }

  
  /**
   * 現場に関係する清掃員情報を取得
   */
  getData() {
    this.http.get(API_BASE_URL + '/api/v1/sites/operationDialog?cdSite=' + this.data['data']['cdSite']
      + '&cdContract=' + this.data['data']['cdContract']
      + '&cdStaff=' + this.data['data']['staffDailyCleaning']
      + '&day=' + moment().format('YYYY-MM-DD') // 日付は今回不要なので何でもOK、本日日付を渡す
      + '&sequenceNumber=' + this.data['data']['sequenceNumber'],
      {headers: this.headers})
      .subscribe((res) => {
        this.siteName = this.data['data']['nameSite'];
        if (res == null || (res as []).length == 0) {
          this.exchangeDialogData = null
        } else {
          this.exchangeDialogData = res;
        }
        // 既存の振替データ取得
        this.http.get(API_BASE_URL + '/api/v1/exchange/getEdit?sequenceNumber=' + this.data['data']['sequenceNumber']
        + '&staffDailyCleaning=' + this.data['data']['staffDailyCleaning']
        + '&cdSite=' + this.data['data']['cdSite']
        + '&exchangeDay=' + this.exchangeDay,
        {headers: this.headers})
        .subscribe((res) => {
          if (res != null && (res as []).length > 0) {
            this.exchange = res as [];
            // 既存のデータのプロパティ名を変更
            this.exchange.forEach((e) => {
              e.exchangeDayBefore = e['exchangeDay']
              e.hourBefore = e['hour']
              e.minuteBefore = e['minute']
              e.after = [{
                exchangeDay: '',
                hour: e['hour'],
                minute: e['minute']
              }]
            })
            console.log(this.exchange)
          }
          this.loadingCompleteData = true;
          // this.inputTime();
        },
        error => {
          alert("認証に失敗しました");
          this.router.navigate(['/']);
          this.loadingCompleteData = true;
        }
      );

      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.loadingCompleteData = true;
      }
    );
  }

  /**
   * 振替先の日を選択
   * @param event 選択日
   * @param i インデックス
   */
  changeDate(event: MatDatepickerInputEvent<Date>, i: number, j:number) {
    let d = new FormControl(event.value);
    this.exchange[i].after[j].exchangeDay = moment(d.value).format('YYYY-MM-DD');
  }

  /**
   * 保存ボタンイベント
   */
  save() {
    const save = this.validateAndSetData()
    if (!save || !save.length) {
      return
    }
    this.saveDisabled = true;

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    this.http.post(
      API_BASE_URL + '/api/v1/exchange/saveEdit?staffName=' + this.data['data']['name']
      + '&siteName=' + this.siteName
      + '&show=' + this.data['data']['show']
      , save
      , httpOptions
    ).subscribe(res => {
      this.dialogRef.close();
    },error => {
      alert('認証に失敗しました');
      this.router.navigate(['/']);
      this.dialogRef.close();
    });
  }

  /**
   * 閉じるボタンイベント
   */
  close() {
    this.dialogRef.close();
  }

  /**
   * 削除ボタン
   * @param ex 振替データ
   * @param j インデックス
   */
  delete(ex, j) {
    ex.after.splice(j, 1);
  }

  /**
   * 追加ボタン
   */
  add(ex) {
    console.log(ex)
    ex.after.push({
      exchangeDay: '',
      hour: '',
      minute: '00'
    })
  }

  /**
   * バリデートとデータを成形する
   * @returns 
   */
  validateAndSetData() {
    let res = []
    let count = 0
    let noSaveIdx = []
    for (let i = 0; i < this.exchange.length; i++) {
      const ex = this.exchange[i];
      for (let j = 0; j < ex.after.length; j++) {
        const e = ex.after[j];
        count++

        if (e.exchangeDay && e.exchangeDay != 'Invalid date' && e.hour && e.minute) {
          if (!(e.hour == '0' && e.minute == '00')) {
            res.push({
              id: ex.id,
              sequenceNumber: ex.sequenceNumber,
              staffDailyCleaning: ex.staffDailyCleaning,
              cdSite: ex.cdSite,
              cdContract: ex.cdContract,
              nameSite: ex.nameSite,
              // 振替元日
              day: ex.day,
              // 振替指定日
              exchangeDay: e.exchangeDay,
              hour: e.hour,
              minute: e.minute
            })
          } else {
            noSaveIdx.push(count)
          }
        } else {
          noSaveIdx.push(count)
        }
      }
    }

    // 保存するデータがない場合
    if (!res || !res.length) {
      alert('保存するデータがありません。変更先の記入を完成させてください。')
      return null
    } else if (noSaveIdx && noSaveIdx.length) {
      if (!window.confirm(noSaveIdx.join('、') + '番目のデータは変更されませんがよろしいでしょうか？')) {
        return null
      }
    }
    return res
  }
}
