import { Component, Inject, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

export interface DialogData {
  data
}

@Component({
  selector: 'app-buildings-export-dialog',
  templateUrl: './buildings-export-dialog.component.html',
  styleUrls: ['./buildings-export-dialog.component.less'],
})

export class BuildingsExportDialogComponent implements OnInit {

  nowLoading = false;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  public chk = new Array(30).fill(true);

  // httpヘッダー
  public httpOptions;

  constructor(private http: HttpClient, private router: Router, @Inject(MAT_DIALOG_DATA) private data: DialogData, private dialogRef: MatDialogRef<BuildingsExportDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * エクスポート クリック
   */
  clickExport() {
    this.nowLoading = true;

    // 「契約情報」で表示する「年度」を生成
    let month = moment().month();
    let year = moment().year();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    let staff = false
    let contract = false
    if (this.chk[6] || this.chk[7] || this.chk[8] || this.chk[9] || this.chk[10]) {
      staff = true
    }
    if (this.chk[20] || this.chk[21] || this.chk[22] || this.chk[23] || this.chk[24] || this.chk[25]
      && this.chk[26] || this.chk[27]) {
      contract = true
    }

    // エクスポートデータ取得
    this.http.get(API_BASE_URL + '/api/v1/sites/export?filterNameBillingAddress=' + encodeURIComponent(this.data.data.filterNameBillingAddress)
      + "&filterNameSite=" + encodeURIComponent(this.data.data.filterNameSite)
      + "&filterAddress=" + encodeURIComponent(this.data.data.filterAddress)
      + "&staffInfo=" + staff
      + "&contractInfo=" + contract,
      {headers: this.headers})
    .subscribe((sites:[]) => {
      if(sites && sites.length > 0) {
        this.export(sites)
        this.nowLoading = false;
        this.dialogRef.close();
        return;
      } else {
        this.nowLoading = false;
        this.dialogRef.close();
        alert("対象データがありませんでした。");
      }
    },
    error => {
      this.nowLoading = false;
      this.dialogRef.close();
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 全て クリック
   * @param s 開始インデックス
   * @param e 終了インデックス
   */
  clickCheck(s, e) {
    this.checkContract(true, s, e)
  }

  /**
   * 解除 クリック
   * @param s 開始インデックス
   * @param e 終了インデックス
   */
  clickUnCheck(s, e) {
    this.checkContract(false, s, e)
  }

  /**
   * キャンセル クリック
   */
  cancel() {
    this.dialogRef.close();
  }

  /**
   * 契約情報チェック
   * @param val 設定値
   * @param s 開始インデックス
   * @param e 終了インデックス
   */
  checkContract(val, s, e) {
    for (let idx = s; idx < e; idx++) {
      this.chk[idx] = val
    }
  }

  /**
   * エクスポート
   * @param data 出力データ
   */
  export(data) {
    const output = this.createExportData(data);
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(output);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, '物件一覧.xlsx');
  }

  /**
   * エクスポート データ生成
   * 出力オブジェクト配列を生成する。
   * @param list 出力データ
   */
  createExportData(list) {
    let retArr = [];

    for (let idx = 0; idx < list.length; idx++) {
      let row = {};
      let data = list[idx]

      // 物件情報
      if (this.chk[0]) row['物件CD'] = data.cdSite;
      if (this.chk[1]) row['物件名'] = (data.nameSiteFirst + ' ' + data.nameSiteSecond).trim();
      if (this.chk[2]) row['得意先名'] = data.nameBillingAddressFirst;
      if (this.chk[3]) row['郵便番号'] = data.zip;
      if (this.chk[4]) row['住所'] = (data.addressFirst + ' ' + data.addressSecond).trim();
      if (this.chk[5]) row['支店名'] = data.nameBillingAddressSecond;

      // 清掃員情報
      if (this.chk[6]) row['清掃員コード'] = data.staffDailyCleaning;
      if (this.chk[7]) row['清掃員名'] = data.nameStaff;
      if (this.chk[8]) row['清掃員電話番号'] = data.numberPhoneStaff;
      if (this.chk[9]) row['清掃員携帯番号'] = data.numberMobilePhoneStaff;
      if (this.chk[10]) row['清掃員住所'] = data.addressStaff;

      // 契約情報
      if (this.chk[20]) row['契約情報'] = data.nameKubunWorkContent;
      if (this.chk[21]) row['作業内容'] = data.nameWorkContent;

      for (let month = 1; month <= 12; month++) {
        if (this.chk[22]) row['予定月' + month] = this.removePlanYear(data['workPlanHeader' + month]);
        if (this.chk[23]) row['作業予定' + month] = this.getPlanOutput(data['workPlan' + month]);
        if (this.chk[24]) {
          if(data['feePlan' + month]) row['作業受' + month] = data['feePlan' + month].replace(/"/g, '');
          else row['作業受' + month] = '';
        }
        if (this.chk[25]) {
          if (data['purchaseFeePlan' + month]) row['作業発' + month] = data['purchaseFeePlan' + month].replace(/"/g, '');
          else row['作業発' + month] = '';
        }
      }

      if (this.chk[26]) {
        if (data.totalFee) row['発注年額'] = data.totalFee.replace(/"/g, '');
        else row['発注年額'] = '';
      }
      if (this.chk[27]) row['発注先'] = data.nameVendor;
      retArr.push(row);
    }

    return retArr;
  }

  /**
   * 予定月 年削除
   */
  removePlanYear(value) {
    if (!value) return value
    const start = value.indexOf('年')
    if (start > 0) return value.substr(start + 1)
    return value
  }

  /**
   * 予定出力内容 取得
   */
  getPlanOutput(value) {
    if (!value) return '-'
    return value
  }
}


