import { Component, OnInit, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { Router }            from '@angular/router';
import { API_BASE_URL }       from '../../environments/environment';
import { MatTableDataSource } from '@angular/material';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  public userName: string = "";
  public password: string = "";
  public version: string = "";

  constructor(
    private http: HttpClient,
    private router: Router,
    private ngZone: NgZone
    ) { }

  ngOnInit() {
    console.info(API_BASE_URL);
    this.getVersion();
  }

  async getVersion() {
    // バージョン取得
    await this.http.get(API_BASE_URL + '/api/v1/param?key=version')
      .toPromise().then((res: any) => {
        var ret = new MatTableDataSource(res as []);
        if ( ret && ret.data.length > 0) {
          localStorage.setItem("version", ret.data[0]['parameterValue']);
          this.version = localStorage.getItem('version');
        }
    });
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  login() {
    this.http.post(API_BASE_URL + '/api/v1/login', {
      userId: this.userName,
      password: this.password
    },
    {
      headers: new HttpHeaders({
        'X-LoginAccessKey': 'xxx'
      })
    }).subscribe(res => {
      if(res==null)
        alert("ログインに失敗しました");
      else {
        localStorage.setItem("accessKey", res['accessKey']);
        localStorage.setItem("uid", res["userId"]);
        localStorage.setItem("authority", res["authority"]);
        localStorage.setItem("outsource", res["outsource"]);
        localStorage.setItem("deleteHoliday", res["deleteHoliday"]);
        localStorage.setItem("cannotInstead", res["cannotInstead"]);
        localStorage.setItem("spot", res["spot"]);
        localStorage.setItem("userManagement", res["userManagement"]);
        localStorage.setItem("operationArrangement", res["operationArrangement"]);
        localStorage.setItem('search_site_name', '');
        this.router.navigate(['/buildings']);
      }
    },
    error => {
      alert("認証に失敗しました");
    });
  }

}
