import { Component, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { MediaMatcher } from '@angular/cdk/layout';
import { API_BASE_URL } from 'src/environments/environment';
import { BaseComponent } from './base.component';
import { MatTableDataSource } from '@angular/material';
import { version } from '../../package.json';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent extends BaseComponent {
  title = 'akbs';
  path  = '';

  mobileQuery: MediaQueryList;

  fillerNav = Array.from({length: 10}, (_, i) => `Nav Item ${i + 1}`);

  private _mobileQueryListener: () => void;

  // ユーザー情報
  target;

  // httpヘッダー
  httpOptions;

  headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router,
    changeDetectorRef: ChangeDetectorRef, media: MediaMatcher, private ngZone: NgZone) {
    super();
    this.mobileQuery = media.matchMedia('(max-width: 1590px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    this.router.events.subscribe((url: any) => {
      this.path = this.router.url;
    });
  }
  async ngOnInit() {
    console.info("ngOnInit()");    
    this.getVersion();
  }

  async getVersion() {
    // バージョン取得
    await this.http.get(API_BASE_URL + '/api/v1/param?key=version')
      .toPromise().then((res: any) => {
        var ret = new MatTableDataSource(res as []);
        if ( ret && ret.data.length > 0) {
          localStorage.setItem("version", ret.data[0]['parameterValue']);

          if (version.trim() != localStorage.getItem("version")) {
            alert('古いページを開いています。ブラウザを閉じて開き直してください。');
          }
        }
    });
  }

  goTo(comp){
    this.ngZone.run(()=>{
    this.router.navigate([comp])
    });
  }
  
  /**
   * メニュー クリック
   * @param comp 遷移先コンポーネント
   */
  clickMenu(comp) {
    this.goTo(comp);
  }

  getAuthority() {
    return localStorage.getItem("authority");
  }

  logout() {
    
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    // ログ内容
    this.target = {
      level: 2,
      userId: localStorage.getItem("uid"),
      serviceName: 'ログアウト',
      operation: 'ログアウト',
      logText: 'ログアウトしました。'
    }

    // ログ登録
    this.http.post(
      API_BASE_URL + "/api/v1/log/save",　this.target, this.httpOptions
    ).subscribe(res => {
      // localStorageリセット
      localStorage.setItem("accessKey", '');
      localStorage.setItem("uid", '');
      this.router.navigate(['login']);
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * メニュータグにホバーしたら、子メニューが出現
   * @param target 対象クラス名
   */
  showDrop(target) {
    // 子メニューa要素
    const childMenuLink = document.getElementsByClassName(target);

    // is-activeを付加
    for (let i = 0; i < childMenuLink.length; i++) {
      childMenuLink[i].classList.add('open');
    }
  }

  /**
   * メニュータグのホバーが外れたら、子メニューが非表示
   * @param target 対象クラス名
   */
  hiddenDrop(target) {
    // 子メニューa要素
    const childMenuLink = document.getElementsByClassName(target);

    // is-activeを付加
    for (let i = 0; i < childMenuLink.length; i++) {
      childMenuLink[i].classList.remove('open');
    }
  }

  shouldRun = [/(^|\.)plnkr\.co$/, /(^|\.)stackblitz\.io$/].some(h => h.test(window.location.host));
}
