import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {MatDatepicker} from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import { Moment } from 'moment';

const moment = _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY年MM月',
  },
  display: {
    dateInput: 'YYYY年MM月',
    monthYearLabel: 'YYYY MMM',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY MMMM',
  },
};


@Component({
  selector: 'app-year-month-datepicker',
  templateUrl: './year-month-datepicker.component.html',
  styleUrls: ['./year-month-datepicker.component.less'],
  providers: [
    // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
    // application's root module. We provide it at the component level here, due to limitations of
    // our example generation script.
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class YearMonthDatepickerComponent {
  date = new FormControl(moment());

  @Input('ngInputModel') model: Moment;
  @Output('ngModelChange') modelChange = new EventEmitter<Moment>();

  @Input('min') public min:Date;
  @Input('max') public max:Date;
  @Output('yearSelected') yearSelected = new EventEmitter<Moment>();
  @Output('monthSelected') monthSelected = new EventEmitter<Moment>();

  /**
   * 年が選ばれたときのハンドラ
   * @param normalizedYear 
   */
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.date.value;
    ctrlValue.year(normalizedYear.year());
    this.date.setValue(ctrlValue);
    this.yearSelected.emit(normalizedYear);
  }

  /**
   * 月が選ばれたときのハンドラ
   * @param normalizedMonth 
   * @param datepicker 
   */
  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.date.value;
    ctrlValue.month(normalizedMonth.month());
    this.date.setValue(ctrlValue);
    this.monthSelected.emit(normalizedMonth);
    datepicker.close();
    
  }
}
