import { Component, OnInit, Input } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-profit-chart',
  templateUrl: './profit-chart.component.html',
  styleUrls: ['./profit-chart.component.less']
})

export class ProfitChartComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // 粗利データ
  @Input('profitData')
  profitData: any[];

  // 「合計のみ」「月単位」表示
  @Input('profitDisplay')
  profitDisplay: string;

  // 物件名
  siteNames = [];

  // 表示切替
  disp1 = 'amount';

  // 「合計のみ」金額
  barChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'left'
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data){
          return data.datasets[tooltipItem.datasetIndex].label + " : " + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +' 円';
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            fontSize: 11
          }  
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: function(label, index, labels) {
                return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        }
      ]
    },
  };

  // 「月単位」金額
  barChartOptions2: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data){
          return data.datasets[tooltipItem.datasetIndex].label + " : " + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +' 円';
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            fontSize: 11
          }  
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: function(label, index, labels) {
                return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
          }
        }
      ]
    },
  };

  // 「合計のみ」粗利率
  lineChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'left'
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data){
          return data.datasets[tooltipItem.datasetIndex].label + " : " + tooltipItem.yLabel.toString() +' %';
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            fontSize: 11
          }  
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: function(label, index, labels) {
              return label.toString() +' %';
            },
            max: '100',
            min: '-100'
          }
        }
      ]
    }
  };

  // 「月単位」粗利率
  lineChartOptions2: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data){
          return data.datasets[tooltipItem.datasetIndex].label + " : " + tooltipItem.yLabel.toString() +' %';
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            fontSize: 11
          }  
        }
      ],
      yAxes: [
        {
          ticks: {
            callback: function(label, index, labels) {
              return label.toString() +' %';
            },
            max: '100',
            min: '-20'
          },
        }
      ]
    },
  };

  // 「合計のみ」複合
  compositeChartOptions1: ChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      position: 'left'
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data){
          return data.datasets[tooltipItem.datasetIndex].label + " : " + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            fontSize: 11
          }  
        }
      ],
      yAxes: [
        {
          id: 'amount',
          type: 'linear',
          position: 'left',
          ticks: {
            callback: function(label, index, labels) {
                return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            min: '0'
          }
        },
        {
          id: 'rate',
          type: 'linear',
          position: 'right',
          ticks: {
            callback: function(label, index, labels) {
              return label.toString() +' %';
            },
            max: '100',
            min: '0'
          },
          gridLines: {
            drawOnChartArea: false, 
          },
        }
      ]
    }
  };

  // 「月単位」複合
  compositeChartOptions2: ChartOptions = {
    responsive: true,
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data){
          return data.datasets[tooltipItem.datasetIndex].label + " : " + tooltipItem.yLabel.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        }
      }
    },
    scales: {
      xAxes: [
        {
          ticks: {
            autoSkip: false,
            fontSize: 11
          }  
        }
      ],  
      yAxes: [
        {
          id: 'amount',
          type: 'linear',
          position: 'left',
          ticks: {
            callback: function(label, index, labels) {
                return label.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +' 円';
            },
            min: '0'
          }
        },
        {
          id: 'rate',
          type: 'linear',
          position: 'right',
          ticks: {
            callback: function(label, index, labels) {
              return label.toString() +' %';
            },
            max: '100',
            min: '0'
          },
          gridLines: {
            drawOnChartArea: false, 
          },
        }
      ]
    },
  };

  // 棒グラフ 初期値
  barChartType: ChartType = 'bar';

  // 折れ線グラフ
  lineChartType: ChartType = 'line';

  chartLegend = true;

  // ラベル
  chartLabels: Label[];

  // 「合計のみ」「金額」
  barChartData1 = [];

  // 「合計のみ」「粗利率」
  lineChartData1 = [];
  
  // 「合計のみ」「複合」
  compositeChartData1 = [];
  
  // 「月単位」「金額」
  barChartData2 = new Object();
  
  // 「月単位」「粗利率」
  lineChartData2 = new Object();

  // 「月単位」「複合」
  compositeChartData2 = new Object();

  ngOnInit() {
    this.getData();
  }

  /**
   * ラベル,　金額データを取得
   */
  getData() {
    let label = [];
    let contract = [];
    let cost = [];
    let profit = [];
    let rate = [];

    // 「合計のみ」の場合
    if (this.profitDisplay == 'onlyTotal') {
      
      for (let i = 0; i < this.profitData.length; i++) {
        // X軸のラベル
        let val = this.profitData[i].nameSite;
        // 外注の場合は2行で表示
        if (val.indexOf('外注') != -1) {
          label.push([val.substr(0, val.length-4), val.substr(-4)]);
        } else {
          label.push(this.profitData[i].nameSite);
        }
        // 受注金額のデータ
        contract.push(Number(this.profitData[i].totalContract));
        // 原価確定のデータ
        cost.push(Number(this.profitData[i].totalCost));
        // 粗利のデータ
        profit.push(Number(this.profitData[i].totalProfit));

        // 粗利率のデータ
        rate.push(this.calculateRate(Number(this.profitData[i].totalProfit), Number(this.profitData[i].totalContract)));
      }

      this.barChartData1 = [
        {data: contract, label: '受注'},
        {data: cost, label: '原価'},
        {data: profit, label: '粗利'}
      ];
      
      this.lineChartData1 = [
        {type: 'line', data: rate, label: '粗利率'}
      ];

      this.compositeChartData1 = [
        {type: 'bar', data: contract, label: '受注(円)', yAxisID: 'amount'},
        {type: 'bar', data: cost, label: '原価(円)', yAxisID: 'amount'},
        {type: 'bar', data: profit, label: '粗利(円)', yAxisID: 'amount'},
        {type: 'line', data: rate, backgroundColor: 'rgb(226, 253, 226)', label: '粗利率(%)', yAxisID: 'rate'}
      ]

    // 「月単位」の場合
    } else {
      // X軸のラベル   
      label = [
        '合計',
        '3月',
        '4月',
        '5月',
        '6月',
        '7月',
        '8月',
        '9月',
        '10月',
        '11月',
        '12月',
        '1月',
        '2月'
      ];

      for (let i = 0; i < this.profitData.length; i++) {
        
        contract = [];
        cost = [];
        profit = [];
        rate = [];
        
        // 物件名
        this.siteNames.push(this.profitData[i].nameSite);
        // 受注金額のデータ
        contract.push(Number(this.profitData[i].totalContract));
        // 原価確定のデータ
        cost.push(Number(this.profitData[i].totalCost));
        // 粗利のデータ
        profit.push(Number(this.profitData[i].totalProfit));

        // 粗利率のデータ
        rate.push(this.calculateRate(Number(this.profitData[i].totalProfit), Number(this.profitData[i].totalContract)));

        // 3～12月のデータ
        for (let j = 3; j < 13; j++) {
          // 受注金額のデータ
          contract.push(Number(this.profitData[i]['contract'+j]));
          // 原価確定のデータ
          cost.push(Number(this.profitData[i]['cost'+j]));
          // 粗利のデータ
          profit.push(Number(this.profitData[i]['profit'+j]));

          // 粗利率のデータ
          rate.push(this.calculateRate(Number(this.profitData[i]['profit'+j]), Number(this.profitData[i]['contract'+j])));
        }

        // 1～2月のデータ
        for (let j = 1; j < 3; j++) {
          // 受注金額のデータ
          contract.push(Number(this.profitData[i]['contract'+j]));
          // 原価確定のデータ
          cost.push(Number(this.profitData[i]['cost'+j]));
          // 粗利のデータ
          profit.push(Number(this.profitData[i]['profit'+j]));
          
          // 粗利率のデータ
          rate.push(this.calculateRate(Number(this.profitData[i]['profit'+j]), Number(this.profitData[i]['contract'+j])));
        }
        
        // 金額 表示
        let amountData = [
          {data: contract, label: '受注'},
          {data: cost, label: '原価'},
          {data: profit, label: '粗利'}
        ]
        this.barChartData2[i] = amountData;

        // 粗利率 表示
        let rateData = [
          {type: 'line', data: rate, label: '粗利'}
        ]
        this.lineChartData2[i] = rateData;

        // 複合 表示
        let compositeData = [
          {type: 'bar', data: contract, label: '受注(円)', yAxisID: 'amount'},
          {type: 'bar', data: cost, label: '原価(円)', yAxisID: 'amount'},
          {type: 'bar', data: profit, label: '粗利(円)', yAxisID: 'amount'},
          {type: 'line', data: rate, label: '粗利率(%)', yAxisID: 'rate'}
        ]
        this.compositeChartData2[i] = compositeData;
      }
    }
    this.chartLabels = label;
  }

  /**
   * 「月単位」表示の物件毎のグラフ
   * @param i インデックス
   * @returns 
   */
  setData(i: number) {
    if (this.disp1 == 'amount') {
      return this.barChartData2[i];
    } else if (this.disp1 == 'rate') {
      return this.lineChartData2[i];
    } else {
      return this.compositeChartData2[i];
    }
  }

  /**
   * 「合計のみ」のグラフのwidth設定
   */
  chartWidth() {
    let width = 0;
    switch (this.profitData.length > 9) {
      case true:
        width = this.profitData.length * 150;
        break;
    
      default:
        width = this.profitData.length * 300;
        break;
    }
    return width;
  }

  /**
   * 粗利率を算出
   * @param profitAmount 粗利金額
   * @param contractAmount 受注金額
   */
  calculateRate(profitAmount: number, contractAmount: number) {
    let data = [];
    if (profitAmount == 0) {
      data.push(0);
    } else if (contractAmount == 0) {
      data.push(-100);
    } else {
      let num = profitAmount /contractAmount * 100;
      num = Math.floor(num * 100) / 100;
      data.push(num);
    }
    return data;
  }
}
