import { Component, OnInit, ViewChild, AfterViewInit, ChangeDetectorRef, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { API_BASE_URL } from '../../environments/environment';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-building-setting-detail',
  templateUrl: './building-setting-detail.component.html',
  styleUrls: ['./building-setting-detail.component.less']
})

export class BuildingSettingDetailComponent extends BaseComponent implements OnInit, AfterViewInit {
  // ローディング中 
  nowLoading = true;

  // 物件詳細情報
  siteInfo = [];

  // 物件名
  siteName = '';

  // 得意先名
  clientName1 = '';
  clientName2 = '';

  // 清掃員リスト
  dataSource = new MatTableDataSource();

  // 清掃員リスト件数
  dataSourceLength = 0;

  // 清掃員名検索
  filterName = '';

  // 清掃員カナ検索
  filterNameKana = '';

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // カラム名 物件一覧
  columnDefs = [
    'name'
  ];

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });
  
  // httpヘッダー
  public httpOptions;

  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private cdr: ChangeDetectorRef, private ngZone: NgZone) {
    super();
  }

  ngOnInit() {
    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };
    let cdSite = this.route.snapshot.paramMap.get('cdSite');
    let cdClient = this.route.snapshot.paramMap.get('cdClient');
    this.getData(cdSite, cdClient);
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * ページ読み込み後の初期化メソッド
   */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      // 清掃員リスト
        this.getStaffList(this.paginator.pageIndex, this.paginator.pageSize);
    });
    this.getStaffList(this.paginator.pageIndex, this.paginator.pageSize);
    this.cdr.detectChanges();
  }

  applyFilter() {
    this.paginator.pageIndex = 0;
    this.getStaffList(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * 物件情報取得
   */
  getData(cdSite: string, cdClient: string) {
    this.http.get(
      API_BASE_URL + '/api/v1/siteInfo?cdSite=' + cdSite
        + "&cdClient=" + cdClient
      , {headers: this.headers}
      )
    .subscribe(res => {
      let data = res as {};
      if (data) {
        this.siteName = data['siteName'];
        this.clientName1 = data['clientName1'];
        this.clientName2 = data['clientName2'];
        this.siteInfo = data['siteInfo'];
      }
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 清掃員リスト取得
   * @param index 開始インデックス
   * @param size  取得サイズ
   */
  getStaffList(index: number, size: number) {
    this.http.get(
      API_BASE_URL + '/api/v1/instructEmployment/staffList?s=0&e=8000&index=' + index
        + "&size=" + size
        + "&filterName=" + encodeURIComponent(this.filterName)
        + "&filterNameKana=" + encodeURIComponent(this.filterNameKana)
      , {headers: this.headers}
      )
    .subscribe(res => {
      this.dataSource = new MatTableDataSource(res as []);
      // ページネーターに表示する全サイズを取得
      let data = res as any[];
      if (data != null && data.length > 0) {
        this.dataSourceLength = data[0].totalSize;
      } else {
        this.dataSourceLength = 0;
      }
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 一覧に戻るイベント
   */
  goBack() {
    this.router.navigate(['/buildingSetting']);
  }

  /**
   * 代勤不可削除ボタン権限
   * @returns 
   */
  deleteDisabled() {
    console.log(localStorage.getItem("cannotInstead"))
    if (localStorage.getItem("cannotInstead") == 'true') {
      return false;
    } else {
      return true;
    }
  }

  /**
   * 清掃員名クリックイベント
   * @param target 対象清掃員
   */
  clickStaff(target: { staffDailyCleaning: any; name: any; }) {
    for (let i = 0; i < this.siteInfo.length; i++) {
      const s = this.siteInfo[i];
      if (s.staffDailyCleaning == target.staffDailyCleaning) {
        alert(target.name + 'さんは既に選択しています。')
        return
      }
    }
    this.siteInfo.push({staffDailyCleaning: target.staffDailyCleaning, name: target.name, memo: '' })
  }

  /**
   * 清掃員削除イベント
   * @param i 
   */
  delete(i: number) {
    this.siteInfo.splice(i, 1);
  }

  /**
   * 代勤不可保存イベント
   */
  saveCannotInstead() {
    this.nowLoading = true;
    let nameList = '';
    let name = [];
    for (let i = 0; i < this.siteInfo.length; i++) {
      const s = this.siteInfo[i];
      name.push(s.name);
    }
    if (name.length) {
      nameList = name.join(',');
    }

    this.http.post(
      API_BASE_URL + "/api/v1/siteInfo/save?cdSite=" + this.route.snapshot.paramMap.get('cdSite')
      + "&cdClient=" +  this.route.snapshot.paramMap.get('cdClient')
      + "&siteName=" + this.siteName
      + "&nameList=" + nameList
      , this.siteInfo
      , this.httpOptions
    ).subscribe(res => {
      if (window.confirm('保存しました。一覧に戻りますか?')) {
        this.router.navigate(['/buildingSetting']);
      }
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.nowLoading = false;
    });
    this.nowLoading = false;
  }
}