import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders }  from '@angular/common/http';
import { Router }     from '@angular/router';
import { API_BASE_URL }                   from '../../environments/environment';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import * as XLSX from 'xlsx';
import { BaseComponent } from '../base.component';

export interface Customer {
  nameBillingAddress1: string;
  nameBililngAddress2: string;
  address1: string;
  numberPhone: string;
}

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.less']
})
export class CustomersComponent extends BaseComponent implements OnInit {
// 得意先一覧テーブルのデータソース
public dataSource = new MatTableDataSource();

// 得意先一覧の全レコードの件数
public dataSourceLength = 0;

// エクスポート用全件データ
exportData = [];

public nowLoading = true;

public keyword = "";

public filterNameBillingAddress = "";

public filterNameBillingAddressKana = "";

@ViewChild(MatPaginator) paginator: MatPaginator;

@ViewChild('TABLE') table: ElementRef;

public headers = new HttpHeaders({
  "X-LoginAccessKey": localStorage.getItem("accessKey")
});

public columnDefs = [
  'nameBillingAddress1',
  'nameBillingAddress2',
  'address1',
  'numberPhone'
];

constructor(private http: HttpClient, private router: Router, private ngZone: NgZone) {
  super();
}

  ngOnInit() {}

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
  * ページ読み込み後の初期化メソッド
  */
  ngAfterViewInit() {
    console.info("ngAfterViewInit()");
    this.dataSource.paginator = this.paginator;
    this.paginator.page.subscribe(() => {
      this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
      this.getAll(this.keyword);
    });
    this.getAll(this.keyword);
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
  }
  /**
  * セルクリック時のコールバック
  * @param event 選択された行オブジェクト
  */
  cellClicked(event) {
    this.router.navigate(['/customers/' + event.cdBillingAddress]);
  }

  applyFilter(keyword: string) {
    this.keyword = keyword;
    this.paginator.pageIndex = 0;
    this.getAgreements(this.paginator.pageIndex, this.paginator.pageSize);
    this.getAll(this.keyword);
  }

  /**
  * 契約一覧を取得します
  * @param index 開始インデックス
  * @param size  取得サイズ
  */
  getAgreements(index: number, size: number) {
    this.nowLoading = true;
    console.info(this.filterNameBillingAddress + ", " + this.filterNameBillingAddressKana);
    this.http.get(
      API_BASE_URL + '/api/v1/billingAddresses?s=0&e=8000&index=' + index + "&size=" + size
                + "&filterNameBillingAddress=" + encodeURIComponent(this.filterNameBillingAddress)
                + "&filterNameBillingAddressKana=" + encodeURIComponent(this.filterNameBillingAddressKana),
      {headers: this.headers}
    )
    .subscribe(res => {
      console.info(res);
      this.dataSource = new MatTableDataSource(res as Customer[]);
      this.nowLoading = false;
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
  * 全レコードを取得
  */
  getAll(keyword: string) {
    this.http.get(
      API_BASE_URL + '/api/v1/billingAddresses/all?filterNameBillingAddress=' + encodeURIComponent(this.filterNameBillingAddress)
              + "&filterNameBillingAddressKana=" + encodeURIComponent(this.filterNameBillingAddressKana),
        {headers: this.headers}
      ).subscribe(res => {
        this.exportData = res as [];
        this.dataSourceLength = this.exportData.length;
        // エクスポート用に生成
        let data = []
        for (let i = 0; i < this.exportData.length; i++) {
          const e = this.exportData[i]
          data.push({ '得意先名':  e.nameBillingAddress1, '支店名': e.nameBillingAddress2, '住所': e.address1, 'TEL': e.numberPhone})
        }
        this.exportData = data;
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * エクスポートボタン
   */
  downloadCsv() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    // ファイル名設定
    let fileName = '得意先一覧.xlsx';
    XLSX.writeFile(wb, fileName);
  }
}
