import { Component, OnInit, NgZone } from '@angular/core';
import { Router} from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { MatDialogRef, MatTableDataSource } from '@angular/material';
import { min } from 'moment';

@Component({
  selector: 'app-skill-item-dialog',
  templateUrl: './skill-item-dialog.component.html',
  styleUrls: ['./skill-item-dialog.component.less']
})

export class SkillItemDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  // スキルデータ
  skillList = new MatTableDataSource();

  // 更新日
  createdDate = null;

  // 更新者
  createdUser = null;

  // スコア配分
  score = {};

  // Bクラスドロップダウン
  selectB = [];

  // 削除するデータ
  deleteData = [];

  // カラム
  columnDefs = [
  'item',
  'fold',
  'delete'
];

  // httpヘッダー
  public httpOptions;

  constructor(private http: HttpClient, private router: Router, private dialogRef: MatDialogRef<SkillItemDialogComponent>, private ngZone: NgZone) { }

  ngOnInit() {

    this.httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    this.getSkillItem();

  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  /**
   * スキルデータ取得
   */
  getSkillItem() {

    // スキル項目取得
    this.http.get(
      API_BASE_URL + '/api/v1/skillItem',
      {headers: this.headers}
    )
    .subscribe(res => {
      this.skillList = new MatTableDataSource(res as []);

      if ((res as []).length > 0) {
        this.createdDate = res[0]['createdDate'];
        this.createdUser = res[0]['createdUser'];
      }

      // スコア配分取得
      this.http.get(
        API_BASE_URL + '/api/v1/skillEvaluationClass',
        {headers: this.headers}
      )
      .subscribe(res => {

        if ((res as any[]).length == 0) {
          this.score['minB'] = 0;
          this.score['maxB'] = 0;
        } else {
          this.score['minB'] = res[0].minB;
          this.score['maxB'] = res[0].maxB;
        }

        this.setScore();
      },
      error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
      });
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 入力欄追加
   */
  add() {
    let data = this.skillList.data;

    data.push({
      item: null,
      fold: 1
    })

    this.skillList = new MatTableDataSource(data as []);

    this.setScore();
  }

  /**
   * 登録
   */
  register() {

    // スコア項目登録

    let data = this.skillList.data;
    let minB = false;
    let maxB = false;

    for (let i = 0; i < data.length; i++) {
      
      // 入力漏れがないか確認
      if (!data[i]['item'] || data[i]['item'] == '') {
        alert('項目名が入力されていない箇所があります');
        return;
      }
      if (!data[i]['fold'] || data[i]['fold'] == '') {
        alert('ポイントが入力されていない箇所があります');
        return;
      }
      
      data[i]['createdDate'] = new Date();
      data[i]['createdUser'] = localStorage.getItem("uid");
    }

    // Bクラスの最小値と最大値を確認
    if (this.score['minB'] > this.score['maxB']) {
      alert('Bクラスの最小値が最大値より大きいです');
      return;
    }

    // Bクラスの最小値と最大値を選択しているか確認
    for (let i = 0; i < this.selectB.length; i++) {
      if (!minB && this.score['minB'] == this.selectB[i]) {
        minB = true;
      }
      if (!minB && this.score['maxB'] == this.selectB[i]) {
        maxB = true;
      }
    }
    if (!minB || !maxB) {
      alert('Bクラスの値が選択されていません。');
      return;
    }

    //　スキル項目登録・削除

    for (let i = 0; i < this.deleteData.length; i++) {
      data.push(this.deleteData[i]);
    }

    this.http.post(
      API_BASE_URL + "/api/v1/skillItem/save", data, this.httpOptions
    ).subscribe(res => {

      // 評価スコア配分 登録

      let scoreData = {
        minB: this.score['minB'],
        maxB: this.score['maxB'],
        createdUser: localStorage.getItem("uid")
      }

      this.score['createdUser'] = localStorage.getItem("uid");

      this.http.post(
        API_BASE_URL + "/api/v1/skillEvaluationClass/save", scoreData, this.httpOptions
      ).subscribe(res => {
        this.dialogRef.close();
      },error => {
        alert("認証に失敗しました");
        this.router.navigate(['/']);
        this.dialogRef.close();
      });
      
    },error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.dialogRef.close();
    });
  }

  /**
   * キャンセル
   */
  cancel() {
    this.dialogRef.close();
  }

  /**
   * 削除
   */
  delete(idx: number) {
    let data = this.skillList.data;

    // idがあるものはテーブル内のデータを削除するため、配列に保存
    if (data[idx]) {
      data[idx]['createdDate'] = null;
      this.deleteData.push(data[idx]);
    }

    data.splice(idx, 1);
    this.skillList = new MatTableDataSource(data as []);

    this.setScore();
  }

  /**
   * 評定のスコア配分をセット
   */
  setScore() {
    let list = this.skillList.data.length;

    this.generateNums();

    if (list == 0) {
      this.score['maxA'] = 0;
      this.score['minA'] = 0;
      this.score['maxB'] = 0;
      this.score['minB'] = 0;
      this.score['maxC'] = 0;
      this.score['minC'] = 0;
      return;
    }
    this.score['maxA'] = this.selectB.slice(0)[0] + 5;
    this.score['minC'] = this.selectB.slice(-1)[0] - 5;
    this.score['minA'] = this.score['maxB'] + 5;
    this.score['maxC'] = this.score['minB'] - 5;

    if (this.score['minA'] > this.score['maxA']) {
      this.score['minA'] = this.score['maxA'];
    }

    if (this.score['minC'] > this.score['maxC']) {
      this.score['minC'] = this.score['maxC'];
    }
  }

  /**
   * Bクラスのドロップダウン生成
   */
  generateNums() {
    this.selectB = [];
    let totalFold = 0;
    let data = this.skillList.data;
    this.selectB.push(0);

    for (let i = 0; i < data.length; i++) {
      // ポイント数を足す
      totalFold += data[i]['fold'];
    }

    for (let i = 1; i < totalFold; i++) {
      this.selectB.push(i*5);
      this.selectB.push(i*-5);
    }

    this.selectB.sort((a, b) => {
      return b-a;
    });
  }

  /**
   * セルのアンダーライン表示
   * @param value 入力値
   * @returns 
   */
  emptyCell(value) {
    // 何も入力されていない場合
    if (!value || value == '') {
      return 'empty';
    }
  }
}