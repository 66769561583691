import { Injectable, NgZone } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router }   from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private http: HttpClient, private router: Router, private ngZone: NgZone) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      var res = this.http.post<boolean>(API_BASE_URL + '/api/v1/loginCheck', {accessKey: localStorage.getItem("accessKey")});
      res.subscribe(r => {
        if(!r) {
          alert("IDもしくはパスワードが違います");
          this.router.navigate(['/login']);
          return false;
        }
        this.http.post<any>(API_BASE_URL + "/api/v1/loginUser", {accessKey: localStorage.getItem("accessKey")}).subscribe(user => {
          if(user == null) {
            alert("セッションの有効期限が切れています");
            this.router.navigate(['/login']);
            localStorage.removeItem("uid");
            localStorage.removeItem("accessKey");
            localStorage.removeItem("authority");
            localStorage.removeItem("outsource");
            localStorage.removeItem("deleteHoliday");
            localStorage.removeItem("cannotInstead");
            localStorage.removeItem("spot");
            localStorage.removeItem("userManagement");
            localStorage.removeItem("operationArrangement");
            return false;
          } 
          localStorage.setItem("uid", user["userId"]);
          localStorage.setItem("authority", user["authority"]);
          localStorage.setItem("outsource", user["outsource"]);
          localStorage.setItem("deleteHoliday", user["deleteHoliday"]);
          localStorage.setItem("cannotInstead", user["cannotInstead"]);
          localStorage.setItem("spot", user["spot"]);
          localStorage.setItem("userManagement", user["userManagement"]);
          localStorage.setItem("operationArrangement", user["operationArrangement"]);
          return true;
        });
      });
      return res;
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
}
