import { Component, OnInit, ViewChild, ChangeDetectorRef, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../environments/environment';
import { Router } from '@angular/router';
import { MatTableDataSource, MatPaginator } from '@angular/material';
import * as moment from 'moment';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-vacancy',
  templateUrl: './vacancy.component.html',
  styleUrls: ['./vacancy.component.less']
})

export class VacancyComponent implements OnInit {

  // カラム名
  public columnDefs = [
    'nameSite',
    'cdSite',
    'status',
    'frames',
    'weekTimes',
    'monthTimes'
  ];

  // テーブルデータソース
  vacancyList = new MatTableDataSource();

  // エクスポート用のデータ
  exportData = [];

  // 欠員コマ数カウント対象日
  days = '';

  // 欠員コマ数カウント終了日
  end = moment().endOf('month').format("YYYY/MM/DD");

  // 欠員時間（月）
  monthDays = `(${moment().startOf('month').format("YYYY/MM/DD")}~${moment().endOf('month').format("YYYY/MM/DD")})`

  // 物件名絞込み
  siteName = '';

  // エリア絞り込み
  areas = [];

  // 選択されたエリア
  areaIds = '';

  // 欠員状態絞り込み
  status = '全て';

  // 欠員状態絞り込みリスト
  public statusChoices = [
    {value: '全て'},
    {value: '清掃員なし'},
    {value: '清掃員いるが時間不足'}
  ];

  // ローディング中：true　終了：false
  nowLoading = true;
  areaLoading = true;
  loadingExport = false;

  // データ数
  dataSourceLength = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });

  constructor(private http: HttpClient, private router: Router, private cdr: ChangeDetectorRef, private ngZone: NgZone) { }

  ngOnInit() {

    let endDay = moment().endOf('month').format("YYYY-MM-DD");
    let start = moment(endDay).add(-6, 'days').format('YYYY/MM/DD');
    this.days = `(${start}~${this.end})`;

    this.siteName = localStorage.getItem('vacancySiteName') != null ? localStorage.getItem('vacancySiteName') : "";
    this.status = localStorage.getItem('vacancyStatus') != null ? localStorage.getItem('vacancyStatus') : "全て";
    this.getAreas();
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }
  
  ngAfterViewInit() {
    this.vacancyList.paginator = this.paginator;
    this.getData(this.paginator.pageIndex, this.paginator.pageSize); 
    this.paginator.page.subscribe(() => {
      this.getData(this.paginator.pageIndex, this.paginator.pageSize); 
    });
  }

  /**
   * 欠員物件データ取得
   */
  getData(index: number, size: number) {
    this.nowLoading = true;
    localStorage.setItem('vacancySiteName', this.siteName);
    localStorage.setItem('vacancyStatus', this.status);

    let year = moment().year();
    let month = moment().month();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/recruit/vacancy?s=0&e=8000&index=' + index
      + '&size=' + size
      + '&siteName=' + encodeURIComponent(this.siteName)
      + '&areaIds=' + this.areaIds
      + '&status=' + this.status
      + '&year=' + year,
      {headers: this.headers}
    )
    .subscribe(res => {
      this.vacancyList = new MatTableDataSource(res as any[]);

      let data = res as any[];
      if (data && data.length > 0) {
        this.dataSourceLength = res[0].totalSize;
      } else {
        this.dataSourceLength = 0;
      }
      this.nowLoading = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.nowLoading = false;
    });
  }
  
  /**
   * エリアドロップダウン作成
   */
  getAreas() {
    this.areaLoading = true;
    this.http.get(
      API_BASE_URL + "/api/v1/area", {headers: this.headers}
    ).subscribe(res => {
      this.areas = res as any[];
      this.areaLoading = false;
    }, error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
    });
  }

  /**
   * 検索機能イベント
   * @param event エリアドロップダウン表示：true、閉じる：false
   * @returns 
   */
  applyFilter(event?: boolean) {
    if(event)
      return;
    this.paginator.pageIndex = 0;
    this.getData(this.paginator.pageIndex, this.paginator.pageSize);
  }

  /**
   * エクスポート用に全件数取得
   */
  downloadBtn() {
    this.loadingExport = true;
    this.exportData = [];

    let year = moment().year();
    let month = moment().month();

    // 1月⇒0、2月⇒1なら年からマイナス1年（契約台帳の期首月が3月のため）
    if (month == 0 || month == 1) {
      year -= 1;
    }

    this.http.get(
      API_BASE_URL + '/api/v1/recruit/allVacancy?s=0&e=8000'
      + '&siteName=' + encodeURIComponent(this.siteName)
      + '&areaIds=' + this.areaIds
      + '&status=' + this.status
      + '&year=' + year,
      {headers: this.headers}
    )
    .subscribe(res => {
      let data = res as any[];
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const d = data[i];
          let status = ''
          if (d.staffVacancy) {
            status = d.staffVacancy
          }
          if (d.siteVacancy) {
            if (status) {
              status += '/'
            }
            status += d.siteVacancy
          }
          this.exportData.push({
              '物件名': d.nameSite
            , '物件コード': d.cdSite
            , '欠員状態': status
            , '欠員コマ': d.frames
            , '欠員時間（週）': d.weekTimes
            , '欠員時間（月）': d.monthTimes
            , '契約終了日': d.endDateContract
          })
        }
      }
      this.downloadCsv();
      this.loadingExport = false;
    },
    error => {
      alert("認証に失敗しました");
      this.router.navigate(['/']);
      this.loadingExport = false;
    });
  }

  /**
   * エクスポートボタン
   */
  downloadCsv() {
    const ws: XLSX.WorkSheet=XLSX.utils.json_to_sheet(this.exportData);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

    /* save to file */
    // ファイル名設定
    let fileName = '欠員確認' + this.days + '.xlsx';
    XLSX.writeFile(wb, fileName);
  }

  /**
   * 背景色設定
   * @param index 番号
   */
  setBgColor(index: number) {
    if (index % 2 == 0) {
      return 'blue-contract-bg';
    }
  }

  /**
   * 物件名クリック時に物件詳細情報へ遷移
   * @param row 対象データ
   */
  cellClicked(row) {
    this.router.navigate(['/buildings/' + row.cdSite]);
  }
}
