import { Component, OnInit, NgZone } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { API_BASE_URL } from '../../../environments/environment';
import { Router } from '@angular/router';
import _ from 'lodash'
import Sortable from 'sortablejs';
import { ClientSelectionDialogComponent } from '../client-selection-dialog/client-selection.component';
import { Subscription } from 'rxjs/internal/Subscription';
import * as moment from 'moment';

@Component({
  selector: 'app-management-plan-dialog',
  templateUrl: './management-plan.component.html',
  styleUrls: ['./management-plan.component.less']
})
export class ManagementPlanDialogComponent implements OnInit {

  public headers = new HttpHeaders({
    "X-LoginAccessKey": localStorage.getItem("accessKey")
  });


  // データ一覧
  list = [];

  // 年度検索
  filterYear = Number(moment().format('YYYY'));

  // 並び順が変更可能な年度
  isFuture = false;

  // 今期
  thisYear = null;

  subscription = new Subscription();

  constructor(
    private router: Router,
    private http: HttpClient,
    private dialogRef: MatDialogRef<ManagementPlanDialogComponent>,
    private dialog1: MatDialog,
    private ngZone: NgZone
  ) { }

  ngOnInit() {
    // 今期の表示順を取得
    this.getData();
    // 今年度
    let y = Number(moment().format('YYYY'))
    if (Number(moment().format('M')) <= 2) {
      y--
    }
    this.thisYear = y

    
      // 1, SortableJS
      const elem = document.getElementById("sort-table");
      Sortable.create(elem, {
        handle: '.handle', // ドラッグのトリガーをセレクタで指定します
        animation: 150,
      });
  }

  /**
   * 並び替え後のデータを生成
   * @param e 
   */
  onSortEvent() {
    const table = document.querySelector('#sort-table');
    const items = table.querySelectorAll('li');
    const newList = []
    for (let i = 0; i < items.length; i++) {
      const it = items[i];
      const row = { name: it.querySelector('.name').textContent }
      newList.push(row)
    }
    return newList
  }

  goTo(comp, param){
    this.ngZone.run(()=>{
    this.router.navigate([comp, param])
    });
  }

  /**
   * 今期の並び順を取得
   */
  getData() {
    if (!this.filterYear) {
      alert('年度を入力してください。');
      this.isFuture = false;
      return;
    }

    // データ取得
    this.list = []
    this.http.get(API_BASE_URL + '/api/v1/contractLedgers/getManagementPlanSetting?filterYear='
      + this.filterYear,
      {headers: this.headers})
      .subscribe((res) => {
        let r = res as any[];
        this.list = r;

        this.checkIsFuture();
      },
      error => {
        alert(error);
        this.router.navigate(['/']);
      }
    );
  }

  /**
   * 検索年度が今年度より未来か確認
   * @returns 
   */
  checkIsFuture() {
    if (!this.filterYear) {
      this.isFuture = false
      return
    }
    const tar = Number(this.filterYear)

    // 表示年度が今年度より過去の場合
    if (tar < this.thisYear) {
      this.isFuture = false
    // 表示年度と今年度が同じで、4月以降の場合
    } else if (tar == this.thisYear && Number(moment().format('M')) >= 4) {
      this.isFuture = false
    } else {
      this.isFuture = true
    }

  }

  /**
   * 変更ボタンイベント
   * @param row 対象行データ
   */
  clickChangeBtn(row) {
    const dialogRef = this.dialog1.open(ClientSelectionDialogComponent, {
      data: {
        name: row.name
      }
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
          row.name = result
        }
      })
    )
  }

  /**
   * 保存ボタンイベント
   * @returns 
   */
  save() {
    this.checkIsFuture();
    if (!this.isFuture) {
      alert('こちらの年度のデータは保存できません。')
      return
    }
    if (!this.list.length) {
      alert('保存できません。')
      return
    }

    // 並び替えたデータを取得
    const newList = this.onSortEvent();

    for (let i = 0; i < newList.length; i++) {
      const tar = newList[i];
      for (let j = 0; j < newList.length; j++) {
        const tar2 = newList[j];
        if (i != j) {
          if (tar.name == tar2.name) {
            alert(tar.name + 'が重複しています。')
            return
          }
        }
      }
    }
    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        "X-LoginAccessKey": localStorage.getItem("accessKey")
      })
    };

    this.http.post(
      API_BASE_URL + "/api/v1/contractLedgers/setManagementPlanSetting?year="
      + this.filterYear
      , newList
      , httpOptions
    ).subscribe(res => {
      alert('保存しました。');
      this.getData();
    },error => {
      alert("エラーが発生しました。" + error);
      this.router.navigate(['/']);
    });
  }

  /**
   * 閉じるボタンイベント
   */
  close() {
    this.dialogRef.close();
  }
}
